// Full routes, necessary for sidebar nav
export const DASHBOARD_ROUTES = {
  DASHBOARD: '/dashboard',
  PARTS: '/parts',
  PARTS_SEARCH: '/parts/search',
  PARTS_WATCHLIST: '/parts/watchlist',
  BOMS: '/boms',
  PURCHASING_PARTS_QUOTES: '/purchasing/part-quotes',
  PURCHASING_ORDERS: '/purchasing/orders',
  INVENTORY: '/inventory',
  INVENTORY_PARTS: '/inventory/parts',
  INVENTORY_STOCK_LOTS: '/inventory/stock-lots',
  INVENTORY_LOCATIONS: '/inventory/locations',
  INVENTORY_SHIPPING: '/inventory/shipping',
  INVENTORY_HANDLING: '/inventory/handling-requests',
  INVENTORY_DOCUMENTS: '/inventory/documents',
  INVENTORY_ALLOCATIONS: '/inventory/allocations',
  SUPPLIERS: '/suppliers',
  PLANNING: '/planning',
  PRODUCTION: '/production',
  PRODUCTION_RUNS: '/production/runs',
  ACCOUNT: '/account',
  ORG: '/org',
  ORG_PREFERENCES: '/org/preferences',
  ORG_BILLING: '/org/billing',
  ORG_BILLING_PLAN: '/org/billing/plan',
  ORG_BILLING_PAYMENT_METHODS: '/org/billing/payment-methods',
  ORG_BILLING_PAYMENT_HISTORY: '/org/billing/history',
  ORG_USERS_INTERNAL: '/org/users/internal',
  ORG_USERS_EXTERNAL: '/org/users/external',
  ORG_INFO: '/org/info',
  ORG_USERS: '/org/users',
  ERROR: '*',
};

// Mobile inventory routes
export const MOBILE_INVENTORY_ROUTES = {
  DASHBOARD: '/mobileinventory/dashboard',
  TRANSFER: '/mobileinventory/transfer',
  COUNTER: '/mobileinventory/counter',
  SETTINGS: '/mobileinventory/settings',
  PICKING: '/mobileinventory/picking',
  HANDLING: '/mobileinventory/handling',
  RECEIVE: '/mobileinventory/receive',
  SHIP: '/mobileinventory/ship',
  RETURN: '/mobileinventory/dekit',
  STOCK_LOTS: '/mobileinventory/stock-lots',
  STOCK_LOCATIONS: '/mobileinventory/stock-locations',
  ERROR: '*',
};

export const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT: '/forgot',
  AUTH_FAIL: '/auth-fail',
  // This is for setting password from an authenticated email route,
  // changing password when authenticated happens in another UX,
  // and resetting password when unauthenticated happens in another route
  RESET: '/set-password',
};

export const USER_ACCOUNT_ROUTES = {
  USER_ACCOUNT: '/account',
  PROFILE: '/account/profile',
  SECURITY: '/account/security',
  NOTIFICATIONS: '/account/notifications',
};

// NOTE: Should we break these into app related constants.ts files?

// Parts Constants
// TODO: why do we need to define tabs THREE times?
export const PARTS_TABS = {
  PURCHASING: 'purchasing',
  QUOTES: 'quotes',
  PURCHASE_REQUESTS: 'purchase_requests',
  PURCHASE_ORDERS: 'purchase_orders',
  ORDERS: 'orders',
  WATCHLIST_SUBSCRIPTIONS: 'watchlist-subscriptions',
  BOMS: 'boms',
  INVENTORY: 'inventory',
  ALTERNATIVES: 'alternatives',
  HEALTH: 'health',
  COMPLIANCE: 'compliance',
  SPECIFICATIONS: 'specifications',
  DOWNLOADS: 'downloads',
};

export const PARTS_SUBTABS = {
  SUPPLIER_LISTINGS: 'supplier-listings',
  SUPPLY: 'supply',
  SOURCING_AGENT: 'sourcing-agent',
  ALL_QUOTES: 'all-quotes',
};

// Inventory Constants
export const INVENTORY_PARTS_TABS_MAP = {
  STOCK_LOTS: 'stock-lots',
  LEDGERS: 'ledgers',
  PURCHASE_ORDERS: 'purchase-orders',
  ORDER_HISTORY: 'order-history',
  ALLOCATIONS: 'allocations',
};

export const STOCK_LOT_TABS_MAP = {
  HANDLING_TASKS: 'handling-tasks',
  LOT_LEDGER: 'lot-ledger',
  DOCUMENTATION: 'documentation',
  ALLOCATIONS: 'allocations',
};

export const PURCHASING_ORDER_TABS_MAP = {
  STATUS: 'status',
  ALL_LINES: 'all_lines',
  INVOICES: 'invoices',
  DOCUMENTATION: 'documentation',
};

export const PRODUCTION_RUNS_TABS_MAP = {
  STATUS: 'status',
  PARTS: 'parts',
  PURCHASING: 'purchasing',
  RELATED: 'related',
  SETTINGS: 'settings',
};

export const ORDER_SNAPSHOT_TABS_MAP = {
  STATUS: 'status',
  ALL_LINES: 'all_lines',
  INVOICES: 'invoices',
  KITTING: 'kitting',
};
