import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { MOBILE_INVENTORY_ROUTES } from 'routes/constants';
import { AppBar, Toolbar } from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { useGetStockLocationQuery } from 'store/slices/inventory';
import {
  IconPlaneDeparture,
  IconTransferIn,
  IconDashboard,
  IconBasket,
  IconListCheck,
  IconTruckDelivery,
  IconCircleMinus,
  IconTallymarks,
} from '@tabler/icons-react';
import { useDeviceContext } from 'hooks/useDeviceContext';
import {
  mobileInventoryDefaults,
  mobileInventoryStorageName,
} from 'types/mobileInventorySettings';
import useLocalStorage from 'hooks/useLocalStorage';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { ALL_APPS } from 'constants/appConstants';

const BottomAppBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { desktopScreen } = useDeviceContext();
  const [settings] = useLocalStorage(
    mobileInventoryStorageName,
    mobileInventoryDefaults
  );
  const { data: facility } = useGetStockLocationQuery(
    settings.facility || skipToken
  );

  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
      }}
    >
      <Toolbar>
        {desktopScreen && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.DASHBOARD
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.DASHBOARD)}
            startIcon={
              <IconDashboard
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.DASHBOARD
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            Dashboard
          </SegmentButton>
        )}
        {!desktopScreen && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.DASHBOARD)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconDashboard
              color={
                pathname === MOBILE_INVENTORY_ROUTES.DASHBOARD
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && settings.showCounter && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.COUNTER
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.COUNTER)}
            startIcon={
              <IconTallymarks
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.COUNTER
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            Counter
          </SegmentButton>
        )}
        {!desktopScreen && settings.showCounter && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.COUNTER)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconTallymarks
              color={
                pathname === MOBILE_INVENTORY_ROUTES.COUNTER
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.TRANSFER
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.TRANSFER)}
            startIcon={
              <IconTransferIn
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.TRANSFER
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            Move
          </SegmentButton>
        )}
        {!desktopScreen && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.TRANSFER)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconTransferIn
              color={
                pathname === MOBILE_INVENTORY_ROUTES.TRANSFER
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && !settings.showCounter && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.RECEIVE
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.RECEIVE)}
            startIcon={
              <IconTruckDelivery
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.RECEIVE
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            Receive
          </SegmentButton>
        )}
        {!desktopScreen && !settings.showCounter && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.RECEIVE)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconTruckDelivery
              color={
                pathname === MOBILE_INVENTORY_ROUTES.RECEIVE
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.PICKING
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.PICKING)}
            startIcon={
              <IconBasket
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.PICKING
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            Pick
          </SegmentButton>
        )}
        {!desktopScreen && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.PICKING)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconBasket
              color={
                pathname === MOBILE_INVENTORY_ROUTES.PICKING
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && facility?.warehouseHandlingEnabled && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.HANDLING
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.HANDLING)}
            startIcon={
              <IconListCheck
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.HANDLING
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            Handling
          </SegmentButton>
        )}
        {!desktopScreen && facility?.warehouseHandlingEnabled && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.HANDLING)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconListCheck
              color={
                pathname === MOBILE_INVENTORY_ROUTES.HANDLING
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && !settings.showCounter && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.SHIP ? 'success' : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.SHIP)}
            startIcon={
              <IconPlaneDeparture
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.SHIP
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            Kit & Ship
          </SegmentButton>
        )}
        {!desktopScreen && !settings.showCounter && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.SHIP)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconPlaneDeparture
              color={
                pathname === MOBILE_INVENTORY_ROUTES.SHIP
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}

        {desktopScreen && !settings.showCounter && (
          <SegmentButton
            size="large"
            color={
              pathname === MOBILE_INVENTORY_ROUTES.RETURN
                ? 'success'
                : 'inherit'
            }
            sx={{ mr: 2 }}
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.RETURN)}
            startIcon={
              <IconCircleMinus
                size={32}
                color={
                  pathname === MOBILE_INVENTORY_ROUTES.RETURN
                    ? theme.palette.success.main
                    : 'white'
                }
              />
            }
            permissionScope={{
              app: ALL_APPS.WAREHOUSE.id,
            }}
          >
            De-Kit
          </SegmentButton>
        )}
        {!desktopScreen && !settings.showCounter && (
          <SegmentIconButton
            color="inherit"
            onClick={() => navigate(MOBILE_INVENTORY_ROUTES.RETURN)}
            permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
          >
            <IconCircleMinus
              color={
                pathname === MOBILE_INVENTORY_ROUTES.RETURN
                  ? theme.palette.success.main
                  : 'white'
              }
            />
          </SegmentIconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default BottomAppBar;
