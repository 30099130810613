import { useEffect, useRef } from 'react';

export const useInterval = ({
  callback,
  interval,
  autoStart = true,
}: {
  callback: () => void;
  interval: number;
  autoStart?: boolean;
}) => {
  const savedCallback = useRef<(() => void) | null>(null);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // After every render, save the latest callback into our ref.
  useEffect(() => {
    savedCallback.current = callback;
  });

  const start = () => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (intervalId.current === null) {
      intervalId.current = setInterval(tick, interval);
    }
  };

  useEffect(() => {
    if (autoStart) {
      start();
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
    };
  }, [interval, autoStart]);

  const clear = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  return { start, clear };
};
