import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { RootState, useDispatch, useSelector } from 'store';
import {
  LoadingStatusTypes,
  PartSchemas,
  partStatusRequestTypes,
} from 'types/partStatus';
import {
  fetchPartAltsData,
  fetchPartCoreData,
  fetchPartMarketData,
  fetchPartSpecsData,
  fetchPartSupplyData,
  partDataSelector,
} from 'store/slices/partData';
import {
  Part,
  PartAlts,
  PartCore,
  PartSpecs,
  PartMarket,
  PartSupply,
} from 'types/part';
import { getPartStatusSelector } from 'store/slices/partStatus';

type PartDataRequesterContextType = {
  getIsPartLoading: ({
    partId,
    schema,
  }: {
    partId: Part['id'];
    schema: PartSchemas;
  }) => boolean;
  getPartDataBySchema: ({
    partId,
    schema,
  }: {
    partId: Part['id'];
    schema: PartSchemas;
  }) => PartCore | PartSupply | PartAlts | PartSpecs | PartMarket;
};

export const PartDataRequesterContext = createContext(
  {} as PartDataRequesterContextType
);

const PartDataRequesterProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const batchTimeout = 500;
  const retryBatchTimeout = 1000 * 5;
  const maxBatchSize = 100;

  const partStatus = useSelector((state: RootState) =>
    getPartStatusSelector(state)
  );
  const partData = useSelector((state: RootState) => partDataSelector(state));

  function filterReadyStatusBySchema(schema: PartSchemas) {
    return Object.keys(partStatus).filter(
      (partId) =>
        (partStatus[partId][schema] as partStatusRequestTypes) ===
        partStatusRequestTypes.READY
    );
  }

  function filterRetryNowStatusBySchema(schema: PartSchemas) {
    return Object.keys(partStatus).filter(
      (partId) =>
        (partStatus[partId][schema] as partStatusRequestTypes) ===
        partStatusRequestTypes.READY_RETRY_NOW
    );
  }

  const partsCoreDataReady = useMemo(
    () => filterReadyStatusBySchema(PartSchemas.core),
    [partStatus]
  );
  const partsSupplyDataReady = useMemo(
    () => filterReadyStatusBySchema(PartSchemas.supply),
    [partStatus]
  );
  const partsSupplyRetryDataReady = useMemo(
    () => filterRetryNowStatusBySchema(PartSchemas.supply),
    [partStatus]
  );
  const partsAltsDataReady = useMemo(
    () => filterReadyStatusBySchema(PartSchemas.alts),
    [partStatus]
  );
  const partsSpecsDataReady = useMemo(
    () => filterReadyStatusBySchema(PartSchemas.specs),
    [partStatus]
  );
  const partsMarketDataReady = useMemo(
    () => filterReadyStatusBySchema(PartSchemas.market),
    [partStatus]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsCoreDataReady?.length > 0) {
        const initialRequest = partsCoreDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartCoreData({
            parts: initialRequest,
          })
        );
      }
    }, batchTimeout);
    return () => clearTimeout(timer);
  }, [partsCoreDataReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsSupplyDataReady?.length > 0) {
        const initialRequest = partsSupplyDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartSupplyData({
            parts: initialRequest,
          })
        );
      }
    }, batchTimeout);
    return () => clearTimeout(timer);
  }, [partsSupplyDataReady]);

  // Retry supply data with a retryBatchTimeout timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsSupplyRetryDataReady?.length > 0) {
        const initialRequest = partsSupplyRetryDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartSupplyData({
            parts: initialRequest,
          })
        );
      }
    }, retryBatchTimeout);
    return () => clearTimeout(timer);
  }, [partsSupplyRetryDataReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsAltsDataReady?.length > 0) {
        const initialRequest = partsAltsDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartAltsData({
            parts: initialRequest,
          })
        );
      }
    }, batchTimeout);
    return () => clearTimeout(timer);
  }, [partsAltsDataReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsSpecsDataReady?.length > 0) {
        const initialRequest = partsSpecsDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartSpecsData({
            parts: initialRequest,
          })
        );
      }
    }, batchTimeout);
    return () => clearTimeout(timer);
  }, [partsSpecsDataReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partsMarketDataReady?.length > 0) {
        const initialRequest = partsMarketDataReady.slice(0, maxBatchSize);
        dispatch(
          fetchPartMarketData({
            parts: initialRequest,
          })
        );
      }
    }, batchTimeout);
    return () => clearTimeout(timer);
  }, [partsMarketDataReady]);

  const getIsPartLoading = ({
    partId,
    schema,
  }: {
    partId: Part['id'];
    schema: PartSchemas;
  }) =>
    LoadingStatusTypes.includes(
      partStatus?.[partId]?.[schema] as partStatusRequestTypes
    );

  const getPartDataBySchema = ({
    partId,
    schema,
  }: {
    partId: Part['id'];
    schema: PartSchemas;
  }) => partData[schema].byId?.[partId];

  return (
    <PartDataRequesterContext.Provider
      value={{
        getIsPartLoading,
        getPartDataBySchema,
      }}
    >
      {children}
    </PartDataRequesterContext.Provider>
  );
};

export default PartDataRequesterProvider;
