import { createSlice } from '@reduxjs/toolkit';
import { DatagridNames, DatagridProps } from 'types/datagrid';
import { logout, softLogout } from 'store/actions';
import _ from 'lodash';

const initialState: DatagridProps = _.reduce(
  Object.values(DatagridNames),
  (acc, gridName) => ({ ...acc, [gridName]: null }),
  {} as DatagridProps
);

const datagrid = createSlice({
  name: 'datagrid',
  initialState,
  reducers: {
    saveDatagridState(state, action) {
      const { gridName, gridState } = action.payload;
      state[gridName as keyof DatagridProps] = gridState;
    },
    clearDatagridState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(softLogout, () => initialState);
  },
});

export default datagrid.reducer;

export const { saveDatagridState, clearDatagridState } = datagrid.actions;
