import { ReactElement } from 'react';
import NoSubOrgViewCard from 'views/pages/components/NoSubOrgViewCard';
import useSubOrgsViews from 'hooks/useSubOrgsViews';

const NoSubOrgRouteWrapper = ({ children }: { children: ReactElement }) => {
  const { isSubOrg, createdFrom, showSubOrgUsageAndPlan } = useSubOrgsViews();

  if (isSubOrg && !showSubOrgUsageAndPlan) {
    return <NoSubOrgViewCard createdFrom={createdFrom} />;
  }

  return children;
};

export default NoSubOrgRouteWrapper;
