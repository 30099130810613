import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import snackbarReducer from 'store/slices/snackbar';
import userReducer from 'store/slices/user';
import menuReducer from 'store/slices/menu';
import orgReducer from 'store/slices/org';
import datagridReducer from 'store/slices/datagrid';
import sessionReducer from 'store/slices/session';
import partStatusReducer from 'store/slices/partStatus';
import partDataReducer from 'store/slices/partData';
import utilityReducer from 'store/slices/utility';
import { apiSlice } from 'store/slices/api';

const reducer = combineReducers({
  snackbar: snackbarReducer,
  user: persistReducer({ key: 'user', storage }, userReducer),
  menu: menuReducer,
  org: persistReducer(
    {
      key: 'org',
      storage,
      whitelist: ['activeOrgId', 'blended', 'sandbox', 'activeOrg'],
    },
    orgReducer
  ),
  datagrid: persistReducer(
    {
      key: 'datagrid',
      storage,
    },
    datagridReducer
  ),
  [apiSlice.reducerPath]: apiSlice.reducer,
  session: persistReducer(
    {
      key: 'session',
      storage,
      whitelist: [
        'authToken',
        'refreshToken',
        'storedLocation',
        'storedOrg',
        'isLoggedIn',
      ],
    },
    sessionReducer
  ),
  partStatus: partStatusReducer,
  partData: partDataReducer,
  utility: persistReducer(
    {
      key: 'utility',
      storage: storageSession,
      blacklist: ['showNoPlanBanner'],
    },
    utilityReducer
  ),
});

export default reducer;
