import { FC } from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface WithSkeletonProps extends SkeletonProps {
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

const WithSkeleton: FC<WithSkeletonProps> = ({
  children,
  isLoading,
  sx = {},
  ...others
}) => (
  <>
    {isLoading ? (
      <Skeleton sx={sx} {...others}>
        {children}
      </Skeleton>
    ) : (
      children
    )}
  </>
);

export default WithSkeleton;
