import { createSlice } from '@reduxjs/toolkit';
import { handleOrgSwitch, logout, softLogout } from 'store/actions';
import { Part, PartId, PartSupply, RetryDirective } from 'types/part';
import _ from 'lodash';
import {
  DefaultPartRequestState,
  LoadingStatusTypes,
  PartRequestStateType,
  PartSchemas,
  partStatusRequestTypes,
} from 'types/partStatus';
import {
  fetchPartAltsData,
  fetchPartCoreData,
  fetchPartMarketData,
  fetchPartSpecsData,
  fetchPartSupplyData,
} from 'store/slices/partData';
import { RootState } from 'store/index';
import createCachedSelector from 're-reselect';

export type PartStatusStateProps = {
  [key: PartId]: PartRequestStateType;
};

export const initialStatePartStatus: {
  [key: PartId]: PartRequestStateType;
} = {};

const partStatus = createSlice({
  name: 'partStatus',
  initialState: initialStatePartStatus,
  reducers: {
    createOrUpdatePartsStatus(state, action) {
      const { partIds, schemas, statusType } = action.payload;
      _.forEach(partIds, (partId: Part['id']) => {
        if (!state[partId]) {
          state[partId] = structuredClone(DefaultPartRequestState);
        }
        _.forEach(schemas, (schema) => {
          state[partId][schema as PartSchemas] = statusType;
        });
      });
    },
    removePartStatusByIdAndSchema(state, action) {
      const { partId, schema } = action.payload;
      state[partId][schema as PartSchemas] = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialStatePartStatus)
      .addCase(softLogout, () => initialStatePartStatus)
      .addCase(handleOrgSwitch, (state, action) => {
        const { customPartIds } = action.payload;
        //   remove part status for each supply and alt in the old org
        _.forEach(Object.keys(state), (partId) => {
          state[partId][PartSchemas.supply] = null;
          state[partId][PartSchemas.alts] = null;
        });
        _.forEach(customPartIds, (partId) => {
          state[partId][PartSchemas.core] = null;
          state[partId][PartSchemas.specs] = null;
        });
      })
      .addCase(fetchPartCoreData.pending, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.core]: partStatusRequestTypes.FETCHING,
          };
        });
      })
      .addCase(fetchPartCoreData.fulfilled, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.core]: partStatusRequestTypes.SUCCESS,
          };
        });
      })
      .addCase(fetchPartCoreData.rejected, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.core]: partStatusRequestTypes.ERROR,
          };
        });
      })
      .addCase(fetchPartAltsData.pending, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.alts]: partStatusRequestTypes.FETCHING,
          };
        });
      })
      .addCase(fetchPartAltsData.fulfilled, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.alts]: partStatusRequestTypes.SUCCESS,
          };
        });
      })
      .addCase(fetchPartAltsData.rejected, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.alts]: partStatusRequestTypes.ERROR,
          };
        });
      })
      .addCase(fetchPartSpecsData.pending, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.specs]: partStatusRequestTypes.FETCHING,
          };
        });
      })
      .addCase(fetchPartSpecsData.fulfilled, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.specs]: partStatusRequestTypes.SUCCESS,
          };
        });
      })
      .addCase(fetchPartSpecsData.rejected, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.specs]: partStatusRequestTypes.ERROR,
          };
        });
      })
      .addCase(fetchPartSupplyData.pending, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          let supplyStatus = partStatusRequestTypes.FETCHING;
          // If part was requested to retry, maintain retry context in partStatus
          if (
            currentStatus[PartSchemas.supply] ===
            partStatusRequestTypes.READY_RETRY_NOW
          ) {
            supplyStatus = partStatusRequestTypes.FETCHING_RETRY;
          }
          state[partId] = {
            ...currentStatus,
            [PartSchemas.supply]: supplyStatus,
          };
        });
      })
      .addCase(fetchPartSupplyData.fulfilled, (state, action) => {
        _.forEach(action.payload, (partSupply: PartSupply) => {
          const currentStatus = state[partSupply.id] ?? DefaultPartRequestState;
          // Default to success status on query fulfilled
          let supplyStatus = partStatusRequestTypes.SUCCESS;
          // if retryDirective is retry_now and current status for supply is not fetching_retry,
          // set status to RETRY_NOW so that we can immediately retry fetching
          // Otherwise, set the status as RETRY_NOW_FAILED, so we don't loop through retries
          if (partSupply.retryDirective === RetryDirective.retry_now) {
            if (
              currentStatus[PartSchemas.supply] !==
              partStatusRequestTypes.FETCHING_RETRY
            ) {
              supplyStatus = partStatusRequestTypes.READY_RETRY_NOW;
            } else {
              supplyStatus = partStatusRequestTypes.RETRY_NOW_FAILED;
            }
          }
          // If retryDirective is no_retry, set status to KB_FAILURE so that we can alert the user
          if (partSupply.retryDirective === RetryDirective.no_retry) {
            supplyStatus = partStatusRequestTypes.KB_FAILURE;
          }
          state[partSupply.id] = {
            ...currentStatus,
            [PartSchemas.supply]: supplyStatus,
          };
        });
      })
      .addCase(fetchPartSupplyData.rejected, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.supply]: partStatusRequestTypes.ERROR,
          };
        });
      })
      .addCase(fetchPartMarketData.pending, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.market]: partStatusRequestTypes.ERROR,
          };
        });
      })
      .addCase(fetchPartMarketData.fulfilled, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.market]: partStatusRequestTypes.SUCCESS,
          };
        });
      })
      .addCase(fetchPartMarketData.rejected, (state, { meta }) => {
        _.forEach(meta.arg.parts, (partId: Part['id']) => {
          const currentStatus = state[partId] ?? DefaultPartRequestState;
          state[partId] = {
            ...currentStatus,
            [PartSchemas.market]: partStatusRequestTypes.ERROR,
          };
        });
      });
  },
});

export default partStatus.reducer;

export const { createOrUpdatePartsStatus, removePartStatusByIdAndSchema } =
  partStatus.actions;

export const getPartStatusSelector = (state: RootState) => state.partStatus;

createCachedSelector(
  getPartStatusSelector,
  (__: RootState, schemas: PartSchemas[]) => schemas,
  (__: RootState, ___, partId: PartId) => partId,
  (partStatusState, schemas, partId) => {
    let loading = false;
    _.forEach(schemas, (s) => {
      const status = partStatusState[partId]?.[s];
      if (status && LoadingStatusTypes.includes(status)) {
        loading = true;
      }
    });
    return loading;
  }
)((__, schemas, partId) => `${partId}_${(_.join(schemas), '_')}`);

export const selectLoadingStateByStatusByIdAndSchemaList = createCachedSelector(
  getPartStatusSelector,
  (__: RootState, schemas: PartSchemas[]) => schemas,
  (__: RootState, ___, partIds: PartId[]) => partIds,
  (partStatusState, schemas, partIds) => {
    let loading = false;
    _.forEach(partIds, (partId) => {
      _.forEach(schemas, (s) => {
        const status = partStatusState[partId]?.[s];
        if (status && LoadingStatusTypes.includes(status)) {
          loading = true;
        }
      });
    });
    return loading;
  }
)((__, schemas, partIds) => `${_.join(partIds, '_')}_${_.join(schemas, '_')}`);

export const selectLoadingStateBySchemaForList = createCachedSelector(
  getPartStatusSelector,
  (__: RootState, schemas: PartSchemas[]) => schemas,
  (__: RootState, ___, partIds: { [key: PartId]: PartId[] }) => partIds,
  (__: RootState, ____, listName: string) => listName,
  (partStatusState, schemas, partIds, __) =>
    _.reduce(
      Object.keys(partIds),
      (acc, key) => {
        let loading = false;
        _.forEach(partIds[key], (partId) => {
          _.forEach(schemas, (s) => {
            const status = partStatusState[partId]?.[s];
            if (status && LoadingStatusTypes.includes(status)) {
              loading = true;
            }
          });
        });
        acc[key] = loading;
        return acc;
      },
      {} as { [key: PartId]: boolean }
    )
)(
  (__, schemas, ___, listName) =>
    `LoadingStateForList-${listName}-${_.join(schemas, '_')}`
);

export const selectPartStatusRequestBySchemaForList = createCachedSelector(
  getPartStatusSelector,
  (__: RootState, schema: PartSchemas) => schema,
  (__: RootState, ___, partIds: PartId[]) => partIds,
  (partStatusState, schema, partIds) =>
    _.reduce(
      partIds,
      (acc) => {
        _.forEach(partIds, (partId) => {
          const status = partStatusState[partId]?.[schema];
          if (status) {
            acc[partId] = status;
          }
        });
        return acc;
      },
      {} as { [key: PartId]: partStatusRequestTypes }
    )
)(
  (__, schema, partIds) => `PartStatusRequest-${schema}-${_.join(partIds, '_')}`
);
