import { Part, OrgPart } from 'types/part';
import { Org, OrgAddressBase, OrgLite } from 'types/org';
import { User } from 'types/user';
import { DateString, DateTimeString } from 'types';
import { ProductionRun } from 'types/production';
import { BomCRUDSchema } from 'types/bom';
import { HandlingRequestCostItem, ShipmentCostItem } from 'types/purchasing';
import { PartRuleSet } from 'types/partRules';
import { Lite } from 'types/api';
import { OrgSupplier } from 'types/suppliers';
import { Team, TeamLite } from 'types/properties';

// USE with stock_lots endpoints
export type StockLotCRUDSchema = {
  id: string;
  org: Org;
  status: string;
  lotId: string;
  package: PackagingOptions;
  quantCertain: boolean;
  quarantined: boolean;
  pending: boolean;
  quant: number;
  ownedQuant: number;
  part: Part;
  printedPart: string;
  unitPrice: string;
  landedUnitPrice: string;
  allowedRequests: string[];
  createdAt: DateString;
  expiresAt?: DateString | null;
  tapeSize: number | null;
  mfgLot: string | null;
  countryOfOrigin: string | null;
  dateCode: string | null;
  internalNotes: string | null;
  externalNotes: string | null;
  notes: string | null;
  msl: string | null;
  locationName: string | null;
  mslFloorHours: number | null;
  stockLocation: StockLocation | null;
  facility: StockLocationLite | null;
  expectedDate: DateString | null;
  bonded: DateString | null;
  handlingRequests: HandlingRequestLite[];
  shipments: ShipmentPickingIcon[];
  auditedAt: DateTimeString | null;
  dataSourceSyncedAt: DateTimeString | null;
  dataSource: DataSource | null;
  purchaseLineQuant: number;
  receivedFromIngest: boolean;
  currentQuant: number;
  smtNoPnp: boolean;
  team?: TeamLite;
};

export type StockLotLite = Lite<StockLotCRUDSchema>;

// USE with query_stock_lots
export type StockLotQuerySchema = {
  id: string;
  org: Org;
  status: string;
  lotId: string;
  mfgLot: string | null;
  ownedQuant: number;
  notes: string | null;
  countryOfOrigin: string | null;
  createdFrom: string | null;
  replacedBy: string | null;
  stockLocation: StockLocation;
  facility: StockLocationLite;
  purchaseLine: string | null;
  handlingRequests: HandlingRequestLite[];
  shipments: Shipment[];
  part: Part;
  orgPart: OrgPart;
  locationDisplay: string;
  partLotId: string;
  createdDate: string;
  expiresDate: string | null;
  mslHoursLeft: number;
  unitPrice: string;
  landedUnitPrice: string;
  expectedDate: DateString | null;
  createdAt: DateString;
  updatedAt: DateString;
  printedPart: string;
  quant: number;
  dateCode: string | null;
  quantCertain: boolean;
  quarantined: boolean;
  smtNoPnp: boolean;
  pending: boolean;
  msl: string | null;
  mslFloorHours: number | null;
  tapeSize: number | null;
  bonded: string | null;
  auditedAt: DateString | null;
  dataSource: DataSource | null;
  dataSourceSyncedAt: DateTimeString | null;
  expectedBy: string | null;
  package: string;
  team: Team['id'] | null;
};

export type StockLotWithPrice = StockLotCRUDSchema & {
  unitPrice?: number | null;
  landedUnitPrice?: number | null;
};

export type ConsumeStockEvent = {
  id: string;
  stockLot: StockLotWithPrice;
  quant: number;
};

export type ShipmentToReceiveGETSchema = {
  shipToId: StockLocation['id'];
  sandbox?: boolean;
};

export type ShipmentToReceive = {
  id: string;
  status: string;
  trackingNumber: string;
  carrier: string;
  shipmentCode: string;
  notes: string | null;
  org: Org;
  shipFrom: StockLocation | null;
  shipTo: StockLocation | null;
  lines: string[];
  estimatedDelivery: DateString | null;
  createdAt?: DateTimeString;
  shipmentType: ShipmentType;
};

export type ShipmentBase = {
  id: string;
  status: string;
  trackingNumber: string;
  carrier: string;
  service: string;
  shipmentCode: string;
  notes: string | null;
  notificationEmail: string | null;
  billable: boolean;
  shipPartial: boolean;
  deadline: DateString | null;
  approvedAt?: DateString | null;
  shippedAt: DateString | null;
  estimatedDelivery: DateString | null;
  receivedAt: DateString | null;
  approved: boolean;
  locked: boolean;
  address: OrgAddressBase;
  org: string;
  shipFrom: StockLocation | null;
  shipTo: StockLocation | null;
  shippedBy: string | null;
  receivedBy: string | null;
  parentShipment: string | null;
  childShipments: string[];
  productionRun: ProductionRun | null;
  hierarchy?: string[];
  shipmentType: ShipmentType;
  createdAt?: DateTimeString;
  internalNotes?: string | null;
  team?: TeamLite;
};

export type Shipment = ShipmentBase & {
  lines: ShipmentLine[];
  org: Org;
  relatedRecords?: relatedRecord[];
  approvedBy: User | null;
  orderExternalPoNumber?: string | null;
};

export type ShipmentLite = Omit<ShipmentBase, 'team'> & {
  lines: ShipmentLine['id'][];
  approvedBy: string | null;
  team?: Team['id'];
};

export type ShipmentPickingIcon = {
  id: string;
  shipmentCode: string;
  approvedAt: DateString | null;
  shippedAt: DateString | null;
  receivedAt: DateString | null;
};

export type ExpandedShipments = ShipmentBase & {
  lines: ShipmentLineLiteExpandedStockLot[];
  relatedRecords?: relatedRecord[];
  approvedBy: User | null;
};

export enum ShipmentLineStatus {
  TO_DO = 'to_do',
  PICKED = 'picked',
  PACKED = 'packed',
  INGESTED = 'ingested',
}

export type ShipmentLine = {
  id: string;
  status: ShipmentLineStatus | null;
  notes: string | null;
  printedNotes: string | null;
  shipment: string;
  stockLot: StockLotWithPrice;
  shippedQuant: number | null;
  quantAfterApproval?: number;
  packageAfterApproval?: string;
  pickedAt?: DateTimeString | null;
};

export type ShipmentLineLiteExpandedStockLot = {
  id: string;
  status: string | null;
  notes: string | null;
  printedNotes: string | null;
  shipment: string;
  stockLot: StockLotLite;
  shippedQuant: number | null;
  quantAfterApproval?: number;
  packageAfterApproval?: string;
};

export type ShipmentLineLite = {
  id: string;
  status: string | null;
  notes: string | null;
  printedNotes: string | null;
  shipment: string;
  stockLot: string;
  shippedQuant: number | null;
  quantAfterApproval?: number;
  packageAfterApproval?: string;
};

export type ShipmentLineUpdateRequest = {
  status?: string;
  notes?: string | null;
  printed_notes?: string | null;
  stock_location?: StockLocation['id'] | null;
  stockLot?: string;
  transferRequests?: boolean;
};

export type ShipmentEstimatesRequest = {
  shipments: {
    shipmentId: Shipment['id'];
  }[];
  handlingRequests?: {
    requestType: string;
    stockLot?: StockLotCRUDSchema['id'];
  }[];
};

export type ShipmentEstimates = {
  shippingCosts: ShipmentCostItem[];
  shippingTotal: number;
  handlingRequestsCosts: HandlingRequestCostItem[];
  handlingRequestsTotal: number;
  packingTotal: number;
  totalCost: number;
  shippingPartial: boolean;
};

export type ShipmentConflicts = {
  canShipAll: boolean;
  shipments: ShipmentConflict[];
};

export type ShipmentConflict = {
  id: Shipment['id'];
  canShip: boolean;
  deadline?: DateString;
  conflictedLines: ShipmentConflictedLine[];
};

export type ShipmentConflictedLine = {
  id: ShipmentLine['id'];
  reasons: ConflictReason[];
  requestedQuant: number;
  status: string;
  stockLot: StockLotCRUDSchema;
};

export type ConflictReason = {
  status: string;
  msg: string;
  conflictedHandlingRequest?: HandlingRequest;
  conflictedShipmentLine?: ShipmentLineCausingConflicts;
};

export type ShipmentLineCausingConflicts = {
  id: ShipmentLine['id'];
  status: string;
  notes: string | null;
  printedNotes: string | null;
  shippedQuant: number | null;
  shipment: Shipment['id'];
  stockLot: StockLotCRUDSchema['id'];
};

export type relatedRecord = {
  name: string;
  recordType: string;
  recordId: string;
  orgId: string | null;
};

export type Ledger = {
  ledgerType: string;
  date: DateString;
  expected: number | null;
  onHand: number | null;
  allocated: number | null;
  price: number | null;
  stockLocation: StockLocation | null;
  notes: string | null;
  related_records: relatedRecord[];
};

export enum StockLocationType {
  FACILITY = 'facility',
  ESD_BIN = 'esd_bin_201506',
  BIN_SECTION = 'bin_section',
  BIG_REEL_HOPPER = 'big_reel_hopper',
  BAG = 'bag',
  WORKSTATION = 'workstation',
  DRY_CABINET = 'dry_cabinet',
  SHELF = 'shelf',
  PALLET = 'pallet',
  OTHER = 'other',
  NOT_SPECIFIED = 'not_specified',
}

export const StockLocationTypeLabels: {
  [key in StockLocationType]: string;
} = {
  facility: 'Address',
  esd_bin_201506: 'ESD Bin',
  bin_section: 'Bin Section',
  big_reel_hopper: 'Large Reel Storage',
  bag: 'Bag',
  workstation: 'Workstation',
  dry_cabinet: 'Dry Cabinet',
  shelf: 'Shelf',
  pallet: 'Pallet',
  other: 'Other',
  not_specified: '-',
};

export type StockLocation = {
  id: string;
  name: string;
  externalName: string | null;
  facility: StockLocationLite | null;
  billable: boolean;
  locationType: StockLocationType;
  customerLocation: boolean;
  org: string | null;
  address: OrgAddressBase;
  parent: string | StockLocation | null;
  shipment?: Shipment | null;
  notificationEmail?: string;
  manufacturer: boolean;
  requiresMslReset: boolean;
  requiresLcrInspection: boolean;
  requiresDimensionalInspection: boolean;
  requiresReels: boolean;
  requiresMerges: boolean;
  requiresSplits: boolean;
  consumesStock: boolean;
  childStockLots: StockLotCRUDSchema[];
  stockLots: StockLotCRUDSchema[];
  warehouseShippingEnabled?: boolean;
  warehouseHandlingEnabled?: boolean;
  acceptsSmtNoPnp?: boolean;
  defaultPackage?: string;
  partRuleSet?: PartRuleSet['id'];
  locked?: boolean;
  team?: TeamLite;
};

export type StockLocationLite = {
  id: string;
  address: OrgAddressBase;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  name: string;
  locationType: StockLocationType;
  active: boolean;
  billable: boolean;
  customerLocation: boolean;
  externalName: string | null;
  addressLine_1: string | null;
  addressLine_2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressCountry: string | null;
  addressPostalCode: string | null;
  addressCompany: string | null;
  addressName: string | null;
  addressPhone: string | null;
  parent: string | null;
  org: string | null;
  warehouseShippingEnabled?: boolean;
  warehouseHandlingEnabled?: boolean;
  acceptsSmtNoPnp?: boolean;
  partRuleSet?: PartRuleSet['id'];
  team?: Team['id'];
};

export enum ExtractorFieldNames {
  MPN = 'mpn',
  SKU = 'sku',
  CPID = 'cpid',
  TRACKING_NUMBER = 'trackingNumber',
  COO = 'coo',
  HTSUS = 'htsus',
  ECCN = 'eccn',
  DATE_CODE = 'dateCode',
  MFG_LOT = 'mfgLot',
  DOCUMENT_DATE = 'documentDate',
  SHIP_DATE = 'shipDate',
  DELIVERY_DATE = 'deliveryDate',
  RELEASE_DATE = 'releaseDate',
  HAS_COFC = 'hasCofc',
  QUANT_ORDERED = 'quantOrdered',
  QUANT_SHIPPED = 'quantShipped',
  QUANT_BACKORDERED = 'quantBackordered',
  QUANT_CANCELLED = 'quantCancelled',
  UNIT_PRICE = 'unitPrice',
  LINE_PRICE = 'linePrice',
  LINE_DISCOUNT = 'lineDiscount',
  REELING_PRICE = 'reelingPrice',
  REELING_DISCOUNT = 'reelingDiscount',
  LINE_TARIFFS = 'lineTariffs',
  TOTAL_TARIFFS = 'totalTariffs',
  TOTAL_SHIPPING = 'totalShipping',
  TOTAL = 'total',
  TAX_TOTAL = 'taxTotal',
  SUBTOTAL = 'subtotal',
  PAYMENT_TERMS = 'paymentTerms',
  ORDER_NUMBER = 'orderNumber',
}

export const extractorFieldDataTypes = {
  [ExtractorFieldNames.MPN]: 'string',
  [ExtractorFieldNames.SKU]: 'string',
  [ExtractorFieldNames.CPID]: 'string',
  [ExtractorFieldNames.TRACKING_NUMBER]: 'string',
  [ExtractorFieldNames.COO]: 'string',
  [ExtractorFieldNames.HTSUS]: 'string',
  [ExtractorFieldNames.ECCN]: 'string',
  [ExtractorFieldNames.DATE_CODE]: 'string',
  [ExtractorFieldNames.MFG_LOT]: 'string',
  [ExtractorFieldNames.DOCUMENT_DATE]: 'date',
  [ExtractorFieldNames.SHIP_DATE]: 'date',
  [ExtractorFieldNames.DELIVERY_DATE]: 'date',
  [ExtractorFieldNames.RELEASE_DATE]: 'date',
  [ExtractorFieldNames.HAS_COFC]: 'boolean',
  [ExtractorFieldNames.QUANT_ORDERED]: 'number',
  [ExtractorFieldNames.QUANT_SHIPPED]: 'number',
  [ExtractorFieldNames.QUANT_BACKORDERED]: 'number',
  [ExtractorFieldNames.QUANT_CANCELLED]: 'number',
  [ExtractorFieldNames.UNIT_PRICE]: 'number',
  [ExtractorFieldNames.LINE_PRICE]: 'number',
  [ExtractorFieldNames.LINE_DISCOUNT]: 'number',
  [ExtractorFieldNames.REELING_PRICE]: 'number',
  [ExtractorFieldNames.REELING_DISCOUNT]: 'number',
  [ExtractorFieldNames.LINE_TARIFFS]: 'number',
  [ExtractorFieldNames.TOTAL_SHIPPING]: 'number',
  [ExtractorFieldNames.TOTAL]: 'number',
  [ExtractorFieldNames.TAX_TOTAL]: 'number',
  [ExtractorFieldNames.SUBTOTAL]: 'number',
  [ExtractorFieldNames.PAYMENT_TERMS]: 'string',
};

export const documentLevelExtractorFieldNames = [
  ExtractorFieldNames.ORDER_NUMBER,
  ExtractorFieldNames.PAYMENT_TERMS,
  ExtractorFieldNames.SUBTOTAL,
  ExtractorFieldNames.TOTAL_SHIPPING,
  ExtractorFieldNames.TOTAL_TARIFFS,
  ExtractorFieldNames.TAX_TOTAL,
  ExtractorFieldNames.TOTAL,
  ExtractorFieldNames.TRACKING_NUMBER,
  ExtractorFieldNames.DOCUMENT_DATE,
];

export const lineLevelExtractorFieldNames = [
  ExtractorFieldNames.CPID,
  ExtractorFieldNames.MPN,
  ExtractorFieldNames.SKU,
  ExtractorFieldNames.QUANT_ORDERED,
  ExtractorFieldNames.QUANT_SHIPPED,
  ExtractorFieldNames.QUANT_BACKORDERED,
  ExtractorFieldNames.QUANT_CANCELLED,
  ExtractorFieldNames.COO,
  ExtractorFieldNames.MFG_LOT,
  ExtractorFieldNames.DATE_CODE,
  ExtractorFieldNames.UNIT_PRICE,
  ExtractorFieldNames.LINE_PRICE,
  ExtractorFieldNames.LINE_TARIFFS,
  ExtractorFieldNames.LINE_DISCOUNT,
  ExtractorFieldNames.REELING_PRICE,
  ExtractorFieldNames.REELING_DISCOUNT,
  ExtractorFieldNames.SHIP_DATE,
  ExtractorFieldNames.RELEASE_DATE,
  ExtractorFieldNames.DELIVERY_DATE,
  ExtractorFieldNames.HTSUS,
  ExtractorFieldNames.ECCN,
  ExtractorFieldNames.HAS_COFC,
];

export type ExtractedDataRow = {
  [key in ExtractorFieldNames]?: string | number | boolean | null | DateString;
};

export type ExtractedData = ExtractedDataRow[];

export type DocumentParsedData = {
  errors?: {
    type: string;
    message: string;
  }[];
  extractedData?: ExtractedData | null;
  foundValues?: string[] | null;
  text?: string | null;
  plRequestIds?: string[] | null;
};

export type DocumentReview = {
  needsReview: boolean;
  processUpdates?: boolean;
  scoreExtraction?: number;
  parsedData?: DocumentParsedData;
  purchase?: string | null;
  poNumber?: string | null;
  documentExtractor?: string | null;
  reprocess?: boolean;
};

export enum ExtractorContextTypes {
  EMAIL = 'email',
  SCAN = 'scan',
}

export type DocumentExtractor = {
  id: string;
  name: string;
};

export type StockDocument = {
  id: string;
  name: string;
  documentType: StockDocType;
  notes: string | null;
  document: string;
  createdAt: DateTimeString | null;
  relatedRecords: RelatedRecord[];
  needsReview: boolean;
  purchaseEvents: string[];
  purchaseLines: string[];
  documentExtractor: string | null;
  documentExtractorId: string | null;
  purchase: string | null;
  org: OrgLite | null;
  metadata: {
    context: {
      type: ExtractorContextTypes;
      senderEmail?: string | null;
      recipientEmails?: string | null;
      subject?: string | null;
      bodyText?: string | null;
    };
  };
  parsedData: DocumentParsedData;
  supplier?: SupplierLite | null;
  orgSupplier?: OrgSupplier | null;
  mimeType: string;
};

export type StockLocationCreate = {
  orgId?: string | null;
  parentId?: string | null;
  name: string;
  locationType?: StockLocationType;
  shipmentId?: string | null;
  billable?: boolean;
  externalName?: string | null;
  notificationEmail?: string;
  consumesStock?: boolean;
  manufacturer?: boolean;
  requiresLcrInspection?: boolean;
  requiresDimensionalInspection?: boolean;
  requiresReels?: boolean;
  requiresMerges?: boolean;
  address?: OrgAddressBase;
  acceptsSmtNoPnp?: boolean;
  team?: Team['id'];
};

export type StockLocationUpdate = {
  name?: string;
  externalName?: string | null;
  billable?: boolean;
  locationType?: StockLocationType;
  address?: OrgAddressBase;
  orgId?: Org['id'];
  parentId?: string | null;
  shipmentId?: Shipment['id'];
  locked?: boolean;
  notificationEmail?: string;
  manufacturer?: boolean;
  requiresMslReset?: boolean;
  requiresLcrInspection?: boolean;
  requiresDimensionalInspection?: boolean;
  acceptsTube?: boolean;
  acceptsSmtNoPnp?: boolean;
  requiresReels?: boolean;
  requiresMerges?: boolean;
  requiresSplits?: boolean;
  consumesStock?: boolean;
  longTermStorage?: boolean;
  team?: Team['id'];
};

export type StockLotCreate = {
  id?: string;
  orgId: string;
  part: string;
  stockLocationId: string | null;
  facilityId?: string | null;
  package: string;
  quant?: string;
  dateCode?: string | null;
  mfgLot?: string | null;
  msl?: string;
  mslFloorHours?: number;
  pending?: boolean;
  quantCertain?: boolean;
  quarantined?: boolean;
  expectedBy?: DateString | string | null;
  internalNotes?: string | null;
  externalNotes?: string | null;
  tapeSize?: number | null;
  createdByCustomer?: boolean;
  station?: string;
  unitPricePaid?: number | null;
  landedUnitPricePaid?: number | null;
  smtNoPnp?: boolean;
  expiresAt?: DateString | null;
  team?: TeamLite['id'] | null;
};

export type StockLotWithShipmentLineCreate = StockLotCreate & {
  notes?: string;
  printedNotes?: string;
  createConsumeRequest?: boolean;
};

export type StockLotEdit = {
  quant?: number;
  package?: string;
  notes?: string;
  countryOfOrigin?: string;
  dateCode?: string | null;
  mfgLot?: string | null;
  auditedAt?: DateTimeString;
  quarantined?: boolean;
  msl?: MslOptions;
  msl_floor_hours?: number;
  internalNotes?: string;
  tapeSize?: number;
  unitPricePaid?: number | null;
  landedUnitPricePaid?: number | null;
  smtNoPnp?: boolean;
  expiresAt?: DateString | null;
  team?: Team['id'] | null;
};

export type StockLotReceive = {
  stockLocation: StockLocation['id'] | null;
  package?: string;
  quant?: number;
  dateCode?: string | null;
  mfgLot?: string | null;
  msl?: string | null;
  mslFloorHours?: number | null;
  quantCertain: boolean;
  quarantined?: boolean;
  internalNotes?: string | null;
  externalNotes?: string | null;
  printedNotes?: string | null;
  tapeSize?: number | null;
  station?: StockLocation['name'];
  shipmentLine?: ShipmentLine['id'] | null;
  smtNoPnp?: boolean;
  expiresAt?: DateString | null;
};

export type AllocationCRUDSchema = {
  id: string;
  name: string | null;
  originalQuant: number;
  quant: number;
  releasedAt: string | null;
  part: Part['id'];
  org: Org;
  releasedBy: User;
  stockLot: StockLotCRUDSchema;
  relatedRecords: relatedRecord[];
};

/**
 * Schema Types will be the full possible response from the endpoint with all nested fields expanded
 * This is used for the useServerSideQuery hook
 */
export type AllocationQuerySchema = {
  id: string;
  org: Org;
  releasedBy: User;
  stockLot: Lite<StockLotCRUDSchema>;
  productionRun: Lite<ProductionRun>;
  part: Part;
  orgPart: OrgPart;
  status: string;
  createdDate: string;
  updatedAt: string;
  originalQuant: number;
  quant: number;
  name: string | null;
  releasedAt: string | null;
  releaseNotes: string | null;
  dataSource: string | null;
  syncedPart: Part | null;
  splitFrom: string | null;
};

export type AllocationsCreate = {
  stockLot: StockLotCRUDSchema['id'];
  quant: number;
  productionRun: ProductionRun['id'];
  replaceFromProductionRun?: ProductionRun['id'];
};

export type DataSource = 'google-sheets';

export type StockLotScanMove = {
  stockLocationId: string;
};

export enum PackagingOptions {
  tube = 'tube',
  tray = 'tray',
  bulk = 'bulk',
  tapeSingle = 'tape_single',
  tapeMultiple = 'tape_multiple',
  reel = 'reel',
}

export enum PackagingOptionsLabels {
  tube = 'Tube',
  tray = 'Tray',
  bulk = 'Bulk',
  tapeSingle = 'Cut Tape (Single)',
  tapeMultiple = 'Cut Tape (Multiple)',
  reel = 'Reel',
}

export const PackagingOptionsMap: {
  [key in PackagingOptions]: PackagingOptionsLabels;
} = {
  [PackagingOptions.tube]: PackagingOptionsLabels.tube,
  [PackagingOptions.tray]: PackagingOptionsLabels.tray,
  [PackagingOptions.bulk]: PackagingOptionsLabels.bulk,
  [PackagingOptions.tapeSingle]: PackagingOptionsLabels.tapeSingle,
  [PackagingOptions.tapeMultiple]: PackagingOptionsLabels.tapeMultiple,
  [PackagingOptions.reel]: PackagingOptionsLabels.reel,
};

export enum MslOptions {
  msl1 = '1',
  msl2 = '2',
  msl2a = '2a',
  msl3 = '3',
  msl4 = '4',
  msl5 = '5',
  msl5a = '5a',
  msl6 = '6',
}

export const mslAllowedExposureHours = {
  '2': 8760,
  '2a': 672,
  '3': 168,
  '4': 72,
  '5': 48,
  '5a': 24,
  '6': 0,
};

export type HandlingTaskRow = {
  id: string;
  status: string;
  description: string;
  notes: string;
  requestType: HandlingRequestType;
  submittedAt: string;
  completedAt: string;
  org?: string;
  stockLot?: StockLotLite;
  relatedRecords: RelatedRecord[];
  voidable: boolean;
  taskType: string;
  taskTypeDisplay: string;
  shipmentCode?: string | null;
};

export type RelatedRecord = {
  name: string;
  recordType: string;
  recordId: string;
  orgId: string | null;
};

export type HandlingRequestCreate = {
  orgId: string;
  requestType: HandlingRequestType;
  stockLotIds: string[];
  notes?: string;
  deadline?: string | Date;
  quant?: number;
  stockLocationId?: string;
  package?: string;
};

export enum HandlingRequestStatus {
  DRAFT = 'draft',
  REQUESTED = 'requested',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  PASSED = 'passed',
  FAILED = 'failed',
}

export type HandlingRequestLite = {
  id: string;
  requestType: HandlingRequestType;
  requestTypeDisplay: string;
  quant?: number;
  notes?: string;
  deadline?: DateString | Date;
  package?: string;
  status: HandlingRequestStatus;
  completedAt: DateTimeString | null;
  pickedAt: DateTimeString | null;
};

export type HandlingRequest = HandlingRequestLite & {
  billable: boolean;
  cancelled: boolean;
  completedAt: string | null;
  org: Org['id'];
  status: HandlingRequestStatus;
  stockLocation: StockLocation['id'];
  stockLot: StockLotCRUDSchema['id'];
  splitStockLot: StockLotCRUDSchema['id'] | null;
  submittedAt: DateTimeString;
  pickedAt: DateTimeString | null;
};

export type ExpandedHandlingRequest = Omit<
  HandlingRequest,
  'stockLot' | 'splitStockLot'
> & {
  stockLot: StockLotCRUDSchema;
  splitStockLot: StockLotCRUDSchema | null;
};

export type HandlingRequestToConsumeShipmentLine = {
  id: string;
  shipment: ShipmentLite;
  externalNotes: string;
  printNotes: string;
};

export type HandlingRequestToConsumeStockLot = Omit<
  StockLotLite,
  'handlingRequests'
> & {
  shipmentLines: HandlingRequestToConsumeShipmentLine[];
  handlingRequests: HandlingRequestLite[];
};

export type HandlingRequestToConsumeConsume = {
  id: string;
  org: OrgLite;
  stockLot: HandlingRequestToConsumeStockLot;
  shipmentLine: HandlingRequestToConsumeShipmentLine;
  quant: number;
  part: Part;
  notes: string;
};

export type DeadlineRequest = {
  deadlineRequests: DeadlineRequestItem[];
};

export type DeadlineRequestItem = {
  stockLotId: StockLotCRUDSchema['id'];
  requestTypes: HandlingRequestType[];
};

export type Deadline = {
  stockLotId: StockLotCRUDSchema['id'];
  requestTypes: HandlingRequestType[];
  fromDate: DateString;
  deadline: DateString;
  daysAfter: number;
};

// [ msl_reset, splice, combine, reel, anticounterfeiting, split, move, check_quant, dispose, repackage ]

export enum HandlingRequestType {
  MSL_RESET = 'msl_reset',
  SPLICE = 'splice',
  COMBINE = 'combine',
  REEL = 'reel',
  ANTICOUNTERFEITING = 'anticounterfeiting',
  SPLIT = 'split',
  TRANSFER = 'transfer',
  CONSUME = 'consume',
  CHECK_QUANT = 'check_quant',
  DISPOSE = 'dispose',
  REPACKAGE = 'repackage',
  RECEIVE = 'receive',
  INSPECTION_PHOTO = 'inspection_photo',
  INSPECTION_PHOTO_SAMPLE = 'inspection_photo_sample',
  INSPECTION_PHOTO_FULL = 'inspection_photo_full',
  XRAY = 'x_ray',
  X_RAY_SAMPLE = 'x_ray_sample',
  X_RAY_FULL = 'x_ray_full',
  PACKAGE_PHOTO = 'package_photo',
  INSPECTION_DIMENSIONAL = 'inspection_dimensional',
  INSPECTION_LCR = 'inspection_lcr',
  ANTICOUNTERFEITING_SAMPLE = 'anticounterfeiting_sample',
  ANTICOUNTERFEITING_FULL = 'anticounterfeiting_full',
  INSPECTION_SURFACE = 'inspection_surface',
  INSPECTION_DECAP = 'inspection_decap',
  INSPECTION_3RD_PARTY = 'inspection_3rd_party',
  SHIP = 'ship',
  RESELL = 'resell',
}

export const executionOrder = [
  HandlingRequestType.PACKAGE_PHOTO,
  HandlingRequestType.DISPOSE,
  HandlingRequestType.CHECK_QUANT,
  HandlingRequestType.INSPECTION_LCR,
  HandlingRequestType.INSPECTION_DIMENSIONAL,
  HandlingRequestType.INSPECTION_PHOTO,
  HandlingRequestType.INSPECTION_PHOTO_SAMPLE,
  HandlingRequestType.INSPECTION_PHOTO_FULL,
  HandlingRequestType.XRAY,
  HandlingRequestType.X_RAY_SAMPLE,
  HandlingRequestType.X_RAY_FULL,
  HandlingRequestType.INSPECTION_SURFACE,
  HandlingRequestType.INSPECTION_DECAP,
  HandlingRequestType.INSPECTION_3RD_PARTY,
  HandlingRequestType.ANTICOUNTERFEITING_SAMPLE,
  HandlingRequestType.ANTICOUNTERFEITING_FULL,
  HandlingRequestType.MSL_RESET,
  HandlingRequestType.SPLIT,
  HandlingRequestType.SPLICE,
  HandlingRequestType.COMBINE,
  HandlingRequestType.REEL,
  HandlingRequestType.REPACKAGE,
  HandlingRequestType.SHIP,
  HandlingRequestType.RESELL,
];

export const HandlingRequestTypeLabels: {
  [key in HandlingRequestType]: string;
} = {
  [HandlingRequestType.MSL_RESET]: 'MSL Reset',
  [HandlingRequestType.SPLICE]: 'Splice',
  [HandlingRequestType.COMBINE]: 'Combine',
  [HandlingRequestType.REEL]: 'Reel',
  [HandlingRequestType.ANTICOUNTERFEITING]: 'Anti-counterfeiting',
  [HandlingRequestType.SPLIT]: 'Split',
  [HandlingRequestType.TRANSFER]: 'Transfer',
  [HandlingRequestType.CHECK_QUANT]: 'Check Quantity',
  [HandlingRequestType.DISPOSE]: 'Dispose',
  [HandlingRequestType.REPACKAGE]: 'Repackage',
  [HandlingRequestType.RECEIVE]: 'Receive',
  [HandlingRequestType.CONSUME]: 'Consume',
  [HandlingRequestType.INSPECTION_PHOTO]: 'Inspection Photo',
  [HandlingRequestType.INSPECTION_PHOTO_SAMPLE]: 'Inspection Photo Sample',
  [HandlingRequestType.INSPECTION_PHOTO_FULL]: 'Inspection Photo Full',
  [HandlingRequestType.XRAY]: 'X-Ray',
  [HandlingRequestType.X_RAY_SAMPLE]: 'X-Ray Sample',
  [HandlingRequestType.X_RAY_FULL]: 'X-Ray Full',
  [HandlingRequestType.PACKAGE_PHOTO]: 'Package Photo',
  [HandlingRequestType.INSPECTION_DIMENSIONAL]: 'Dimensional Inspection',
  [HandlingRequestType.INSPECTION_LCR]: 'LCR Inspection',
  [HandlingRequestType.ANTICOUNTERFEITING_SAMPLE]: 'Anti-counterfeiting Sample',
  [HandlingRequestType.ANTICOUNTERFEITING_FULL]: 'Anti-counterfeiting Full',
  [HandlingRequestType.INSPECTION_SURFACE]: 'Surface Inspection',
  [HandlingRequestType.INSPECTION_DECAP]: 'Decap Inspection',
  [HandlingRequestType.INSPECTION_3RD_PARTY]: 'Third-party Inspection',
  [HandlingRequestType.SHIP]: 'Ship',
  [HandlingRequestType.RESELL]: 'Resell',
};

export enum StockEventType {
  MSL_TRACK = 'msl_track',
  SPLICE = 'splice',
  COMBINE = 'combine',
  REEL = 'reel',
  ANTICOUNTERFEITING = 'anticounterfeiting',
  SPLIT = 'split',
  TRANSFER = 'transfer',
  ADJUST_QUANT = 'adjust_quant',
  DISPOSE = 'dispose',
  REPACKAGE = 'repackage',
  RECEIVE = 'receive',
  INSPECTION_PHOTO = 'inspection_photo',
  XRAY = 'x_ray',
  CONSUME = 'consume',
  PACKAGE_PHOTO = 'package_photo',
  INSPECTION_DIMENSIONAL = 'inspection_dimensional',
  INSPECTION_LCR = 'inspection_lcr',
  ANTICOUNTERFEITING_SAMPLE = 'anticounterfeiting_sample',
  ANTICOUNTERFEITING_FULL = 'anticounterfeiting_full',
  INSPECTION_SURFACE = 'inspection_surface',
  INSPECTION_DECAP = 'inspection_decap',
  INSPECTION_3RD_PARTY = 'inspection_3rd_party',
  RESELL = 'resell',
}

export enum StockDocType {
  INSPECTION_PHOTO = 'inspection_photo',
  INSPECTION_PHOTO_BULK = 'inspection_photo_bulk',
  PACKAGE_PHOTO = 'package_photo',
  X_RAY = 'x_ray',
  X_RAY_BULK = 'x_ray_bulk',
  DOCUMENT = 'document',
  REPORT = 'report',
  QUOTE = 'quote',
  PACKING_SLIP = 'packing_slip',
  COFC = 'c_of_c',
  PURCHASE_UPDATE = 'purchase_update',
  PURCHASE_CONFIRMATION = 'purchase_confirmation',
  INVOICE = 'invoice',
  SHIP_NOTIFICATION = 'ship_notification',
  PURCHASE_ORDER = 'purchase_order',
  RFQ = 'rfq',
  FILE = 'file',
}

export const StockDocTypeLabels: { [key in StockDocType]: string } = {
  [StockDocType.INSPECTION_PHOTO]: 'Inspection Photo',
  [StockDocType.INSPECTION_PHOTO_BULK]: 'Inspection Photo (Bulk)',
  [StockDocType.PACKAGE_PHOTO]: 'Package Photo',
  [StockDocType.X_RAY]: 'X-Ray Image',
  [StockDocType.X_RAY_BULK]: 'X-Ray Image (Bulk)',
  [StockDocType.DOCUMENT]: 'Document',
  [StockDocType.REPORT]: 'Report',
  [StockDocType.QUOTE]: 'Quote',
  [StockDocType.PACKING_SLIP]: 'Packing Slip',
  [StockDocType.COFC]: 'Certificate of Conformance',
  [StockDocType.PURCHASE_UPDATE]: 'Purchase Update',
  [StockDocType.PURCHASE_CONFIRMATION]: 'Purchase Confirmation',
  [StockDocType.INVOICE]: 'Invoice',
  [StockDocType.SHIP_NOTIFICATION]: 'Shipment Notification',
  [StockDocType.PURCHASE_ORDER]: 'Purchase Order',
  [StockDocType.RFQ]: 'RFQ',
  [StockDocType.FILE]: 'File',
};

export type ShipmentLineCreate = {
  stockLotId: string;
  notes?: string;
  printedNotes?: string;
};

export type ShipmentCreate = {
  shipFromId?: string;
  shipToId: string;
  addressId?: string;
  carrier?: string;
  service?: string;
  deadline?: string | Date;
  notificationEmail?: string;
  shipPartial?: boolean;
  notes?: string;
  productionRun?: string | null;
  trackingNumber?: string;
  approved?: boolean;
  internalNotes?: string | null;
  team?: Team['id'];
};

export type ShipmentMarkAsShipped = {
  trackingNumber?: string;
};

export enum ShipmentType {
  WAREHOUSE_TO_NONWAREHOUSE = 'warehouse_to_nonwarehouse',
  NONWAREHOUSE_TO_WAREHOUSE = 'nonwarehouse_to_warehouse',
  WAREHOUSE_TO_WAREHOUSE = 'warehouse_to_warehouse',
  NONWAREHOUSE_TO_NONWAREHOUSE = 'nonwarehouse_to_nonwarehouse',
  KIT_WITHIN_WAREHOUSE = 'kit_within_warehouse',
  UNSPECIFIED = 'unspecified',
}

export const ShipmentTypeLabels: { [key: string]: string } = {
  warehouse_to_nonwarehouse: 'Warehouse → Other Address',
  nonwarehouse_to_warehouse: 'Other Address → Warehouse',
  warehouse_to_warehouse: 'Warehouse → Warehouse',
  nonwarehouse_to_nonwarehouse: 'Other Address → Other Address',
  kit_within_warehouse: 'Kit Transfer',
  unspecified: 'Unspecified',
};

export enum ShipmentStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  PICKED = 'picked',
  PACKED = 'packed',
  SHIPPED = 'shipped',
  RECEIVED = 'received',
  CANCELLED = 'cancelled',
  REQUESTED = 'requested',
  INGESTED = 'ingested',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  PARTIALLY_SHIPPED = 'partially_shipped',
}

export const ShipmentStatusIgnoreConflicts = [
  ShipmentStatus.COMPLETE,
  ShipmentStatus.SHIPPED,
  ShipmentStatus.RECEIVED,
];

// shipment solver types
export enum ShipmentSolverPrioritizationOptions {
  smallestFirst = 'smallest_first',
  largestFirst = 'largest_first',
  closestMatch = 'closest_match',
  oldestFirst = 'oldest_first',
  newestFirst = 'newest_first',
}

export enum ShipmentSolverPrioritizationLabels {
  smallestFirst = 'Smallest Quantity First',
  largestFirst = 'Largest Quantity First',
  closestMatch = 'Closest Quantity Match',
  oldestFirst = 'Oldest Purchase Date First',
  newestFirst = 'Newest Purchase Date First',
}

export const prioritizationOptions = (
  Object.keys(
    ShipmentSolverPrioritizationOptions
  ) as (keyof typeof ShipmentSolverPrioritizationOptions)[]
).map((option) => option);

export type ShipmentSolverPartRequest = {
  requestLineId?: string;
  part: Part['id'];
  quant: number;
  alts?: Part['id'][];
  notes?: string;
  printedNotes?: string;
};

export type ShipmentSolverFailedLine = {
  requestLineId: string;
  part: Part;
  explanation: string;
  quant: number;
};

export enum ShipmentSolverStatusOptions {
  draftSuccess = 'draft_success',
  draftPartial = 'draft_partial',
  draftFail = 'draft_fail',
  success = 'success',
  partial = 'partial',
  fail = 'fail',
}

export type ShipmentSolver = {
  status: string;
  shipmentsCreated: number;
  shipmentLinesCreated: number;
  handlingRequestsCreated: number;
  sourceFacilities: StockLocation[];
  failedRequests: ShipmentSolverFailedLine[];
};

export type ShipmentSolver2 = {
  shipments: Shipment[];
  handlingRequests: HandlingRequest[];
  status: ShipmentSolverStatusOptions;
  sourceFacilities: StockLocationLite[];
  failedRequests: ShipmentSolverFailedLine[];
};

export type ShipmentSolver2Request = {
  productionRunId?: ProductionRun['id'];
  partRequests: ShipmentSolverPartRequest[];
  shipToId: string;
  carrier: string;
  service: string;
  deadline?: string | Date | null;
  notificationEmail?: string;
  shipPartial?: boolean;
  notes?: string;
  shipments?: Shipment['id'][];
  limitToFacilities?: StockLocation['id'][];
  addOverage?: boolean;
  includeExpectedStock?: boolean;
  includeProcessingStock?: boolean;
  includeOnhandStock?: boolean;
  globalNotePrefix?: string;
  globalNoteSuffix?: string;
  addBomLineNumberNote?: boolean;
  addRefdesNote?: boolean;
  draftMode?: boolean;
  prioritization?: ShipmentSolverPrioritizationOptions;
  bomIds?: BomCRUDSchema['id'][];
};

export type Supplier = {
  id: string;
  name: string;
  authenticity?: number;
  accuracy?: number;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  certifications: string[];
  buyable?: boolean;
  purchaseOption?: string;
  scheduledReleasePeriod?: number;
  freeShip?: {
    threshold: number | null;
    shippingLead: number | null;
  };
  bestCaseLead?: number | null;
  isCustomizedInfo?: boolean;
};

export type SupplierCreate = Omit<Supplier, 'id'>;

export type SupplierLite = {
  id: string;
  name: string;
};

export type PurchaseRule = {
  id: string;
  name: string;
  org: string;
  authorizedOnly: boolean;
  requiredCertifications: string[];
  allowedSuppliers: Supplier[] | Supplier['id'][];
  preferredSuppliers: Supplier[] | Supplier['id'][];
  lastResortSuppliers: Supplier[] | Supplier['id'][];
  bannedSuppliers: Supplier[] | Supplier['id'][];
  allowedOrgSuppliers: OrgSupplier[];
  preferredOrgSuppliers: OrgSupplier[];
  lastResortOrgSuppliers: OrgSupplier[];
  bannedOrgSuppliers: OrgSupplier[];
  isDefaultPurchaseRule: string;
};

export type Printer = {
  id: string;
  name: string;
  locked: boolean;
  extPrintnodeId: string;
  externalName: string | null;
  active: boolean;
  internalNotes: string;
  externalNotes: string;
  printerType: string;
  org: Org;
  stockLocation: StockLocationLite;
  connected: boolean;
};

export enum LabelFormat {
  StockLotDefault = 'StockLotDefault',
  StockLocationDefault = 'StockLocationDefault',
  StockLocationVertical = 'StockLocationVertical',
  StockLocationShipment = 'StockLocationShipment',
  StockLocationCustomer = 'StockLocationCustomer',
  StockLocationBinSection = 'StockLocationBinSection',
  ShipmentDefault = 'ShipmentDefault',
  ShipmentLineDefault = 'ShipmentLineDefault',
  PrinterDefault = 'PrinterDefault',
  UserDefault = 'UserDefault',
}

export type LabelTemplate = {
  id: string;
  name: string;
  locked: boolean;
  zpl: string;
  externalName: string | null;
  active: boolean;
  internalNotes: string;
  externalNotes: string;
  labelType: string;
  printerType: string;
  labelFormat: LabelFormat | null;
  org: Org | null;
};

export type LabelRequest = {
  printer: string;
  templateName: string;
};

export type PrintLabelRequest = {
  printer: string;
  labelTemplate: string;
  additionalData: Object;
};

export type PrintStockDocumentRequest = {
  printer: string;
};

export type LabelPreviewRequest = {
  shipment: string | null;
  stockLocation: string | null;
  stockLot: string | null;
  shipmentLine: string | null;
  printer: string | null;
  additionalData: Object;
};

export type PreviewZpl = {
  zpl: string;
  labelTemplate: string;
};

export type PrinterTypeSpec = {
  description: string;
  width: number;
  height: number;
};

export const printerTypeSpecs: { [key: string]: PrinterTypeSpec } = {
  '0302_thermal': {
    description: '3in x 2in Thermal Transfer',
    width: 3,
    height: 2,
  },
  '0402_thermal': {
    description: '4in x 2in Thermal Transfer',
    width: 4,
    height: 2,
  },
  '0406_thermal': {
    description: '4in x 6in Thermal Transfer',
    width: 4,
    height: 6,
  },
};

export type StockEventCreate = {
  eventType: StockEventType;
  mslFloorHours?: number;
  quant?: number;
  billable?: boolean;
  internalNotes?: string;
  externalNotes?: string;
  package?: string;
  stockLots: string[];
  stockLocation?: string;
  shipmentLine?: string | null;
};

export type PickListStockLotGroup = {
  stockLot: StockLotLite;
  shipmentLines: ShipmentLine[];
  handlingRequests: HandlingRequestLite[];
};

export type PickListTreeNode = {
  thisLocation: StockLocationLite;
  childStockLocations: PickListTreeNode[];
  stockLotGroups: PickListStockLotGroup[];
};

export type PickingFilters = {
  stockLocationId: string;
  shipmentIds: string[] | null;
  includeShipments: boolean;
  includeHandling: boolean;
  includePicked: boolean;
  handlingTypes: string[] | null;
  orgIds: string[] | null;
};

export type StockLocationPickListGETSchema = {
  stockLocationId: string;
  shipmentIds: string[] | null;
  handlingTypes: string[] | null;
  orgIds: string[] | null;
  sandbox: boolean;
};

export type HandlingRequestsGETSchema = {
  stockLotId?: StockLotCRUDSchema['id'];
  facilityId?: StockLocation['id'];
  sandbox?: boolean;
  requestTypes?: HandlingRequestType[];
};

export const pickTypeMap: { [key: string]: string } = {
  [HandlingRequestType.MSL_RESET]: HandlingRequestType.MSL_RESET,
  [HandlingRequestType.SPLICE]: HandlingRequestType.COMBINE,
  [HandlingRequestType.COMBINE]: HandlingRequestType.COMBINE,
  [HandlingRequestType.REEL]: HandlingRequestType.REEL,
  [HandlingRequestType.ANTICOUNTERFEITING]: 'inspection',
  [HandlingRequestType.SPLIT]: HandlingRequestType.SPLIT,
  [HandlingRequestType.TRANSFER]: HandlingRequestType.TRANSFER,
  [HandlingRequestType.CHECK_QUANT]: HandlingRequestType.CHECK_QUANT,
  [HandlingRequestType.DISPOSE]: HandlingRequestType.DISPOSE,
  [HandlingRequestType.REPACKAGE]: HandlingRequestType.REEL,
  [HandlingRequestType.INSPECTION_PHOTO]: 'inspection',
  [HandlingRequestType.INSPECTION_PHOTO_SAMPLE]: 'inspection',
  [HandlingRequestType.INSPECTION_PHOTO_FULL]: 'inspection',
  [HandlingRequestType.XRAY]: 'inspection',
  [HandlingRequestType.X_RAY_SAMPLE]: 'inspection',
  [HandlingRequestType.X_RAY_FULL]: 'inspection',
  [HandlingRequestType.PACKAGE_PHOTO]: HandlingRequestType.PACKAGE_PHOTO,
  [HandlingRequestType.INSPECTION_DIMENSIONAL]: 'inspection',
  [HandlingRequestType.INSPECTION_LCR]: 'inspection',
  [HandlingRequestType.ANTICOUNTERFEITING_SAMPLE]: 'inspection',
  [HandlingRequestType.ANTICOUNTERFEITING_FULL]: 'inspection',
  [HandlingRequestType.INSPECTION_SURFACE]: 'inspection',
  [HandlingRequestType.INSPECTION_DECAP]: 'inspection',
  [HandlingRequestType.INSPECTION_3RD_PARTY]: 'inspection',
  [HandlingRequestType.SHIP]: HandlingRequestType.SHIP,
};

export const MslValueOptions = ['1', '2', '2A', '3', '4', '5', '5A', '6'];

export type SupplierRequest = {
  autocomplete?: string | null;
  ids?: string;
  query?: string;
};

export const packageValueOptions = [
  { value: 'tube', label: 'Tube' },
  { value: 'tray', label: 'Tray' },
  { value: 'bulk', label: 'Bulk' },
  { value: 'tape_single', label: 'Cut Tape (Single)' },
  { value: 'tape_multiple', label: 'Cut Tape (Multiple)' },
  { value: 'reel', label: 'Reel' },
];

export const packageTypeOptions = [
  { value: 'adjust_quant', label: 'Quantity Adjustment' },
  { value: 'receive', label: 'Receive' },
  { value: 'purchase', label: 'Purchase' },
  { value: 'split', label: 'Split' },
  { value: 'dispose', label: 'Trash' },
  { value: 'ship', label: 'Shipment' },
  { value: 'user_create', label: 'Created By User' },
  { value: 'splice', label: 'Splice' },
  { value: 'combine', label: 'Combine' },
  { value: 'consume', label: 'Consumed by Production' },
];

export const tapeSizeOptions = [
  '-',
  '8mm',
  '12mm',
  '16mm',
  '24mm',
  '32mm',
  '44mm',
  '56mm',
];

// We a server side version of this because client side doesn't support using just numbers as values

export const mountTypeOptions = ['SMT', 'THT', 'Other'];

export type DocumentTemplate = {
  id: string;
  name: string;
  locked: boolean;
  externalName: string | null;
  active: boolean;
  default: boolean;
  internalNotes: string;
  externalNotes: string;
  org: Org | null;
  templateId: string;
  documentType: StockDocType;
};
