import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';
import AuthGuard from 'utils/route-guard/AuthGuard';
import OrgGuard from 'utils/route-guard/OrgGuard';
import { DASHBOARD_ROUTES, USER_ACCOUNT_ROUTES } from 'routes/constants';
import { ALL_APPS } from 'constants/appConstants';

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <OrgGuard>
        <MainLayout />
      </OrgGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: DASHBOARD_ROUTES.PARTS,
      element: <Navigate to={`${ALL_APPS.PART_SEARCH.path}`} replace />,
    },
    {
      path: DASHBOARD_ROUTES.PARTS_SEARCH,
      element: <Navigate to={`${ALL_APPS.PART_SEARCH.path}`} replace />,
    },
    {
      path: `${DASHBOARD_ROUTES.PARTS_SEARCH}/:partId`,
      element: <Redirect to={`${ALL_APPS.PART_SEARCH.path}/:partId`} />,
    },
    {
      path: DASHBOARD_ROUTES.PARTS_WATCHLIST,
      element: <Navigate to={`${ALL_APPS.WATCHLIST.path}`} replace />,
    },
    {
      path: DASHBOARD_ROUTES.PURCHASING_PARTS_QUOTES,
      element: <Navigate to={`${ALL_APPS.QUOTES.path}`} replace />,
    },
    {
      path: DASHBOARD_ROUTES.PURCHASING_ORDERS,
      element: <Navigate to={`${ALL_APPS.ORDERS.path}`} replace />,
    },
    {
      path: `${DASHBOARD_ROUTES.PURCHASING_ORDERS}/:orderId`,
      element: <Redirect to={`${ALL_APPS.ORDERS.path}/:orderId`} />,
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_SHIPPING,
      element: (
        <Navigate to={`${ALL_APPS.KITTING.views?.SHIPMENTS?.path}`} replace />
      ),
    },
    {
      path: `${DASHBOARD_ROUTES.INVENTORY_SHIPPING}/:shipmentId`,
      element: (
        <Redirect
          to={`${ALL_APPS.KITTING.views?.SHIPMENTS?.path}/:shipmentId`}
        />
      ),
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_LOCATIONS,
      element: (
        <Navigate
          to={`${ALL_APPS.KITTING.views?.STOCK_LOCATIONS?.path}`}
          replace
        />
      ),
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_HANDLING,
      element: (
        <Navigate
          to={`${ALL_APPS.MANAGED_INVENTORY.views?.HANDLING_TASKS?.path}`}
          replace
        />
      ),
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_PARTS,
      element: (
        <Navigate to={`${ALL_APPS.STOCK.views?.PART_STOCK?.path}`} replace />
      ),
    },
    {
      path: `${DASHBOARD_ROUTES.INVENTORY_PARTS}/:partId`,
      element: (
        <Redirect to={`${ALL_APPS.STOCK.views?.PART_STOCK?.path}/:partId`} />
      ),
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_STOCK_LOTS,
      element: (
        <Navigate to={`${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}`} replace />
      ),
    },
    {
      path: `${DASHBOARD_ROUTES.INVENTORY_STOCK_LOTS}/:stockLotId`,
      element: (
        <Redirect
          to={`${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/:stockLotId`}
        />
      ),
    },
    {
      path: DASHBOARD_ROUTES.INVENTORY_ALLOCATIONS,
      element: (
        <Navigate to={`${ALL_APPS.STOCK.views?.ALLOCATIONS?.path}`} replace />
      ),
    },
    {
      path: DASHBOARD_ROUTES.PRODUCTION_RUNS,
      element: <Redirect to={`${ALL_APPS.PRODUCTION.path}`} />,
    },
    {
      path: `${DASHBOARD_ROUTES.PRODUCTION_RUNS}/:runId`,
      element: <Redirect to={`${ALL_APPS.PRODUCTION.path}/:runId`} />,
    },
    {
      path: `${ALL_APPS.PRODUCTION.deprecatedPath}`,
      element: <Redirect to={`${ALL_APPS.PRODUCTION.path}`} />,
    },
    {
      path: `${ALL_APPS.PRODUCTION.deprecatedPath}/:runId`,
      element: <Redirect to={`${ALL_APPS.PRODUCTION.path}/:runId`} />,
    },
    {
      path: DASHBOARD_ROUTES.ACCOUNT,
      element: <Navigate to={`${ALL_APPS.USER_SETTINGS.path}`} replace />,
    },
    {
      path: DASHBOARD_ROUTES.PRODUCTION_RUNS,
      element: <Navigate to={`${ALL_APPS.PRODUCTION.path}`} replace />,
    },
    {
      path: USER_ACCOUNT_ROUTES.USER_ACCOUNT,
      element: (
        <Navigate
          to={`${ALL_APPS.USER_SETTINGS.views?.PROFILE?.path}`}
          replace
        />
      ),
    },
    {
      path: USER_ACCOUNT_ROUTES.PROFILE,
      element: (
        <Navigate
          to={`${ALL_APPS.USER_SETTINGS.views?.PROFILE?.path}`}
          replace
        />
      ),
    },
    {
      path: USER_ACCOUNT_ROUTES.SECURITY,
      element: (
        <Navigate
          to={`${ALL_APPS.USER_SETTINGS.views?.SECURITY?.path}`}
          replace
        />
      ),
    },
    {
      path: USER_ACCOUNT_ROUTES.NOTIFICATIONS,
      element: (
        <Navigate
          to={`${ALL_APPS.USER_SETTINGS.views?.NOTIFICATIONS?.path}`}
          replace
        />
      ),
    },
  ],
};

export default MainRoutes;
