import _ from 'lodash';
import {
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  Stack,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from 'react-hook-form';
import { ReactNode } from 'react';
import ClickableMainCard from 'ui-component/cards/ClickableMainCard';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import { Icon } from '@tabler/icons-react';
import { SxProps } from '@mui/system';
import { Theme, useTheme } from '@mui/material/styles';

interface HookFormToggleCardButtonProps {
  errors?: FieldErrors;
  control: Control;
  name: string;
  label: string | ReactNode;
  options: {
    value: unknown;
    label: string;
    description: string;
    icon?: Icon;
  }[];
  rules?: RegisterOptions;
  defaultValue?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  valueAsInt?: boolean;
  sx?: SxProps<Theme>;
  [key: string]: unknown;
  alert?: ReactNode;
}

export const HookFormToggleCardButton = ({
  errors,
  control,
  name,
  label,
  options,
  defaultValue,
  disabled,
  multiple = false,
  valueAsInt = false,
  sx,
  alert,
  ...rest
}: HookFormToggleCardButtonProps) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth sx={{ mb: 2, ...sx }}>
      <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, width: '80%' }}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rest.rules}
        render={({ field: { onChange, value } }) => (
          <Grid container spacing={1} alignItems="stretch">
            {options.map((option) => {
              const CardIcon = option.icon!;
              return (
                <Grid
                  key={option.label}
                  item
                  xs={Math.ceil(12 / options.length)}
                  sx={{ width: '100%', display: 'flex' }}
                >
                  <ClickableMainCard
                    sx={{
                      flexGrow: 1,
                      backgroundColor: _.isEqual(value, option.value)
                        ? theme.palette.success.light
                        : undefined,
                    }}
                    headerSX={{ p: actionButtonSpacing }}
                    contentSX={{ p: actionButtonSpacing }}
                    title={
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        {option.icon && <CardIcon />}
                        <Typography variant="h4">{option.label}</Typography>
                      </Stack>
                    }
                    boxShadow={!disabled}
                    onClick={() => onChange(option.value)}
                    highlight={_.isEqual(value, option.value)}
                    disabled={disabled}
                  >
                    <Typography>{option.description}</Typography>
                  </ClickableMainCard>
                </Grid>
              );
            })}
            {errors?.[name]?.message && (
              <FormHelperText error id={`${name}Error`} sx={{ ml: 0 }}>
                {errors?.[name]?.message}
              </FormHelperText>
            )}
            {!!alert && (
              <Grid item xs={12} sx={{ mt: gridSpacing }}>
                {alert}
              </Grid>
            )}
          </Grid>
        )}
      />
    </FormControl>
  );
};
