import React from 'react';

import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { Badge } from '@mui/material';

import { FeedMetadata } from '@knocklabs/client';

export type BadgeCountType = 'unseen' | 'unread' | 'all';

export type UnseenBadgeProps = {
  badgeCountType?: BadgeCountType;
  content: React.ReactElement;
};

function selectBadgeCount(
  badgeCountType: BadgeCountType,
  metadata: FeedMetadata
) {
  switch (badgeCountType) {
    case 'all':
      return metadata.total_count;
    case 'unread':
      return metadata.unread_count;
    case 'unseen':
      return metadata.unseen_count;
  }
  return metadata.unseen_count;
}

export const KnockUnreadBadge: React.FC<UnseenBadgeProps> = ({
  badgeCountType = 'unseen',
  content,
}) => {
  const { useFeedStore } = useKnockFeed();
  const badgeCountValue = useFeedStore((state) =>
    selectBadgeCount(badgeCountType, state.metadata)
  );

  return (
    <Badge badgeContent={badgeCountValue} color="error" max={99}>
      {content}
    </Badge>
  );
};
