import { useSelector } from 'store';
import { useEffect, useState } from 'react';
import { useGetOrgQuery } from 'store/slices/org';
import { skipToken } from '@reduxjs/toolkit/query/react';
import logo from 'assets/images/cofactr_logo.png';

import Loader from 'ui-component/Loader';
import { API_URL, IS_LOCAL } from 'constants/envConstants';

const Logo = () => {
  const { activeOrgId } = useSelector((state) => state.org);
  const [img, setImg] = useState('');
  const { data: org, isLoading: isOrgLoading } = useGetOrgQuery(
    activeOrgId ?? skipToken
  );

  useEffect(() => {
    // only set the image if there is one
    // image url in production is full url but in development is missing the host
    // images that start with http were generated in fixtures and should be unchanged
    if (org && org.image?.startsWith('http')) {
      setImg(org.image);
    } else if (org && org.image && org.image.length > 0) {
      setImg(`${IS_LOCAL ? API_URL : ''}${org.image}`);
    } else {
      setImg('');
    }
  }, [org]);

  if (isOrgLoading) {
    return <Loader />;
  }

  return (
    <img
      src={img || logo}
      alt={`${org && img.length > 0 ? org.name : 'Cofactr'} Logo`}
      width="150"
      height="30"
      style={{ objectFit: 'contain', maxWidth: 'none', height: '30px' }}
      onError={() => {
        setImg('');
      }}
    />
  );
};

export default Logo;
