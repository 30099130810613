// HookFormTableInput.tsx
import { FC, ComponentType } from 'react';
import { useFieldArray, Control, FieldErrors } from 'react-hook-form';
import {
  IconButton,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableRow,
  TableCellProps,
  TableProps,
  TableRowProps,
  TableHead,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconX, IconPlus } from '@tabler/icons-react';

type TableInputFieldProps = {
  // TODO: Replace with specific type in UI-2732
  component: ComponentType<any>;
  name: string;
  props?: object;
  label?: string;
};

type HookFormTableInputProps = {
  control: Control;
  name: string;
  fields: TableInputFieldProps[];
  errors: FieldErrors;
  componentProps?: {
    tableProps?: TableProps;
    tableBodyProps?: TableBodyProps;
    tableRowProps?: TableRowProps;
    tableCellProps?: TableCellProps;
  };
};

export const HookFormTableInput: FC<HookFormTableInputProps> = ({
  control,
  name,
  fields,
  errors,
  componentProps: {
    tableProps = {},
    tableBodyProps = {},
    tableRowProps = {},
    tableCellProps = {},
  } = {},
}) => {
  const theme = useTheme();
  const {
    fields: fieldArrayFields,
    append,
    remove,
  } = useFieldArray({ control, name });

  return (
    <Table {...tableProps}>
      <TableHead>
        <TableRow {...tableRowProps}>
          <TableCell
            {...tableCellProps}
            padding="checkbox"
            sx={{ border: 'none', ...tableCellProps?.sx }}
          >
            <IconButton
              aria-label="add"
              onClick={() =>
                append(
                  Object.fromEntries(
                    fields.map(({ name: fieldName }) => [fieldName, ''])
                  )
                )
              }
            >
              <IconPlus color={theme.palette.primary.main} />
            </IconButton>
          </TableCell>
          {fields.map(({ label }, id) => (
            <TableCell
              key={id}
              {...tableCellProps}
              sx={{ border: 'none', ...tableCellProps?.sx }}
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody {...tableBodyProps}>
        {fieldArrayFields.map((item, index) => (
          <TableRow key={item.id} {...tableRowProps}>
            <TableCell
              {...tableCellProps}
              padding="checkbox"
              sx={{ border: 'none', ...tableCellProps?.sx }}
            >
              <IconButton aria-label="delete" onClick={() => remove(index)}>
                <IconX color={theme.palette.error.main} />
              </IconButton>
            </TableCell>
            {fields.map(
              (
                { component: InputComponent, name: subFieldName, props },
                id
              ) => (
                <TableCell
                  key={id}
                  {...tableCellProps}
                  sx={{ border: 'none', ...tableCellProps?.sx }}
                >
                  <InputComponent
                    name={`${name}.${index}.${subFieldName}`}
                    control={control}
                    errors={errors}
                    {...props}
                  />
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
