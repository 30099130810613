import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { gridSpacing } from 'constants/themeConstants';
import { useIsCofactr } from 'hooks/useIsCofactr';
import React, { useEffect } from 'react';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/org';
import { User } from 'types/user';

const Calendly = ({ user }: { user?: User }) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head?.appendChild(script);
  }, []);

  return (
    <div
      className="calendly-inline-widget"
      data-url={`https://calendly.com/d/cp5b-cvb-j6j/cofactr-onboarding?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=38d059&name=${user?.firstName}%20${user?.lastName}&email=${user?.email}`}
      style={{ minWidth: '700px', height: '700px' }}
    />
  );
};

const OnboardingDialog = () => {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const { isCofactr, user } = useIsCofactr();

  if (!org?.onboardingRequired || isCofactr) return <></>;
  return (
    <Dialog open maxWidth="lg">
      <DialogTitle>Schedule Your Onboarding</DialogTitle>
      <DialogContent>
        <Typography variant="h5" sx={{ mt: gridSpacing }}>
          Welcome to Cofactr! Let&#39;s get you setup.
        </Typography>
        <Typography variant="h5" sx={{ mb: gridSpacing }}>
          Schedule a brief onboarding call with our team to start your trial and
          activate your account.
        </Typography>
        {user && <Calendly user={user} />}
      </DialogContent>
    </Dialog>
  );
};
export default OnboardingDialog;
