import { useLocation } from 'react-router-dom';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { useMemo } from 'react';

export function useCurrentApp() {
  const location = useLocation();
  const { appsWithPermissions, isLoading } = useAppAccessContext();

  const currentApp = useMemo(
    () =>
      isLoading
        ? null
        : Object.values(appsWithPermissions ?? []).find((a) =>
            location.pathname.startsWith(`${a.path}`)
          ),
    [location.pathname, isLoading]
  );

  const currentView = useMemo(() => {
    if (currentApp?.views) {
      return Object.values(currentApp.views).find((v) =>
        location.pathname.startsWith(`${v?.path}`)
      );
    }
    return null;
  }, [location.pathname, currentApp?.views]);

  return {
    currentApp,
    currentView,
  };
}
