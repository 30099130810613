import {
  FormControl,
  FormHelperText,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FieldErrors } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type HookFormToggleButtonProps = {
  errors?: FieldErrors;
  label?: string;
  children: ReactNode;
  defaultValue?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  valueAsInt?: boolean;
  sx?: SxProps<Theme>;
  buttonGroupSx?: SxProps<Theme>;
  fullWidth?: boolean;
  /**
   * noDeselectAllowed: boolean
   *
   * This prop determines whether deselecting is allowed in the ToggleButtonGroup.
   * If set to true, once a button is selected, it cannot be deselected.
   * If set to false or not provided, buttons can be deselected.
   */
  noDeselectAllowed?: boolean;
  [props: string]: unknown;
} & HookFormComponentProps;

export const HookFormToggleButton = ({
  errors,
  control,
  name = '',
  label,
  children,
  defaultValue,
  disabled,
  multiple = false,
  valueAsInt = false,
  noDeselectAllowed = false,
  sx,
  buttonGroupSx,
  fullWidth = true,
  ...rest
}: HookFormToggleButtonProps) => (
  <FormControl fullWidth={fullWidth} sx={{ mb: 2, ...sx }}>
    <Typography variant="caption">{label}</Typography>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <ToggleButtonGroup
            id={name}
            sx={buttonGroupSx}
            value={value ?? (multiple ? [] : '')}
            onChange={(_, newVal) => {
              if (noDeselectAllowed && newVal === null) return;
              onChange(valueAsInt ? parseInt(newVal, 10) : newVal);
            }}
            disabled={disabled}
            defaultValue={defaultValue}
            exclusive={!multiple}
            {...rest}
          >
            {children}
          </ToggleButtonGroup>
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`} sx={{ ml: 0 }}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  </FormControl>
);
