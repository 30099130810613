import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'constants/themeConstants';

type BannerProps = {
  bannerMessage: string;
  buttonText?: string;
  showButton?: boolean;
  backgroundColor?: string;
  onButtonClick?: () => void;
};

const Banner = ({
  bannerMessage,
  buttonText,
  showButton,
  backgroundColor,
  onButtonClick,
}: BannerProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: backgroundColor ?? theme.palette.success.main,
        padding: '2px',
        color: '#FFF',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={gridSpacing}
        sx={{ height: showButton ? 'inherit' : '29px' }}
      >
        <Typography variant="body1" color="inherit" noWrap>
          {bannerMessage}
        </Typography>
        {showButton && (
          <SegmentButton
            size="small"
            onClick={onButtonClick}
            sx={{
              color: 'background.paper',
              fontSize: '12px',
              margin: 'auto',
            }}
          >
            {buttonText}
          </SegmentButton>
        )}
      </Stack>
    </Box>
  );
};

export default Banner;
