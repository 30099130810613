import { createContext, ReactNode, useContext } from 'react';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

type PusherContextType = {
  pusherChannel: PusherTypes.Channel;
  jobsChannel: PusherTypes.Channel;
};
export const PusherContext = createContext({} as PusherContextType);

export const usePusherContext = () => useContext(PusherContext);

const PusherProvider = ({ children }: { children: ReactNode }) => {
  const pusher = new Pusher(process.env.PUSHER_APP_KEY || '', {
    cluster: process.env.PUSHER_APP_CLUSTER || '',
  });
  const pusherChannel = pusher.subscribe('parts');
  const jobsChannel = pusher.subscribe('jobs');

  return (
    <PusherContext.Provider value={{ pusherChannel, jobsChannel }}>
      {children}
    </PusherContext.Provider>
  );
};

export default PusherProvider;
