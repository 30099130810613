import * as Yup from 'yup';

export const formConstants = {
  mpn: { name: 'mpn', label: 'MPN' },
  customId: { name: 'customId', label: 'Custom Part Number' },
  mfg: { name: 'manufacturerId', label: 'Manufacturer' },
  customMfg: { name: 'customManufacturerName' },
  classification: { name: 'classificationId', label: 'Classification' },
  customClassification: {
    name: 'customClassificationName',
  },
  msl: { name: 'msl', label: 'MSL' },
  description: { name: 'description', label: 'Description' },
  package: { name: 'package', label: 'Package' },
  terminations: { name: 'terminations', label: 'Number of Terminations' },
  terminationType: { name: 'terminationType', label: 'Mount Type' },
};

export const validationSchema = Yup.object().shape({
  [formConstants.mpn.name]: Yup.string().required(
    `${formConstants.mpn.label} is required`
  ),
  [formConstants.customId.name]: Yup.string(),
  [formConstants.mfg.name]: Yup.string().when([formConstants.customMfg.name], {
    is: (customMfg: string) => !customMfg,
    then: (schema) => schema.required(`${formConstants.mfg.label} is required`),
  }),
  [formConstants.classification.name]: Yup.string(),
  [formConstants.msl.name]: Yup.string(),
  [formConstants.description.name]: Yup.string(),
  [formConstants.package.name]: Yup.string(),
  [formConstants.terminations.name]: Yup.number()
    .nullable()
    .transform((currentValue, originalValue) =>
      originalValue === '' ? null : currentValue
    )
    .typeError(`${formConstants.terminations.label} must be a number`),
  [formConstants.terminationType.name]: Yup.string(),
});

export const packageTypes = [
  'Axial',
  '8-SOIC',
  '0805',
  '0603',
  'SMD',
  '1206',
  'SOT-23-5',
  '16-SOIC',
  '0402',
  'Module',
  '6-CLCC',
  'SOT-23-3',
  '24-QFN',
  'MELF',
  '6-USPC',
  'SOT-25',
  '2512',
  '1210',
  '10-CLCC',
  '16-TSSOP',
  '14-SOIC',
  '20-SOIC',
  'DO-204AL (DO-41)',
  '100-TQFP',
  '8-MSOP',
  '8-PDIP',
  '100-LQFP',
  'TO-92-3',
  'DO-35 (DO-204AH)',
  '20-TSSOP',
  '64-QFN',
  '2010',
  '0201',
  '14-TSSOP',
  '8-TSSOP',
  'SOT-89-5',
  '64-LQFP',
  '28-SOIC',
  '32-QFN',
  'TO-220',
  'DO-213AA',
  'SOD-123',
  '1008',
  'DO-214AC (SMA)',
  '64-TQFP',
  '8-SO',
  'TO-220-3',
  '16-PDIP',
  '28-SSOP',
  '20-SSOP',
  'DO-35',
  '48-LQFP',
  'SOT-25-5',
  'Die',
  '1812',
  '16-QFN',
  'SSOT-24',
  'DO-41',
  'SOD-323',
  '24-SOIC',
  'TO-220AB',
  'SOT-23-6',
  '8-DIP',
  '8-DFN',
  '48-QFN',
  '20-QFN',
  'SOT-23',
  '16-SO',
  '10-MSOP',
  'D2PAK',
  '144-LQFP',
  '4-SMD',
  '6-SMD',
  '16-SSOP',
  '40-QFN',
  '10-DFN',
  'T-1 3/4',
  '2-SMD',
  '28-PLCC',
  '2-PLCC',
  '4-USPN',
  'Radial',
  '24-TSSOP',
  'SOT-323',
  '44-PLCC',
  'TO-263AB (D²PAK)',
  'Radial Lead',
  '20-PDIP',
  'DPAK',
  '44-TQFP',
  '8-VSSOP',
  '16-QSOP',
  '6-DIP',
  '8-SOP',
  '28-QFN',
  'SC-70-5',
  'SOT-23-3 (TO-236)',
  '48-TSSOP',
  'SC-82AB',
  'DO-214AB (SMC)',
  'Sub SMA',
  'SOT-23-8',
  'Mini MELF',
  '44-QFN',
  '48-TQFP',
  'DO-214AA (SMB)',
  '01005',
  '14-SO',
  '6-USPEL',
  '8-WSON',
  'DO-213AB',
  '32-LQFP',
  'DO-5',
  '100-QFP',
  '8-TDFN',
  '14-PDIP',
  '8-SMD',
  '80-LQFP',
  'SOT-89-3',
  '8-uMAX/uSOP',
  '4-USP',
  '28-TSSOP',
  '32-PLCC',
  'DO-201AD',
  'SC-70-3',
  '4-SIP',
  '120-LQFP',
  '64-QFP',
  '208-PQFP',
  '48-SSOP',
  'SOT-6',
  'TO-236AB',
  'SNT-4A',
  '20-SO',
  'TO-92',
  '1212',
  '1152-FBGA',
  'TO-220AC',
  '24-SSOP',
  '10-SIP',
  'TSOT-23-5',
  '28-PDIP',
  '24-PDIP',
  '8-SIP',
  '32-TQFP',
  '6-WSON',
  'SOD-523',
  '0612',
  'ITO-220AB',
  '0806',
  '56-TSSOP',
  '16-DIP',
  'T-1',
  '4527',
  'SOT-223',
  'USPQ-4B05',
  'SNT-6A',
  '100-LFQFP',
  '1517-FBGA',
  '4-PLCC',
  'TSOT-23-6',
  'TSOT-23-8',
  '6-DFN',
  '165-FBGA',
  'SOT-89',
  '484-FPBGA',
  'D-Pak',
  '32-VQFN',
  'SOT-223-3',
  '4-DIP',
  '144-TQFP',
  '0804',
  'DO-213AB (MELF, LL41)',
  'SOT-363',
  '48-VQFN',
  'SC-70-6',
  '64-VQFN',
  '6-SIP',
  '2835',
  '64-LFQFP',
  '68-PLCC',
  'TO-92 (TO-226)',
  '64-FBGA',
  '56-SSOP',
  'SC-70-4',
  '44-TSOP II',
  'DO-4',
  '20-TQFN',
  '256-FPBGA',
  '40-PDIP',
  '16-TQFN',
  '3030',
  'T-18',
  'TO-220-5',
  'TO-247-3',
  'TO-247',
  'DDPAK/TO-263-5',
  'TO-252',
  'D²PAK (TO-263)',
  '4-SOP',
  '18-SOIC',
  '20-PLCC',
  '5-TSOP',
  'TO-252AA',
  '0606',
  '1616',
  '484-FBGA',
  'DO-219AB (SMF)',
  '14-DIP',
];
