import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

// TODO: replace this with code that uses org preferences instead of global defaults
export function useDeviceContext() {
  const { pathname } = useLocation();
  const mobileVersion = pathname.includes('/mobileinventory');
  const desktopScreen = useMediaQuery('(min-width:800px)');
  const narrowWindow = useMediaQuery('(max-width:1000px)');
  const mainViewPadding = desktopScreen ? 3 : 1;

  return {
    mobileVersion,
    desktopScreen,
    mainViewPadding,
    narrowWindow,
  };
}
