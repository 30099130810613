import { memo, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Grid, useMediaQuery } from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';

import MenuList from 'layout/MainLayout/Sidebar/MenuList';
import LogoSection from 'layout/MainLayout/LogoSection';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import cofactrLogo from 'assets/images/cofactr_logo.png';
import cofactrSmallLogo from 'assets/images/Cofactr_logomark_clover_256x256.png';
import { drawerWidth, collapsedDrawerWidth } from 'constants/themeConstants';
import { useNewVersion } from 'contexts/NewVersionContext';
import { CommandBar } from 'ui-component/Commandbar/Commandbar';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import { useGetUserQuery } from 'store/slices/user';

interface SidebarProps {
  window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { showNewVersion } = useNewVersion();
  const { showNoPlanBanner } = useSelector((state) => state.utility);

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const commandbarFeature = useFeature(FeatureFlags.tempCommandbar).on;
  const { isSuccess: isGetUserSuccess, data: user } = useGetUserQuery();

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    []
  );

  const drawer = useMemo(
    () => (
      <Grid container justifyContent="center" sx={{ pt: 2 }}>
        <PerfectScrollbar
          component="div"
          style={{
            height: 'calc(100vh - 160px)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          {isGetUserSuccess && commandbarFeature && (
            <CommandBar userId={user.id} />
          )}
          <div style={{ overflowX: 'hidden' }}>
            <MenuList />
          </div>
        </PerfectScrollbar>

        {drawerOpen && (
          <Grid item xs="auto" sx={{ mt: 2 }}>
            <img src={cofactrLogo} alt="Cofactr Logo" width="100" />
          </Grid>
        )}
        {!drawerOpen && (
          <Grid item xs="auto" sx={{ mt: 2 }}>
            <img src={cofactrSmallLogo} alt="Cofactr Logo" width="24" />
          </Grid>
        )}
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen]
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="permanent"
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            top: `${
              88 +
              [showNewVersion, showNoPlanBanner].filter((val) => !!val).length *
                30
            }px`,
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {matchUpMd && logo}
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
