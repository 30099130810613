import { createAction } from '@reduxjs/toolkit';
import { LoginResponse } from 'types/session';
import { PartId } from 'types/part';

const LOGIN = 'session/login';
const LOGOUT = 'session/logout';
const SET_TOKEN = 'session/setToken';
const SET_REFRESH_TOKEN = 'session/setRefreshToken';
const SET_STORED_LOCATION = 'session/setStoredLocation';
const SET_STORED_ORG = 'session/setStoredOrg';
const SOFT_LOGOUT = 'session/softLogout';
const FINISH_SOFT_LOGOUT = 'session/finishSoftLogout';
const HANDLE_ORG_SWITCH = 'session/handleOrgSwitch';
export const ADD_PARTS_CORE = 'partData/addPartsCore';
export const ADD_PARTS_ALTS = 'partData/addPartsAlts';
export const ADD_PARTS_SPECS = 'partData/addPartsSpecs';
export const ADD_PARTS_SUPPLY = 'partData/addPartsSupply';
export const ADD_PARTS_MARKET = 'partData/addPartsMarket';

// These actions are handled in a given reducers' "extraReducers" section
export const login = createAction<LoginResponse>(LOGIN);
export const logout = createAction(LOGOUT);
// A softLogout is when their refresh token expired,
// and they're likely to log right back in.
export const softLogout = createAction(SOFT_LOGOUT);
export const setToken = createAction<string | null>(SET_TOKEN);
export const setRefreshToken = createAction<string | null>(SET_REFRESH_TOKEN);
export const setStoredLocation = createAction<string>(SET_STORED_LOCATION);
export const setStoredOrg = createAction<string>(SET_STORED_ORG);
export const finishSoftLogout = createAction(FINISH_SOFT_LOGOUT);
export const handleOrgSwitch = createAction<{ customPartIds: PartId[] }>(
  HANDLE_ORG_SWITCH
);
