import { useTheme } from '@mui/material/styles';
import Banner from 'ui-component/Banner';

const NoPlanBanner = () => {
  const theme = useTheme();

  return (
    <Banner
      bannerMessage="Your account does not currently have an active Cofactr plan. Please contact success@cofactr.com to subscribe to the Cofactr platform."
      backgroundColor={theme.palette.brand.cobalt}
    />
  );
};

export default NoPlanBanner;
