import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { GenericObject } from 'types';

export enum Operators {
  '=' = '',
  '>' = '__gt',
  '>=' = '__gte',
  '<' = '__lt',
  '<=' = '__lte',
  'is' = '__iexact',
  'equals' = '__iexact',
  'after' = '__gt',
  'onOrAfter' = '__gte',
  'before' = '__lt',
  'onOrBefore' = '__lte',
  'contains' = '__icontains',
  'startsWith' = '__istartswith',
  'endsWith' = '__iendswith',
  'isEmpty' = '__isnull',
  'isNotEmpty' = '__isnull', // the apparent duplication is intentional, see the NullOperatorValues enum
  'isAnyOf' = '__in',
  'not' = '',
  '!=' = '',
}

export enum NullOperatorValues {
  'isEmpty' = 'true',
  'isNotEmpty' = 'false',
}

export enum OrderDirectionValues {
  asc = '',
  desc = '-',
}

export enum FilterLinkOperatorsValues {
  and = ',',
  or = '|',
}

export const ALL = '__all__';

type commaSeparatedString<T> = keyof T extends infer K
  ? K extends string
    ? K extends keyof T
      ? `${K}${Exclude<commaSeparatedStringExclude<T, K>, ''> extends never
          ? ''
          : ','}${Exclude<commaSeparatedStringExclude<T, K>, ''>}`
      : never
    : never
  : never;

type commaSeparatedStringExclude<T, U> = keyof T extends infer K
  ? K extends string
    ? U extends K
      ? never
      : K
    : never
  : never;

type SubSchema<T> = keyof T | { [key in keyof T]?: SubSchema<T[keyof T]>[] };

export type NestedPath<T extends GenericObject> = {
  [K in keyof T]: T[K] extends GenericObject ? K | `${string & K}` : K;
}[keyof T];

/**
 * NestedPaths
 * Get all the possible paths of an object
 * @example
 * type Keys = NestedPaths<{ a: { b: { c: string } }>
 * // 'a' | 'a.b' | 'a.b.c'
 */
export type NestedPaths<T extends GenericObject> = {
  [K in keyof T]: T[K] extends GenericObject
    ? K | `${string & K}.${string & NestedPath<T[K]>}`
    : K;
}[keyof T];

export type NestedTypeField<T extends GenericObject> = keyof T | NestedPaths<T>;

/**
 * FieldFilter
 * @example
 * type FieldFilter = FieldFilter<StockLot>
 * // { field: 'id' | 'org' | 'status' | 'quant' | 'part.id' | 'part.mpn', operator: '=', value: any }
 */
export type FieldFilter<T extends GenericObject> = {
  field: NestedTypeField<T>;
  operator: keyof typeof Operators;
  value: unknown;
};

export interface QueryParams<T extends GenericObject> {
  pageSize?: number;
  pageNumber?: number;
  // order by can be any property of T for example QueryParams<StockLot> can be {order_by: 'quantity'}
  sort?: {
    orderBy: keyof T;
    orderDirection: keyof typeof OrderDirectionValues;
  }[];
  // filter is an array of objects with keys of T and any value
  filters?:
    | []
    | [FieldFilter<T>]
    | [
        FieldFilter<T>,
        ...(FieldFilter<T> | keyof typeof FilterLinkOperatorsValues)[],
        FieldFilter<T>
      ];
  excludes?:
    | []
    | [FieldFilter<T>]
    | [
        FieldFilter<T>,
        ...(FieldFilter<T> | keyof typeof FilterLinkOperatorsValues)[],
        FieldFilter<T>
      ];
  // schema is an array of strings or objects with keys of T and values of strings or objects with keys of T and values of strings
  schema?:
    | keyof T
    | (
        | keyof T
        | typeof ALL
        | {
            [key in keyof T]?: SubSchema<T[key]>[];
          }
      )[]
    | commaSeparatedString<T>;
  noCache?: boolean;
  newVersion?: boolean;
  blended?: boolean;
  search?: string;
  searchSchema?:
    | keyof T
    | (
        | keyof T
        | typeof ALL
        | {
            [key in keyof T]?: SubSchema<T[key]>[];
          }
      )[]
    | commaSeparatedString<T>;
  overrideOrgId?: string;
}

export interface ListResponse<T> {
  count: number;
  pages: number;
  data: T[];
}

export interface AsyncJob {
  id: string;
}

export enum AsyncJobStatus {
  starting = 'starting',
  processing = 'processing',
  done = 'done',
  error = 'error',
}

export interface AsyncJobResponse<T> {
  jobId: string;
  status: AsyncJobStatus;
  data: T;
}

/**
 * Transforms a type by replacing object properties with their string representation, typically used for ID fields.
 * If the property is an array of objects, it replaces the array with an array of strings.
 *
 * The transformation is done as follows:
 * - If a property is an object and not part of the keys specified in `K`, it is replaced with a string.
 * - If a property is an array of objects and not part of the keys specified in `K`, it is replaced with an array of strings.
 * - If a property is an array but not of objects, it remains the same.
 * - All other properties remain the same.
 * - If a property key is part of `K`, it will not be transformed and will retain its original type.
 *
 * This is useful when you want to simplify complex objects or arrays of objects to their ID representations which is often how Flagship will represent its query schema.
 *
 * @example
 * type AllocationQuerySchema = { id: string, org: Org, stockLot: { id: string, name: string }, productionRun: { id: string, name: string }[] };
 * type SimplifiedAllocationQuerySchema = Lite<AllocationQuerySchema, 'stockLot'>; // { id: string, org: string, stockLot: { id: string, name: string }, productionRun: string[] }
 *
 * @example
 * type AllocationQuerySchemaWithArray = { id: string, org: Org, stockLot: { id: string, name: string }[], productionRun: { id: string, name: string }[] };
 * type SimplifiedAllocationQuerySchemaWithArray = Lite<AllocationQuerySchemaWithArray, 'stockLot'>; // { id: string, org: string, stockLot: { id: string, name: string }[], productionRun: string[] }
 */
export type Lite<T, K extends keyof T = never> = {
  [P in keyof T]: P extends K
    ? T[P]
    : T[P] extends Array<infer U>
    ? U extends object
      ? string[]
      : T[P]
    : T[P] extends object
    ? string
    : T[P] extends object | null
    ? string | null
    : T[P];
};

/**
 * `PropertySubsetValidator` is a utility type that checks if a given type `T` can be represented as a subset of its own properties specified by `K`.
 *
 * It takes two type parameters:
 * - `T`: The original type.
 * - `K`: The keys of `T` that we want to pick.
 *
 * If `T` can be represented as a `Pick<T, K>`, then `T` is returned; otherwise, `never` is returned.
 *
 * @example
 * type User = { name: string, age: number };
 * type ValidUserProps = PropertySubsetValidator<User, 'name'>; // This will be User if 'name' exists in User, otherwise never.
 *
 * // Usage in function parameters to ensure correct type:
 * function doSomething(
 *   allocations:
 *     | PropertySubsetValidator<AllocationQuerySchema, 'name'>[]
 *     | PropertySubsetValidator<AllocationCRUDSchema, 'name'>[]
 * ) {
 *   return allocations.filter((allocation) => allocation.name === 'test');
 * }
 *
 * const allocation = [{ name: 'hi' }] as AllocationQuerySchema[];
 * const relatedRecord = [{ name: 'hi' }] as RelatedRecord[];
 *
 * doSomething(allocation); // valid
 * doSomething(relatedRecord); // TS2345: Argument of type RelatedRecord[] is not assignable to parameter of type AllocationCRUDSchema[] | AllocationQuerySchema[]
 */
export type PropertySubsetValidator<T, K extends keyof T> = Pick<T, K> &
  Partial<Record<Exclude<keyof T, K>, any>>;

export type RefetchQuery<Request, Response> = QueryActionCreatorResult<
  QueryDefinition<
    Request,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    Response,
    string
  >
>;
