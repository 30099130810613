import _ from 'lodash';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { analyticsTrack } from 'utils/functions';
import { forwardRef, ForwardedRef, memo } from 'react';
import { PermissionScope } from 'types/apps';
import usePermissionStateAndCopyHook from 'hooks/usePermissionStateAndCopyHook';

interface SegmentIconButtonProps extends IconButtonProps {
  trackingName?: string;
  permissionScope?: PermissionScope;
  tooltip?: string;
  outlineVariant?: boolean;
}

const SegmentIconButton = forwardRef(
  (
    {
      trackingName,
      permissionScope,
      tooltip,
      children,
      outlineVariant,
      sx,
      ...rest
    }: SegmentIconButtonProps,
    ref: ForwardedRef<any>
  ) => {
    const { permissionDisabled, disabledCopy } = usePermissionStateAndCopyHook({
      permissionScope,
    });
    // TODO: we should get the actual click handler types in here properly
    function handleClick(...args: any) {
      if (trackingName) {
        analyticsTrack(
          `${trackingName || rest['aria-label']} Icon Button Clicked`,
          {
            props: rest,
            ariaLabel: rest['aria-label'],
            trackingName,
          }
        );
      }
      if (rest.onClick) {
        rest.onClick(args);
      }
    }

    return (
      <Tooltip title={disabledCopy ?? tooltip ?? ''}>
        <span>
          <IconButton
            {..._.omitBy(rest, ['onClick'])}
            onMouseDown={(event) => event.stopPropagation()}
            onTouchStart={(event) => event.stopPropagation()}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleClick();
            }}
            ref={ref}
            disabled={permissionDisabled || rest.disabled}
            sx={{
              ...(sx || {}),
              ...(outlineVariant ? { border: 1, borderRadius: 1 } : {}),
            }}
          >
            {children}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
);

export default memo(SegmentIconButton);
