import * as Yup from 'yup';

export const createUpdateTeamFormConstants = {
  name: { id: 'name', label: 'Name' },
  teamType: { id: 'teamType', label: 'Team Type' },
};

export const createUpdateTeamValidationSchema = (
  handleReplaceWithCustomTeamName: (inputString: string) => string
) =>
  Yup.object().shape({
    [createUpdateTeamFormConstants.name.id]: Yup.string().required(
      `${createUpdateTeamFormConstants.name.label} is required`
    ),
    [createUpdateTeamFormConstants.teamType.id]: Yup.string().required(
      handleReplaceWithCustomTeamName(
        `${createUpdateTeamFormConstants.teamType.label} is required`
      )
    ),
  });

export const createUpdateTeamTypeFormConstants = {
  name: { id: 'name', label: 'Name' },
  pluralName: { id: 'pluralName', label: 'Plural Name' },
};

export const createUpdateTeamTypeValidationSchema = Yup.object().shape({
  [createUpdateTeamTypeFormConstants.name.id]: Yup.string().required(
    `${createUpdateTeamTypeFormConstants.name.label} is required`
  ),
  [createUpdateTeamTypeFormConstants.pluralName.id]: Yup.string()
    .required(
      `${createUpdateTeamTypeFormConstants.pluralName.label} is required`
    )
    .typeError('Must be a string'),
});
