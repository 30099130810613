import _ from 'lodash';
import { TabsProps, Tabs } from '@mui/material';
import { analyticsTrack } from 'utils/functions';
import { SyntheticEvent, forwardRef, ForwardedRef } from 'react';

interface SegmentTabsProps extends TabsProps {
  trackingName?: string;
}

const SegmentTabs = forwardRef(
  (
    { trackingName, children, ...rest }: SegmentTabsProps,
    ref: ForwardedRef<any>
  ) => (
    <Tabs
      {..._.omitBy(rest, ['onChange'])}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={(event: SyntheticEvent, newValue: string) => {
        if (trackingName) {
          analyticsTrack(`${newValue} Tab Clicked`, {
            props: _.omitBy(rest, ['onChange']),
            ariaLabel: rest['aria-label'],
            tabName: rest.value,
            trackingName,
          });
        }
        if (rest.onChange) {
          rest.onChange(event, newValue);
        }
      }}
      ref={ref}
    >
      {children}
    </Tabs>
  )
);

export default SegmentTabs;
