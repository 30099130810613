export enum PartSchemas {
  core = 'core',
  alts = 'alts',
  specs = 'specs',
  supply = 'supply',
  market = 'market',
}

export enum partStatusRequestTypes {
  REQUESTING = 'requesting',
  READY = 'ready',
  FETCHING = 'fetching',
  SUCCESS = 'success',
  ERROR = 'error',
  READY_RETRY_NOW = 'ready_retry_now',
  REQUESTING_RETRY = 'requesting_retry',
  FETCHING_RETRY = 'fetching_retry',
  KB_FAILURE = 'kb_failure',
  RETRY_NOW_FAILED = 'retry_now_failed',
}

export const LoadingStatusTypes = [
  partStatusRequestTypes.REQUESTING,
  partStatusRequestTypes.REQUESTING_RETRY,
  partStatusRequestTypes.READY,
  partStatusRequestTypes.READY_RETRY_NOW,
  partStatusRequestTypes.FETCHING,
  partStatusRequestTypes.FETCHING_RETRY,
];

export const DoneLoadingStatusTypes = [
  partStatusRequestTypes.SUCCESS,
  partStatusRequestTypes.KB_FAILURE,
  partStatusRequestTypes.RETRY_NOW_FAILED,
];

export type PartRequestStateType = {
  [PartSchemas.core]: partStatusRequestTypes | null;
  [PartSchemas.alts]: partStatusRequestTypes | null;
  [PartSchemas.specs]: partStatusRequestTypes | null;
  [PartSchemas.supply]: partStatusRequestTypes | null;
  [PartSchemas.market]: partStatusRequestTypes | null;
};

export const DefaultPartRequestState = {
  [PartSchemas.core]: null,
  [PartSchemas.alts]: null,
  [PartSchemas.specs]: null,
  [PartSchemas.supply]: null,
  [PartSchemas.market]: null,
} as PartRequestStateType;

export enum PartSupplyRetryStatus {
  RETRY_FAILED = 'retry_failed',
  KB_FAILURE = 'kb_failure',
}

export const SupplyColumnFailureText = {
  [PartSupplyRetryStatus.RETRY_FAILED]:
    'Some supplier data did not load. Please refresh the page, or reach out to Cofactr Success.',
  [PartSupplyRetryStatus.KB_FAILURE]:
    'Some supplier data did not load. Please retry later, or reach out to Cofactr Success.',
};

export const StatusColumnFailureText = {
  [PartSupplyRetryStatus.RETRY_FAILED]:
    'Some supplier data did not load. This status may not be accurate. Please refresh the page, or reach out to Cofactr Success.',
  [PartSupplyRetryStatus.KB_FAILURE]:
    'Some supplier data did not load. This status may not be accurate. Please retry later, or reach out to Cofactr Success.',
};
