// theme constant
export const gridSpacing = 3;
export const actionButtonSpacing = 1;
export const infoSectionRowBorderTopSx = {
  borderBottom: '1px solid #F5F5F5',
  pb: 3,
  px: 0,
};
export const infoSectionRowBorderSx = {
  borderBottom: '1px solid #F5F5F5',
  py: 3,
  px: 0,
  ml: 0,
};
export const infoSectionRowSx = { py: 3, px: 0, ml: 0 };
export const mobileInfoSectionRowBorderSx = {
  borderBottom: '1px solid #F5F5F5',
  py: 2,
  px: 0,
  ml: 0,
};
export const mobileInfoSectionRowSx = { pt: 2, px: 0 };
export const drawerWidth = 260;
export const collapsedDrawerWidth = 74;
