import {
  Autocomplete,
  Box,
  FormHelperText,
  TextField,
  AutocompleteProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';

type Option<T> = {
  value: T;
  label: string;
};

type HookFormAutocompleteMultipleProps<T> = {
  label: string;
} & HookFormComponentProps &
  Omit<AutocompleteProps<Option<T>, true, false, false>, 'renderInput'>;

const DefaultGetOptionLabel = (option: Option<unknown>) =>
  option.label as string;

export const HookFormAutocompleteMultiple = <T extends unknown>({
  name = '',
  control,
  options,
  label,
  errors,
  getOptionLabel,
  disabled,
}: HookFormAutocompleteMultipleProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value, onBlur, ref } }) => (
      <Autocomplete
        multiple
        blurOnSelect={false}
        options={options}
        value={
          (value as Option<T>[])
            ?.map((v) => options.find((o) => o.value === v))
            .filter(Boolean) || []
        }
        onChange={(__, data) => onChange(data?.map((d) => d?.value))}
        onBlur={onBlur}
        getOptionLabel={(option) =>
          option
            ? getOptionLabel
              ? getOptionLabel(option)
              : DefaultGetOptionLabel(option)
            : ''
        }
        renderInput={(params) => (
          <Box sx={{ mb: 2 }}>
            <TextField {...params} label={label} fullWidth inputRef={ref} />
            {errors?.[name]?.message && (
              <FormHelperText error id={`${name}Error`}>
                {errors?.[name]?.message}
              </FormHelperText>
            )}
          </Box>
        )}
        disabled={disabled}
      />
    )}
  />
);
