import {
  Suspense,
  LazyExoticComponent,
  ComponentType,
  JSX,
  ReactNode,
} from 'react';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Loader from 'ui-component/Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

interface LoaderProps extends LinearProgressProps {}

const Loadable =
  (
    Component: LazyExoticComponent<() => JSX.Element> | ComponentType<ReactNode>
  ) =>
  (props: LoaderProps) =>
    (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
