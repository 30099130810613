import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { styled, useTheme, Theme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from '@mui/material';

import AppLocation from 'layout/MainLayout/AppLocation';
import Header from 'layout/MainLayout/Header';
import Sidebar from 'layout/MainLayout/Sidebar';
// import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { DASHBOARD_ROUTES } from 'routes/constants';

import Dashboard from 'views/dashboard';
import ScreenSizeAlert from 'ui-component/ScreenSizeAlert';
import { useNewVersion } from 'contexts/NewVersionContext';
import { drawerWidth, collapsedDrawerWidth } from 'constants/themeConstants';
import { NewVersionBanner } from 'ui-component/NewVersionBanner';
import NoPlanBanner from 'ui-component/NoPlanBanner';

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${(open ? drawerWidth : collapsedDrawerWidth) + 20}px)`,
  })
);

const MainLayout = () => {
  const { showNewVersion } = useNewVersion();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { container } = useConfig();

  const { showNoPlanBanner } = useSelector((state) => state.utility);

  useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const header = useMemo(
    () => (
      <Toolbar sx={!matchDownMd ? { pl: '20px !important' } : {}}>
        <Header />
      </Toolbar>
    ),
    []
  );

  const sxAmount = useMemo(() => {
    const filteredBanners = [showNewVersion, showNoPlanBanner].filter(
      (val) => !!val
    );
    return filteredBanners.length === 0
      ? 0
      : 118 + (filteredBanners.length - 1) * 30;
  }, [showNoPlanBanner, showNewVersion]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        {showNewVersion && <NewVersionBanner />}
        {showNoPlanBanner && <NoPlanBanner />}

        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main
        theme={theme}
        open={drawerOpen}
        sx={sxAmount > 0 ? { mt: `${sxAmount}px` } : {}}
      >
        {/* breadcrumb */}
        {container && (
          <Container maxWidth="lg">
            {window.location.pathname === '/' ||
            window.location.pathname === DASHBOARD_ROUTES.DASHBOARD ? (
              <Dashboard />
            ) : (
              <>
                <AppLocation />
                <Outlet />
              </>
            )}
          </Container>
        )}
        {!container && (
          <>
            {window.location.pathname === '/' ||
            window.location.pathname === DASHBOARD_ROUTES.DASHBOARD ? (
              <Dashboard />
            ) : (
              <>
                <AppLocation />
                <Outlet />
              </>
            )}
          </>
        )}
      </Main>
      <ScreenSizeAlert />
    </Box>
  );
};

export default MainLayout;
