import * as Yup from 'yup';

export const formConstants = {
  name: { id: 'name', label: 'Name' },
  authorizedOnly: {
    id: 'authorizedOnly',
    label: 'Use only authorized Suppliers',
  },
  requiredCertifications: {
    id: 'requiredCertifications',
    label: 'Required Certifications',
  },
  allowedOrgSuppliers: {
    id: 'allowedOrgSuppliers',
    label: 'Allowed Suppliers',
  },
  preferredOrgSuppliers: {
    id: 'preferredOrgSuppliers',
    label: 'Preferred Suppliers',
  },
  lastResortOrgSuppliers: {
    id: 'lastResortOrgSuppliers',
    label: 'Last Resort Suppliers',
  },
  bannedOrgSuppliers: { id: 'bannedOrgSuppliers', label: 'Banned Suppliers' },
  isDefaultPurchaseRule: {
    id: 'makeDefault',
    label: 'Is Default Purchase Rule',
  },
};

export const validationSchema = Yup.object().shape({
  [formConstants.name.id]: Yup.string()
    .max(255, 'Name must be under 255 characters')
    .required('Name is required'),
  [formConstants.authorizedOnly.id]: Yup.boolean().required(
    'Authorized Only is required'
  ),
  [formConstants.requiredCertifications.id]: Yup.array()
    .of(Yup.string())
    .required('Required Certifications is required'),
  [formConstants.allowedOrgSuppliers.id]: Yup.array()
    .of(Yup.string())
    .required('Allowed Suppliers is required'),
  [formConstants.preferredOrgSuppliers.id]: Yup.array()
    .of(Yup.string())
    .required('Preferred Suppliers is required'),
  [formConstants.lastResortOrgSuppliers.id]: Yup.array()
    .of(Yup.string())
    .required('Last Resort Suppliers is required'),
  [formConstants.bannedOrgSuppliers.id]: Yup.array()
    .of(Yup.string())
    .required('Banned Suppliers is required'),
  [formConstants.isDefaultPurchaseRule.id]: Yup.string().required(
    'Is Default Purchase Rule is required'
  ),
});

export const getCopy = (isEdit: boolean) => ({
  title: isEdit ? 'Edit Purchase Rule' : 'Create Purchase Rule',
  submit: isEdit ? 'Save' : 'Submit',
  success: isEdit
    ? 'Purchase Rule successfully updated'
    : 'Purchase Rule successfully created',
});
