import { NavItemType } from 'types';
import { USER_ACCOUNT_ROUTES } from 'routes/constants';

// ==============================|| MENU ITEMS ||============================== //

const items = [
  {
    id: 'user-account-navigation',
    type: 'group',
    children: [
      {
        id: 'user-account',
        title: 'User Account',
        type: 'collapse',
        url: USER_ACCOUNT_ROUTES.USER_ACCOUNT,
        children: [
          {
            id: 'profile',
            title: 'User Account: Profile',
            type: 'item',
            url: USER_ACCOUNT_ROUTES.PROFILE,
          },
          {
            id: 'security',
            title: 'User Account: Security',
            type: 'item',
            url: USER_ACCOUNT_ROUTES.SECURITY,
          },
          {
            id: 'notifications',
            title: 'User Account: Notifications',
            type: 'item',
            url: USER_ACCOUNT_ROUTES.NOTIFICATIONS,
          },
        ],
      },
    ],
  },
];

const userAccountMenuItems: { items: NavItemType[] } = {
  items,
};

export default userAccountMenuItems;
