import { PermissionScope } from 'types/apps';
import { useEffect, useState } from 'react';
import { getDisabledPermissionCopy } from 'utils/permissions';
import { useAppAccessContext } from 'contexts/AppAccessContext';

export default function usePermissionStateAndCopyHook({
  permissionScope,
}: {
  permissionScope?: PermissionScope;
}) {
  const { isPermissionDisabled, getDisabledReason } = useAppAccessContext();
  const [disabledCopy, setDisabledCopy] = useState<string | null>(null);
  const permissionDisabled = isPermissionDisabled(permissionScope);

  useEffect(() => {
    if (permissionDisabled && permissionScope) {
      const copy = getDisabledPermissionCopy(
        getDisabledReason(permissionScope)
      );
      setDisabledCopy(`${copy.message} ${copy.action}`);
    }
    // if permission isn't disabled, and we have a disabled copy, clear it
    if (!permissionDisabled && Boolean(disabledCopy)) {
      setDisabledCopy(null);
    }
  }, [permissionScope, permissionDisabled]);

  return {
    permissionDisabled,
    disabledCopy,
  };
}
