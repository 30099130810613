import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import { ALL_APPS } from 'constants/appConstants';
import _ from 'lodash';
import { PurchaseRule } from 'types/inventory';
import { useGetPurchaseRulesQuery } from 'store/slices/inventory';
import CreateEditPurchaseRuleDialog from 'views/suppliers/CreateEditPurchaseRuleDialog';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type HookFormPurchaseRuleAutocompleteProps = {
  children?: ReactNode;
  defaultValue?: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  sx?: SxProps<Theme>;
  helperText?: string;
  mt?: number;
  fullWidth?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  [props: string]: unknown;
} & HookFormComponentProps;

export const HookFormPurchaseRuleAutocomplete = ({
  errors,
  control,
  name = '',
  label,
  defaultValue,
  disabled,
  multiple = false,
  sx,
  mt = 2,
  helperText,
  fullWidth = true,
  setValue,
  ...rest
}: HookFormPurchaseRuleAutocompleteProps) => {
  const [newPurchaseRuleDialogOpen, setNewPurchaseRuleDialogOpen] =
    useState(false);
  const [selected, setSelected] = useState<PurchaseRule | null>(null);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] =
    useState<PurchaseRule | null>(null);
  const { data: purchaseRules = [], isLoading: isLoadingPurchaseRules } =
    useGetPurchaseRulesQuery({});

  const options: PurchaseRule[] = useMemo(() => {
    const combinedOptions = [
      ...purchaseRules,
      ...(contextuallyCreatedOption ? [contextuallyCreatedOption] : []),
    ].filter((option) => option !== null && option !== undefined);

    return Array.from(new Set(combinedOptions.map((option) => option.id)))
      .map((id) => combinedOptions.find((option) => option.id === id))
      .filter((option): option is PurchaseRule => option !== undefined);
  }, [purchaseRules, contextuallyCreatedOption]);

  useEffect(() => {
    if (defaultValue) {
      const defaultPurchaseRule = _.find(
        purchaseRules,
        (pr) => pr.id === defaultValue
      );
      setSelected(defaultPurchaseRule || null);
    }
  }, [defaultValue, purchaseRules]);

  const AutoCompleteInput = (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur } }) => (
        <Autocomplete
          loading={isLoadingPurchaseRules}
          fullWidth={fullWidth}
          sx={{ mt, ...sx }}
          options={options}
          onChange={(e, data) => {
            if (data?.id !== selected?.id) {
              setSelected(data);
            }
            onChange(data?.id);
          }}
          value={selected}
          onBlur={onBlur}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <Box>
              <TextField {...params} label={label} fullWidth />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </Box>
          )}
          disabled={disabled}
        />
      )}
    />
  );

  return (
    <>
      {disabled ? (
        AutoCompleteInput
      ) : (
        <>
          <ContextualCreateButton
            onClick={() => setNewPurchaseRuleDialogOpen(true)}
            permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
          >
            {AutoCompleteInput}
          </ContextualCreateButton>
          <CreateEditPurchaseRuleDialog
            dialogOpen={newPurchaseRuleDialogOpen}
            onClose={() => setNewPurchaseRuleDialogOpen(false)}
            onCreateSuccess={(value) => {
              setContextuallyCreatedOption(value);
              setSelected(value);
              setValue && setValue(name, value.id);
            }}
          />
        </>
      )}
    </>
  );
};
