import { apiSlice } from 'store/slices/api';
import {
  AppGrantInfo,
  CreateGrantPayload,
  Entitlement,
  GetOrgIssuedGrantsQueryParams,
  Grant,
  MetronomePlan,
  MetronomeUsage,
  OverwriteGrantPayload,
  UpdateGrantPayload,
  UserAccessControls,
} from 'types/access';
import { URLS } from 'store/constant';
import { Org } from 'types/org';
import { User } from 'types/user';

export const accessEntitlementsExtendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAccessControls: builder.query<UserAccessControls, void>({
      query: () => URLS.USER_ACCESS,
      providesTags: ['UserAccessControl'],
    }),
    getApps: builder.query<AppGrantInfo[], void>({
      query: () => URLS.APPS,
      providesTags: ['Apps'],
    }),
    getGrant: builder.query<Grant, { grantId: Grant['id'] }>({
      query: ({ grantId }) => URLS.GRANTS(grantId),
      providesTags: (result) =>
        result
          ? [
              { type: 'Grant', id: result.id },
              { type: 'Grant', id: 'LIST' },
            ]
          : [{ type: 'Grant', id: 'LIST' }],
    }),
    createGrant: builder.mutation<Grant, { payload: CreateGrantPayload }>({
      query: ({ payload }) => ({
        url: URLS.GRANTS(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Grant', id: result.id },
              { type: 'Grant', id: 'LIST' },
              { type: 'UserGrants', id: 'LIST' },
            ]
          : [{ type: 'Grant', id: 'LIST' }],
    }),
    updateGrant: builder.mutation<
      Grant,
      { grantId: Grant['id']; payload: UpdateGrantPayload }
    >({
      query: ({ grantId, payload }) => ({
        url: URLS.GRANTS(grantId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Grant', id: result.id },
              { type: 'Grant', id: 'LIST' },
              { type: 'UserGrants', id: 'LIST' },
            ]
          : [
              { type: 'Grant', id: 'LIST' },
              { type: 'UserGrants', id: 'LIST' },
            ],
    }),
    deleteGrant: builder.mutation<void, Grant['id']>({
      query: (grantId) => ({
        url: URLS.GRANTS(grantId),
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Grant', id: 'LIST' },
        { type: 'OrgIssuedGrants', id: 'LIST' },
        { type: 'UserGrants', id: 'LIST' },
      ],
    }),
    getAllOrgIssuedGrants: builder.query<
      Grant[],
      GetOrgIssuedGrantsQueryParams
    >({
      query: (queryParams) => URLS.GRANTS(undefined, queryParams),
      providesTags: [{ type: 'OrgIssuedGrants', id: 'LIST' }],
    }),
    getAllOrgGrants: builder.query<Grant[], { orgId: Org['id'] }>({
      query: ({ orgId }) => URLS.ORG_GRANTS(orgId),
      providesTags: [{ type: 'OrgGrants', id: 'LIST' }],
    }),
    overwriteAllOrgGrants: builder.mutation<
      Grant[],
      { orgId: Org['id']; payload: OverwriteGrantPayload[] }
    >({
      query: ({ orgId, payload }) => ({
        url: URLS.ORG_GRANTS(orgId),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'OrgGrants', id: 'LIST' },
        { type: 'Grant', id: 'LIST' },
      ],
    }),
    getAllUserGrants: builder.query<Grant[], { userId: User['id'] }>({
      query: ({ userId }) => URLS.USER_GRANTS(userId),
      providesTags: [{ type: 'UserGrants', id: 'LIST' }],
    }),
    // overwrite is scoped to the requesting org; all other grants will be untouched.
    overwriteAllUserGrants: builder.mutation<
      Grant[],
      { userId: User['id']; payload: OverwriteGrantPayload[] }
    >({
      query: ({ userId, payload }) => ({
        url: URLS.USER_GRANTS(userId),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'OrgIssuedGrants', id: 'LIST' },
        { type: 'User', id: 'LIST' },
        { type: 'UserGrants', id: 'LIST' },
      ],
    }),
    getEntitlements: builder.query<Entitlement[], void>({
      query: () => URLS.METRONOME_ENTITLEMENTS,
      providesTags: [{ type: 'Entitlements', id: 'LIST' }],
    }),
    getMetronomeUsage: builder.query<
      MetronomeUsage[],
      {
        fromDate: string;
        toDate: string;
      }
    >({
      query: ({ fromDate, toDate }) => URLS.METRONOME_USAGE(fromDate, toDate),
    }),
    getActivePlan: builder.query<MetronomePlan, void>({
      query: () => URLS.METRONOME_PLAN,
    }),
  }),
});
export const {
  useGetUserAccessControlsQuery,
  useGetAllOrgIssuedGrantsQuery,
  useOverwriteAllUserGrantsMutation,
  useGetAllUserGrantsQuery,
  useGetAppsQuery,
  useLazyGetEntitlementsQuery,
  useGetMetronomeUsageQuery,
  useGetActivePlanQuery,
  useLazyGetActivePlanQuery,
} = accessEntitlementsExtendedApiSlice;
