export const API_URL = process.env.API_URL || '';
export const ENV = process.env.APP_ENV || '';
export const HOST_URL = process.env.HOST_URL || '';
export const IS_PROD = process.env.APP_ENV === 'production';
export const IS_QA = process.env.APP_ENV === 'qa';
export const IS_ETE = process.env.APP_ENV === 'ete';
export const IS_REMOTE = IS_PROD || IS_QA || IS_ETE;
export const IS_LOCAL = !IS_REMOTE;
export const IS_KNOCK_PROD = process.env.KNOCK_ENV === 'production';
export const PRISMATIC_URL = process.env.PRISMATIC_URL || '';
