import { memo } from 'react';
import useMenuItems from 'menu-items/useMenuItems';
// material-ui

// project imports
import NavGroup from 'layout/MainLayout/Sidebar/MenuList/NavGroup';
import NavItem from 'layout/MainLayout/Sidebar/MenuList/NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const menuItems = useMenuItems();
  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return item?.children && item.children.length > 0 ? (
          <NavGroup key={item.id} item={item} />
        ) : null;
      default:
        return <NavItem key={item.id} item={item} level={1} />;
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
