export type MobileInventoryDeviceSettings = {
  printer3020: string | null;
  printer4020: string | null;
  printer6040: string | null;
  printer8511: string | null;
  wedgeScan: boolean;
  cameraScan: boolean;
  pickingLocation: string | null;
  facility: string | null;
  sandboxMode: boolean;
  cameraName: string | null;
  autoRefreshDashboard: boolean;
  showShipDashboard: boolean;
  showReceiveDashboard: boolean;
  showHandlingDashboard: boolean;
  showCounter: boolean;
  counterSerialPort: SerialPort | null;
};

export const mobileInventoryStorageName = 'cofactr-mobile-inventory-settings';

export const mobileInventoryDefaults = {
  printer3020: null,
  printer4020: null,
  printer6040: null,
  printer8511: null,
  wedgeScan: true,
  cameraScan: false,
  pickingLocation: null,
  facility: null,
  sandboxMode: false,
  cameraName: '',
  autoRefreshDashboard: false,
  showShipDashboard: true,
  showReceiveDashboard: true,
  showHandlingDashboard: true,
  showCounter: false,
  counterSerialPort: null,
} as MobileInventoryDeviceSettings;
