// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { CardContent, Grid, Typography } from '@mui/material';
import { IconServerOff } from '@tabler/icons-react';
import { gridSpacing } from 'constants/themeConstants';

const ErrorWrapper = styled('div')({
  maxWidth: 350,
  margin: '0 auto',
  textAlign: 'center',
});

// ==============================|| ERROR PAGE ||============================== //

const InternalError = () => {
  const theme = useTheme();

  return (
    <CardContent sx={{ mt: 8 }}>
      <ErrorWrapper>
        <IconServerOff size={200} color={theme.palette.primary.main} />
      </ErrorWrapper>
      <Grid container justifyContent="center" spacing={gridSpacing}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <ErrorWrapper>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Typography variant="h1" component="div">
                  Yikes...
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  We&apos;re experiencing an outage, and are unable to complete
                  your request. Our team has been alerted of this error, and
                  we&apos;re working on a solution.
                </Typography>
              </Grid>
            </Grid>
          </ErrorWrapper>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default InternalError;
