import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useGetVersionQuery,
  updateVersion,
  initialStateUtility,
} from 'store/slices/utility';
import { useDispatch, useSelector } from 'store';
import { IS_LOCAL } from 'constants/envConstants';
import { clearDatagridState } from 'store/slices/datagrid';

const NewVersionContext = createContext({
  showNewVersion: false,
  handleUpdateVersion: () => {},
});

export const useNewVersion = () => useContext(NewVersionContext);

export const NewVersionProvider = ({ children }: { children: ReactNode }) => {
  const { version: currentVersion } = useSelector((state) => state.utility);
  const dispatch = useDispatch();
  const [showNewVersion, setShowNewVersion] = useState(false);
  const { version, refetch } = useGetVersionQuery(undefined, {
    pollingInterval: 1000 * 60 * 10,
    selectFromResult: ({ data, error }) => {
      if (error || !data?.version) return { version: currentVersion };
      return { version: data.version, error };
    },
    skip: IS_LOCAL,
  });

  const handleUpdateVersion = () => {
    dispatch(clearDatagridState());
    dispatch(updateVersion(version));
    window.location.reload();
  };

  useEffect(() => {
    if (!IS_LOCAL) {
      refetch();
    }
    if (
      currentVersion !== initialStateUtility.version &&
      version !== currentVersion
    ) {
      setShowNewVersion(true);
    }
    if (
      version !== currentVersion &&
      currentVersion === initialStateUtility.version
    ) {
      dispatch(updateVersion(version));
    }
  }, [version, currentVersion, refetch]);

  return (
    <NewVersionContext.Provider value={{ showNewVersion, handleUpdateVersion }}>
      {children}
    </NewVersionContext.Provider>
  );
};
