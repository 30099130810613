import SegmentButton from 'ui-component/Segment/SegmentButton';
import { Alert, AlertTitle, Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MOBILE_INVENTORY_ROUTES } from 'routes/constants';
import { ALL_APPS } from 'constants/appConstants';

const ScreenSizeAlert: FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        '@media (min-width: 800px)': {
          display: 'none',
        },
      }}
    >
      <Backdrop
        sx={{
          color: (theme) => theme.palette.common.white,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open
      >
        <Alert
          severity="info"
          variant="filled"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            position: 'absolute',
            top: 0,
          }}
          action={
            <SegmentButton
              color="inherit"
              size="small"
              onClick={() => navigate(MOBILE_INVENTORY_ROUTES.DASHBOARD)}
              permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
            >
              Use Mobile Inventory
            </SegmentButton>
          }
        >
          <AlertTitle>Screen Size</AlertTitle>
          This application is designed for a window size of at least 800px wide.
          Please increase your browser window width
        </Alert>
      </Backdrop>
    </Box>
  );
};

export default ScreenSizeAlert;
