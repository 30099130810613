// Definitive location for managing session related actions and state,
// such as logging in and out, tokens, storedLocation, etc

import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from 'store/slices/api';

import { DefaultRootStateProps } from 'types';
import { URLS } from 'store/constant';

import { LoginRequest, LoginResponse, RefreshRequest } from 'types/session';

import {
  login,
  logout,
  softLogout,
  setToken,
  setRefreshToken,
  setStoredLocation,
  setStoredOrg,
  finishSoftLogout,
} from 'store/actions';

import { analyticsTrack, handleErr } from 'utils/functions';
import { OrgSignupRequest } from 'types/org';

const initialState: DefaultRootStateProps['session'] = {
  isLoggedIn: false,
  authToken: null,
  refreshToken: null,
  storedLocation: '',
  storedOrg: '',
  isSoftLoggingOut: false,
};
export const sessionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (creds) => ({ url: URLS.TOKEN, method: 'POST', body: creds }),
      async onQueryStarted(creds, { queryFulfilled }) {
        await queryFulfilled;
        analyticsTrack('Signed In', { username: creds.email });
      },
      async onCacheEntryAdded(
        _arg,
        { dispatch, cacheDataLoaded, getCacheEntry, cacheEntryRemoved }
      ) {
        try {
          await cacheDataLoaded;
          if (getCacheEntry().data) {
            const { data } = getCacheEntry();
            if (data) {
              dispatch(login(data));
            }
          }
        } catch (e: any) {
          handleErr(e, () => {});
        }

        await cacheEntryRemoved;
      },
    }),
    register: builder.mutation<LoginResponse, OrgSignupRequest>({
      query: (payload) => ({ url: URLS.SIGNUP, method: 'POST', body: payload }),
      async onQueryStarted(creds, { queryFulfilled }) {
        await queryFulfilled;
        analyticsTrack('Account Created', { username: creds.email });
      },
      async onCacheEntryAdded(
        _arg,
        { dispatch, cacheDataLoaded, getCacheEntry, cacheEntryRemoved }
      ) {
        try {
          await cacheDataLoaded;
          const { data } = getCacheEntry();
          if (data) {
            dispatch(login(data));
          }
        } catch (e: any) {
          handleErr(e, () => {});
        }

        await cacheEntryRemoved;
      },
    }),
    doRefreshToken: builder.mutation<LoginResponse, RefreshRequest>({
      query: (creds) => ({
        url: URLS.REFRESH_TOKEN,
        method: 'POST',
        body: creds,
      }),
    }),
  }),
});
export const { useLoginMutation, useRegisterMutation } = sessionApiSlice;

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login, (state, { payload }) => {
        state.authToken = payload.access;
        state.refreshToken = payload.refresh;
        state.isLoggedIn = true;
      })
      .addCase(logout, (state) => {
        state.authToken = null;
        state.refreshToken = null;
        state.storedLocation = '';
        state.storedOrg = '';
        state.isLoggedIn = false;
        if (window.analytics) {
          analytics.reset();
        }
      })
      .addCase(softLogout, (state) => {
        state.authToken = null;
        state.refreshToken = null;
        state.storedLocation = '';
        state.isSoftLoggingOut = true;
        if (window.analytics) {
          analytics.reset();
        }
      })
      .addCase(finishSoftLogout, (state) => {
        state.isSoftLoggingOut = false;
        state.isLoggedIn = false;
      })
      .addCase(setToken, (state, { payload }) => {
        state.authToken = payload;
      })
      .addCase(setRefreshToken, (state, action) => {
        state.refreshToken = action.payload;
      })
      .addCase(setStoredLocation, (state, action) => {
        state.storedLocation = action.payload;
      })
      .addCase(setStoredOrg, (state, action) => {
        state.storedOrg = action.payload;
      });
  },
});

export default slice.reducer;
