import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  DialogContent,
  DialogContentText,
  Alert,
} from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconX } from '@tabler/icons-react';
import { PermissionScope } from 'types/apps';
import { ButtonTypeMap } from '@mui/material/Button/Button';

interface ConfirmDialogProps {
  title: string;
  dialogText: string;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  dialogOpen: boolean;
  confirmButtonText?: string;
  confirmButtonColor?: ButtonTypeMap['props']['color'];
  error?: string | null;
  permissionScope?: PermissionScope;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  dialogText,
  onClose,
  onConfirm,
  loading = false,
  dialogOpen,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  error,
  permissionScope,
}) => (
  <Dialog onClose={onClose} open={dialogOpen} fullWidth maxWidth="sm">
    <DialogTitle>
      <Grid container>
        <Grid item xs>
          {title}
        </Grid>
        <Grid item xs="auto">
          <SegmentIconButton onClick={onClose} aria-label="Close Dialog">
            <IconX />
          </SegmentIconButton>
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent sx={{ mt: 2 }}>
      <DialogContentText>{dialogText}</DialogContentText>
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
    </DialogContent>
    <DialogActions sx={{ px: 3 }}>
      <SegmentButton
        onClick={onClose}
        size="medium"
        color="primary"
        sx={{ mr: 1 }}
      >
        Cancel
      </SegmentButton>
      <SegmentLoadingButton
        loading={loading}
        size="medium"
        color={confirmButtonColor}
        variant="contained"
        onClick={onConfirm}
        permissionScope={permissionScope}
      >
        {confirmButtonText}
      </SegmentLoadingButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
