import { useDispatch } from 'react-redux';
import { AlertProps } from '@mui/material/Alert/Alert';
import { openSnackbar } from 'store/slices/snackbar';
import { SnackbarProps } from 'types/snackbar';

export default function useSnackbar() {
  const dispatch = useDispatch();

  const dispatchSnackbar = ({
    message,
    color,
    navigateTo,
    customAction,
  }: {
    message: string;
    color: AlertProps['color'];
    navigateTo?: SnackbarProps['navigateTo'];
    customAction?: SnackbarProps['customAction'];
  }) => {
    dispatch(
      openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
          color,
        },
        close: true,
        navigateTo: navigateTo ?? undefined,
        customAction: customAction ?? undefined,
      })
    );
  };

  return {
    dispatchSuccessSnackbar: (
      message: string,
      navigateTo?: SnackbarProps['navigateTo'],
      customAction?: SnackbarProps['customAction']
    ) =>
      dispatchSnackbar({ message, color: 'success', navigateTo, customAction }),
    dispatchErrorSnackbar: (message: string) =>
      dispatchSnackbar({ message, color: 'error' }),
  };
}
