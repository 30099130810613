import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { setStoredLocation, login } from 'store/actions';

import { GuardProps } from 'types';
import { useEffect } from 'react';
import { getCookie } from 'utils/functions';
import { DASHBOARD_PATH } from 'config';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const LoginGuard = ({ children }: GuardProps) => {
  const { storedLocation } = useSelector((state) => state.session);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(({ session }) => session);
  const cookieAccessToken = getCookie('flagship_access');

  // Run only on app load, if attempting a route in routes/LoginRoutes
  // eslint-disable-next-line
  useEffect(() => {
    // If the user is logged in, and tries to navigate to an auth route, don't let them
    if (isLoggedIn) {
      if (storedLocation) {
        navigate(storedLocation, { replace: true });
        dispatch(setStoredLocation(''));
      } else {
        navigate(DASHBOARD_PATH, { replace: true });
      }
    }
    // This should only happen if the user was sent here from the backend
    // with an access cookie, like from a magic link
    if (cookieAccessToken) {
      dispatch(
        login({
          access: cookieAccessToken,
          refresh: getCookie('flagship_refresh') || '',
        })
      );
    }
  }, []);

  return children;
};

export default LoginGuard;
