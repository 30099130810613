import _ from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { finishSoftLogout, login, setStoredLocation } from 'store/actions';
import InternalError from 'views/pages/InternalError';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { setViewAsStaff, useGetUserQuery } from 'store/slices/user';
import { FeatureFlags, GuardProps } from 'types';
import { useEffect } from 'react';
import { getCookie } from 'utils/functions';
import Loader from 'ui-component/Loader';
import * as Sentry from '@sentry/react';
import { AUTH_ROUTES } from 'routes/constants';
import { isCofactrUser } from 'utils/userPermissions';
import Tracker from '@openreplay/tracker';
import { IS_PROD, IS_QA } from 'constants/envConstants';
import { useFeature } from '@growthbook/growthbook-react';
import { useLazyGetActivePlanQuery } from 'store/slices/accessEntitlements';
import { setShowNoPlanBanner } from 'store/slices/utility';

export const openReplayTracker = new Tracker({
  projectKey: process.env.OPEN_REPLAY_PROJECT_KEY || '',
  ingestPoint: 'https://openreplay.cofactr.com/ingest',
  network: {
    capturePayload: true, // Capture HTTP payload
    sessionTokenHeader: false,
    failuresOnly: false,
    ignoreHeaders: [],
    captureInIframes: false,
  },
});

function isErrorWithStatus(
  error: FetchBaseQueryError | SerializedError
): error is FetchBaseQueryError {
  return (error as FetchBaseQueryError).status !== undefined;
}

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { authToken, isSoftLoggingOut, storedLocation } = useSelector(
    (state) => state.session
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { activeOrgId } = useSelector(({ org }) => org);
  const { viewAsStaff } = useSelector(({ user }) => user);
  const cookieAccessToken = getCookie('flagship_access');
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');
  const openReplayFeature = useFeature(FeatureFlags.tempOpenReplay).on;
  const sentryReplayFeature = useFeature(FeatureFlags.tempSentryReplay).on;

  const {
    data: user,
    isSuccess: isGetUserSuccess,
    isFetching: isUserFetching,
    isError: isUserError,
    error: userError,
  } = useGetUserQuery(authToken ? undefined : skipToken);
  const isCofactr = isCofactrUser({ user, viewAsStaff });

  const [getActivePlan, { data: activePlan }] = useLazyGetActivePlanQuery();

  const handleFetchActivePlan = async () => {
    getActivePlan();
  };

  // Run only on app load, if attempting a route in routes/AppRoutes
  // This could happen if user is not authenticated and tries to navigate to
  // an authenticated-only route, like /parts/search
  // eslint-disable-next-line
  useEffect(() => {
    // Don't store set password route or you'll get an annoying password reset loop
    if (
      (!storedLocation || storedLocation === AUTH_ROUTES.LOGIN) &&
      location.pathname !== AUTH_ROUTES.RESET
    ) {
      dispatch(setStoredLocation(`${location.pathname}${location.search}`));
    }
    // This should only happen if the user was sent here from the backend
    // with an access cookie, like from a magic link
    if (cookieAccessToken) {
      dispatch(
        login({
          access: cookieAccessToken,
          refresh: getCookie('flagship_refresh') || '',
        })
      );
    }
  }, []);

  // Run only on app load, and if softLogout occurs or concludes,
  // if attempting a route in routes/AppRoutes
  useEffect(() => {
    if (isSoftLoggingOut) {
      dispatch(setStoredLocation(`${location.pathname}${location.search}`));
      dispatch(finishSoftLogout());
    }
  }, [isSoftLoggingOut]);

  // Run only on app load, and if authToken is set, or if authToken changes,
  // or if authToken is unset,
  // if attempting a route in routes/AppRoutes
  useEffect(() => {
    if (!authToken && !cookieAccessToken) {
      if (referrer) {
        navigate(`login?referrer=${referrer}`, { replace: true });
      } else {
        navigate('login', { replace: true });
      }
    }
  }, [authToken]);

  // Start OpenReplay tracker and add metadata
  useEffect(() => {
    // Add user identification information to tracker
    if (openReplayFeature && user && (IS_PROD || (IS_QA && isCofactr))) {
      openReplayTracker.start();
      openReplayTracker.setUserID(user.email);
      openReplayTracker.setMetadata(
        'name',
        `${user.firstName} ${user.lastName}`
      );
      openReplayTracker.setMetadata('userOrgId', user.org);
      const orgName = _.find(user.orgs, ['id', user.org])?.name;
      if (orgName) {
        openReplayTracker.setMetadata('userOrgName', orgName);
      }
      openReplayTracker.setMetadata('env', process.env.NODE_ENV);
    }
  }, [user, openReplayFeature]);

  // Start Sentry Replay tracker and add metadata
  useEffect(() => {
    // Add user identification information to tracker
    if (sentryReplayFeature && user && (IS_PROD || (IS_QA && isCofactr))) {
      Sentry.addIntegration(Sentry.replayIntegration());
      Sentry.setUser({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        id: user.email,
      });
    }
  }, [user, openReplayFeature]);

  // Run only on app load, and if activeOrgId changes, and if
  // getUser is invoked anywhere and succeeds or fails,
  // if attempting a route in routes/AppRoutes
  useEffect(() => {
    // Identify Segment as the app is loading
    // Also, reidentify Segment on activeOrgId change
    if (isGetUserSuccess && user) {
      if (window.analytics) {
        analytics.identify(user.id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          orgId: user.org,
        });
        analytics.group(user.org, _.find(user.orgs, ['id', activeOrgId]));
      }
    }
  }, [activeOrgId, isGetUserSuccess]);

  // if user has needsPasswordReset flag, navigate them to reset view
  // and include referrer to render contextual message to user
  useEffect(() => {
    if (isGetUserSuccess && user.needsPasswordReset) {
      navigate(`${AUTH_ROUTES.RESET}/?reason=requires_reset`, {
        replace: true,
      });
    }
  }, [isGetUserSuccess, user]);

  // if user is not a Cofactr user, set viewAsStaff to false
  useEffect(() => {
    // If user is not a Cofactr staff member, set viewAsUser to false
    if (user && !(_.find(user?.orgs, ['id', user?.org])?.name === 'Cofactr')) {
      dispatch(setViewAsStaff(false));
      // If user is a Cofactr staff member who hasn't set viewAsUser (aka its null), default viewAsUser to true
    } else if (user && viewAsStaff === null) {
      dispatch(setViewAsStaff(true));
    }
  }, [user, viewAsStaff]);

  // fetch org's Cofactr plan when activeOrgId changes
  useEffect(() => {
    handleFetchActivePlan();
  }, [activeOrgId]);

  // If the org's active Cofactr plan is 'No Plan' and the org is not a sub-org, render the No Plan banner
  useEffect(() => {
    if (
      activePlan &&
      activePlan?.name === 'No Plan' &&
      _.find(user?.orgs, (org) => org.id === activeOrgId)?.parentOrgName ===
        null
    ) {
      dispatch(setShowNoPlanBanner(true));
    } else {
      dispatch(setShowNoPlanBanner(false));
    }
  }, [activeOrgId, activePlan?.id]);

  if (!isUserError && isUserFetching) {
    return <Loader />;
  }

  if (isUserError) {
    if (isErrorWithStatus(userError) && Number(userError.status) >= 500) {
      Sentry.captureException(
        new Error(`Error: ${JSON.stringify(userError)}`),
        {
          level: 'error',
        }
      );
      return <InternalError />;
    }
  }

  return children;
};

export default AuthGuard;
