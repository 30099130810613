import { lazy } from 'react';

// project imports
import MediumLayout from 'layout/MediumLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import LoginGuard from 'utils/route-guard/LoginGuard';
import { AUTH_ROUTES } from 'routes/constants';

// login routing
const AuthLogin = Loadable(
  lazy(() => import('views/pages/authentication/Login'))
);
const AuthRegister = Loadable(
  lazy(() => import('views/pages/authentication/Register'))
);
const AuthForgotPassword = Loadable(
  lazy(() => import('views/pages/authentication/ForgotPassword'))
);
const AuthFail = Loadable(
  lazy(() => import('views/pages/authentication/AuthFail'))
);

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <LoginGuard>
        <MediumLayout />
      </LoginGuard>
    </NavMotion>
  ),
  children: [
    {
      path: AUTH_ROUTES.LOGIN,
      element: <AuthLogin />,
    },
    {
      path: AUTH_ROUTES.REGISTER,
      element: <AuthRegister />,
    },
    {
      path: AUTH_ROUTES.FORGOT,
      element: <AuthForgotPassword />,
    },
    {
      path: AUTH_ROUTES.AUTH_FAIL,
      element: <AuthFail />,
    },
  ],
};

export default LoginRoutes;
