import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  FormHelperText,
  SxProps,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';

export type HookFormDatePickerProps = {
  disabled?: boolean;
  boxSx?: SxProps<Theme>;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
} & TextFieldProps &
  HookFormComponentProps;

export const HookFormDatePicker: FC<HookFormDatePickerProps> = ({
  errors,
  name = '',
  control,
  label,
  disabled = false,
  sx,
  boxSx,
  disablePast = false,
  minDate,
  maxDate,
  clearable,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value, onBlur } }) => (
      <Box sx={{ my: 2, ...boxSx }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled={disabled}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
            label={label}
            name={name}
            slotProps={{
              field: { clearable, onBlur },
            }}
            sx={{ width: '100%', ...sx }}
            value={value}
            onChange={onChange}
          />
        </LocalizationProvider>
        {errors?.[name]?.message && (
          <FormHelperText error id={`${name}Error`}>
            {errors?.[name]?.message}
          </FormHelperText>
        )}
      </Box>
    )}
  />
);
