import { lazy } from 'react';

// project imports
import MobileInventoryLayout from 'layout/MobileInventoryLayout';
import Loadable from 'ui-component/Loadable';
import { MOBILE_INVENTORY_ROUTES } from 'routes/constants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import { ALL_APPS } from 'constants/appConstants';
import AuthGuard from 'utils/route-guard/AuthGuard';
import OrgGuard from 'utils/route-guard/OrgGuard';

const MobileInventoryDashboard = Loadable(
  lazy(() => import('views/mobileInventory/dashboard'))
);
const MobileInventoryTransfer = Loadable(
  lazy(() => import('views/mobileInventory/transfer'))
);
const MobileInventoryCounter = Loadable(
  lazy(() => import('views/mobileInventory/counter'))
);
const MobileInventorySettings = Loadable(
  lazy(() => import('views/mobileInventory/settings'))
);
const MobileInventoryPicking = Loadable(
  lazy(() => import('views/mobileInventory/PickingV2'))
);
const MobileInventoryHandling = Loadable(
  lazy(() => import('views/mobileInventory/HandlingV2'))
);
const MobileInventoryReceive = Loadable(
  lazy(() => import('views/mobileInventory/TempReceiveToggleRoot'))
);
const MobileInventoryShip = Loadable(
  lazy(() => import('views/mobileInventory/ship'))
);
const ReturnToStock = Loadable(
  lazy(() => import('views/mobileInventory/returnToStock'))
);
const StandardLayout = Loadable(
  lazy(() => import('views/mobileInventory/StandardLayout'))
);
const InventoryStockLot = Loadable(
  lazy(() => import('views/mobileInventory/stockLot'))
);
const InventoryStockLocation = Loadable(
  lazy(() => import('views/mobileInventory/stockLocation'))
);

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const UserAccountRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <OrgGuard>
        <MobileInventoryLayout />
      </OrgGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: MOBILE_INVENTORY_ROUTES.DASHBOARD,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryDashboard />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.TRANSFER,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryTransfer />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.COUNTER,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryCounter />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.SETTINGS,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventorySettings />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.PICKING,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryPicking />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.HANDLING,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryHandling />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.RECEIVE,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryReceive />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.SHIP,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <MobileInventoryShip />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.RETURN,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <ReturnToStock />
        </PermissionsRouteWrapper>
      ),
    },
    {
      path: MOBILE_INVENTORY_ROUTES.STOCK_LOTS,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <StandardLayout />
        </PermissionsRouteWrapper>
      ),
      children: [
        {
          path: `${MOBILE_INVENTORY_ROUTES.STOCK_LOTS}/:stockLotId`,
          element: (
            <PermissionsRouteWrapper
              permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
            >
              <InventoryStockLot />
            </PermissionsRouteWrapper>
          ),
        },
      ],
    },
    {
      path: MOBILE_INVENTORY_ROUTES.STOCK_LOCATIONS,
      element: (
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
        >
          <StandardLayout />
        </PermissionsRouteWrapper>
      ),
      children: [
        {
          path: `${MOBILE_INVENTORY_ROUTES.STOCK_LOCATIONS}/:stockLocationId`,
          element: (
            <PermissionsRouteWrapper
              permissionScope={{ app: ALL_APPS.WAREHOUSE.id }}
            >
              <InventoryStockLocation />
            </PermissionsRouteWrapper>
          ),
        },
      ],
    },
  ],
};

export default UserAccountRoutes;
