import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiSlice } from 'store/slices/api';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  Avatar,
  Tooltip,
} from '@mui/material';
import { ALL_APPS } from 'constants/appConstants';

import PerfectScrollbar from 'react-perfect-scrollbar';

import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

import {
  IconCash,
  IconLogout,
  IconPlug,
  IconSettings,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';
import { useGetUserQuery, useSignoutUserMutation } from 'store/slices/user';
import { logout } from 'store/actions';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useDispatch, useSelector } from 'store';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { handleErr } from 'utils/functions';
import { activeItem } from 'store/slices/menu';

const ProfileSection = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasAppPermission } = useAppAccessContext();

  const { isLoggedIn } = useSelector((state) => state.session);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const { data: user } = useGetUserQuery(isLoggedIn ? undefined : skipToken);

  const [signoutUser] = useSignoutUserMutation();

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    try {
      await signoutUser().unwrap();
    } catch (err) {
      handleErr(err, (__: string) => {});
    } finally {
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    }
  };
  const handleListItemClick = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement>,
      index: number,
      route: string = '',
      id: string = ''
    ) => {
      setSelectedIndex(index);
      handleClose(event);

      if (route && route !== '') {
        navigate(route);
        dispatch(activeItem([id]));
      }
    },
    [navigate]
  );
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (!isLoggedIn) return null;

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor:
            theme.palette.mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Tooltip title="Settings">
            <Avatar
              src={user?.image || ''}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: '8px 0 8px 8px !important',
                cursor: 'pointer',
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              color={theme.palette.primary.main}
            >
              {user?.firstName[0]}
              {user?.lastName[0]}
            </Avatar>
          </Tooltip>
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Hi,</Typography>
                          <Typography component="span" variant="h4">
                            {user?.firstName}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden',
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        <List
                          component="nav"
                          sx={{
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%',
                            },
                            '& .MuiListItemButton-root': {
                              mt: 0.5,
                            },
                          }}
                        >
                          {hasAppPermission(ALL_APPS.USER_SETTINGS.id) && (
                            <ListItemButton
                              sx={{
                                borderRadius: `${borderRadius}px`,
                              }}
                              selected={selectedIndex === 0}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  0,
                                  `${ALL_APPS.USER_SETTINGS.path}`,
                                  `${ALL_APPS.USER_SETTINGS.id}`
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconUser />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Your Settings
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                          {hasAppPermission(ALL_APPS.ORG_SETTINGS.id) && (
                            <ListItemButton
                              sx={{
                                borderRadius: `${borderRadius}px`,
                              }}
                              selected={selectedIndex === 1}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  1,
                                  `${ALL_APPS.ORG_SETTINGS.path}`,
                                  `${ALL_APPS.ORG_SETTINGS.id}`
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconSettings />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Organization Settings
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                          {hasAppPermission(ALL_APPS.USERS.id) && (
                            <ListItemButton
                              sx={{
                                borderRadius: `${borderRadius}px`,
                              }}
                              selected={selectedIndex === 2}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  2,
                                  `${ALL_APPS.USERS.path}`,
                                  `${ALL_APPS.USERS.id}`
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconUsers />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Manage Users
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                          {hasAppPermission(ALL_APPS.BILLING.id) && (
                            <ListItemButton
                              sx={{
                                borderRadius: `${borderRadius}px`,
                              }}
                              selected={selectedIndex === 3}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  3,
                                  `${ALL_APPS.BILLING.path}`,
                                  `${ALL_APPS.BILLING.id}`
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconCash />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Billing
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                          {hasAppPermission(ALL_APPS.INTEGRATIONS.id) && (
                            <ListItemButton
                              sx={{
                                borderRadius: `${borderRadius}px`,
                              }}
                              selected={selectedIndex === 3}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>
                              ) =>
                                handleListItemClick(
                                  event,
                                  3,
                                  `${ALL_APPS.INTEGRATIONS.path}`,
                                  `${ALL_APPS.INTEGRATIONS.id}`
                                )
                              }
                            >
                              <ListItemIcon>
                                <IconPlug />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    Integrations
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          )}
                          <ListItemButton
                            sx={{
                              borderRadius: `${borderRadius}px`,
                            }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">Logout</Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default memo(ProfileSection);
