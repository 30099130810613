import SegmentButton from 'ui-component/Segment/SegmentButton';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { PermissionScope } from 'types/apps';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

const ContextualCreateButton = ({
  onClick,
  buttonMarginTop,
  children,
  boxAlignItems,
  disabled = false,
  permissionScope,
  sx,
}: {
  onClick: () => void;
  buttonMarginTop?: number;
  children?: ReactNode;
  boxAlignItems?: string;
  disabled?: boolean;
  permissionScope?: PermissionScope;
  sx?: SxProps<Theme>;
}) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      alignItems: boxAlignItems || 'center',
    }}
  >
    {children}
    <SegmentButton
      variant="outlined"
      startIcon={<IconPlus />}
      onClick={onClick}
      size="large"
      sx={
        sx ?? {
          '&.MuiButton-outlined': {
            width: '46px',
            height: '46px',
            minWidth: '46px',
            marginLeft: '10px',
          },
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          mt: buttonMarginTop || 0,
        }
      }
      disabled={disabled}
      permissionScope={permissionScope}
    />
  </Box>
);
export default ContextualCreateButton;
