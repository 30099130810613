// material-ui
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// assets
import defaultColor from 'assets/scss/_themes-vars.module.scss';

// types
import { ColorProps } from 'types';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType: PaletteMode, presetColor: string) => {
  let colors: ColorProps;

  // TODO: Create function to replace values in paletteVals as per needs of a new theme. This would go into that new theme switch case

  switch (presetColor) {
    case 'theme1':
      colors = defaultColor;
      break;
    case 'default':
    default:
      colors = defaultColor;
  }

  const paletteVals: unknown = {
    palette: {
      mode: navType,
      common: {
        black: colors.darkPaper,
        white: colors.paper,
      },
      primary: {
        light: colors.primaryLight,
        main: colors.primaryMain,
        dark: colors.primaryDark,
        200: colors.primary200,
        800: colors.primary800,
      },
      secondary: {
        light: colors.secondaryLight,
        main: colors.secondaryMain,
        dark: colors.secondaryDark,
        200: colors.secondary200,
        800: colors.secondary800,
      },
      error: {
        light: colors.errorLight + 60,
        main: colors.errorMain,
        dark: colors.errorDark,
      },
      orange: {
        light: colors.orangeLight,
        main: colors.orangeMain,
        dark: colors.orangeDark,
      },
      warning: {
        light: colors.warningLight,
        main: colors.warningMain,
        dark: colors.warningDark,
      },
      success: {
        light: colors.successLight + 60,
        200: colors.success200,
        main: colors.successMain,
        dark: colors.successDark,
      },
      grey: {
        50: colors.grey50,
        100: colors.grey100,
        500: colors.grey500,
        600: colors.grey900,
        700: colors.grey700,
        900: colors.grey900,
      },
      dark: {
        light: colors.darkTextPrimary,
        main: colors.darkLevel1,
        dark: colors.darkLevel2,
        800: colors.darkBackground,
        900: colors.darkPaper,
      },
      text: {
        primary: colors.grey700,
        secondary: colors.grey500,
        dark: colors.grey900,
        hint: colors.grey100,
      },
      divider: colors.grey200,
      background: {
        paper: navType === 'dark' ? colors.darkPaper : colors.paper,
        default: colors.paper,
      },
      brand: {
        clover: colors.clover,
        juniper: colors.juniper,
        fir: colors.fir,
        moss: colors.moss,
        steel: colors.steel,
        silicon: colors.silicon,
        magnesium: colors.magnesium,
        cobalt: colors.cobalt,
        cadmium: colors.cadmium,
        bromine: colors.bromine,
      },
    },
  };

  return createTheme(paletteVals as ThemeOptions);
};

export default Palette;
