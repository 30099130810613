import { useRoutes } from 'react-router-dom';

// routes
import MobileInventoryRoutes from 'routes/MobileInventoryRoutes';
import LoginRoutes from 'routes/LoginRoutes';
import AuthenticationRoutes from 'routes/AuthenticationRoutes';
import AppRoutes from 'routes/AppRoutes';
import MainRoutes from 'routes/MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AppRoutes,
    MobileInventoryRoutes,
    LoginRoutes,
    AuthenticationRoutes,
  ]);
}
