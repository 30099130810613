import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import { AppCard } from 'ui-component/AppCard';
import MainCard from 'ui-component/cards/MainCard';
import _ from 'lodash';
import KnockNotificationWidget from 'ui-component/third-party/KnockNotificationWidget';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { gridSpacing } from 'constants/themeConstants';
import { IS_KNOCK_PROD } from 'constants/envConstants';
import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/org';
import { useMemo } from 'react';
import { pluralizeNoun } from 'utils/functions';
import { formatToShortDate } from 'utils/dates';
import { IconClock } from '@tabler/icons-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import OnboardingDialog from 'ui-component/OnboardingDialog';

const Dashboard = () => {
  const { isLoading: isLoadingAppAccess, appsWithPermissions } =
    useAppAccessContext();

  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);

  const enabledApps = _.values(appsWithPermissions).filter(
    (app) =>
      app.permissions?.state === 'enabled' &&
      !app.permissions?.comingSoon &&
      app.path
  );
  const disabledApps = _.values(appsWithPermissions).filter(
    (app) =>
      app.permissions?.state !== 'enabled' && !app.permissions?.comingSoon
  );
  const comingSoonApps = _.values(appsWithPermissions).filter(
    (app) => app.permissions?.comingSoon
  );

  // calculate whether org planExpiresOn is before todays date
  const showExpiredPlan = useMemo(() => {
    if (org?.planExpiresOn) {
      const today = new Date();
      return new Date(org?.planExpiresOn) < today;
    }
    return false;
  }, [org?.planExpiresOn]);

  // if org plan is not expired calculate the number of days remaining until org planExpiresOn is reached
  const planExpiresIn = useMemo(() => {
    if (org?.planExpiresOn) {
      const today = new Date();
      const planExpiresOn = new Date(org?.planExpiresOn);
      const timeDiff = planExpiresOn.getTime() - today.getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    return 0;
  }, [org?.planExpiresOn]);

  // if org plan is not expired calculate the number of days remaining until org planExpiresOn is reached
  const planTrialEndsIn = useMemo(() => {
    if (org?.trialEndsOn) {
      const today = new Date();
      const trialEndsOn = new Date(org?.trialEndsOn);
      const timeDiff = trialEndsOn.getTime() - today.getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
    return 0;
  }, [org?.trialEndsOn]);

  return (
    <Grid
      container
      spacing={gridSpacing}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <OnboardingDialog />
      {!!org?.trialEndsOn && planTrialEndsIn > 0 && (
        <Grid item xs={12}>
          <Alert
            variant="filled"
            severity="success"
            icon={<IconClock />}
            action={
              <Button
                rel="noopener noreferrer"
                variant="contained"
                color="success"
                href="mailto:success@cofactr.com?subject=Upgrade/Cancel Cofactr Plan"
              >
                Contact Cofactr Success
              </Button>
            }
          >
            <AlertTitle>
              {`${planTrialEndsIn.toLocaleString('en-US')} ${pluralizeNoun(
                'Day',
                planTrialEndsIn
              )} Left in Your Trial!`}
            </AlertTitle>
            If you would like to continue using Cofactr, no action is required!
            Contact Cofactr Success to cancel your plan before{' '}
            {formatToShortDate(org?.trialEndsOn)}. Otherwise, your trial will
            automatically convert to a paid subscription.
          </Alert>
        </Grid>
      )}
      {!!org?.planExpiresOn && planExpiresIn > 0 && (
        <Grid item xs={12}>
          <Alert
            variant="filled"
            severity="warning"
            icon={<IconClock />}
            action={
              <Button
                rel="noopener noreferrer"
                variant="contained"
                color="warning"
                href="mailto:success@cofactr.com?subject=Upgrade Cofactr Plan"
              >
                Contact Cofactr Success
              </Button>
            }
          >
            <AlertTitle>
              {`${planExpiresIn.toLocaleString('en-US')} ${pluralizeNoun(
                'Day',
                planExpiresIn
              )} Left in Your Trial!`}
            </AlertTitle>
            To continue using Cofactr after{' '}
            {formatToShortDate(org?.planExpiresOn)}, contact Cofactr Success to
            upgrade to a paid subscription or request a trial extension.
          </Alert>
        </Grid>
      )}
      {!!org?.planExpiresOn && showExpiredPlan && (
        <Grid item xs={12}>
          <Alert
            variant="filled"
            severity="error"
            icon={<IconClock />}
            action={
              <Button
                rel="noopener noreferrer"
                variant="contained"
                color="error"
                href="mailto:success@cofactr.com?subject=Upgrade Cofactr Plan"
              >
                Contact Cofactr Success
              </Button>
            }
          >
            <AlertTitle>Your Trial Has Ended!</AlertTitle>
            Your trial ended on {formatToShortDate(org?.planExpiresOn)}. To
            continue using Cofactr, contact Cofactr Success to upgrade to a paid
            subscription or request a trial extension.
          </Alert>
        </Grid>
      )}
      <Grid
        container
        item
        xs={6}
        spacing={gridSpacing}
        direction="column"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <MainCard title="Your Apps">
            <Grid container spacing={gridSpacing}>
              {enabledApps.map((app) => (
                <AppCard
                  key={app.id}
                  app={app}
                  isLoading={isLoadingAppAccess}
                />
              ))}
            </Grid>
          </MainCard>
        </Grid>
        {!!disabledApps.length && (
          <Grid item xs={12}>
            <MainCard title="Disabled Apps">
              <Grid container spacing={gridSpacing}>
                {disabledApps.map((app) => (
                  <AppCard
                    key={app.id}
                    app={app}
                    isLoading={isLoadingAppAccess}
                  />
                ))}
              </Grid>
            </MainCard>
          </Grid>
        )}
        {!!comingSoonApps.length && (
          <Grid item xs={12}>
            <MainCard title="Coming Soon">
              <Grid container spacing={gridSpacing}>
                {comingSoonApps.map((app) => (
                  <AppCard
                    key={app.id}
                    app={app}
                    isLoading={isLoadingAppAccess}
                  />
                ))}
              </Grid>
            </MainCard>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <MainCard>
          <KnockNotificationWidget
            authWithKnock={IS_KNOCK_PROD}
            variant="inbox"
          />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
