import { useTheme } from '@mui/material/styles';
import { useNewVersion } from 'contexts/NewVersionContext';
import Banner from 'ui-component/Banner';

export const NewVersionBanner = () => {
  const theme = useTheme();
  const { handleUpdateVersion } = useNewVersion();

  return (
    <Banner
      bannerMessage="A new Version of Cofactr is ready. Please refresh to get the latest updates!"
      buttonText="Refresh"
      showButton
      backgroundColor={theme.palette.success.main}
      onButtonClick={handleUpdateVersion}
    />
  );
};
