import { useDispatch, useSelector } from 'store';
import {
  Stack,
  Autocomplete,
  TextField,
  Box,
  Avatar,
  Tooltip,
} from '@mui/material';
import { setActiveOrgId } from 'store/slices/org';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTES } from 'routes/constants';
import { useGetUserQuery } from 'store/slices/user';
import {
  IconArrowsShuffle2,
  IconBeach,
  IconCornerDownRight,
} from '@tabler/icons-react';
import { handleOrgSwitch, setStoredOrg } from 'store/actions';
import { skipToken } from '@reduxjs/toolkit/query/react';

import Loader from 'ui-component/Loader';
import _ from 'lodash';
import { Org } from 'types/org';
import { useTheme } from '@mui/material/styles';
import { getCustomizedParts } from 'store/slices/partData';
import { memo, useMemo, useCallback } from 'react';

const ActiveOrgSwitcher = ({
  skipResetState,
  ignoreBlended,
}: {
  skipResetState?: boolean;
  ignoreBlended?: boolean;
}) => {
  const theme = useTheme();
  const { activeOrgId, blended, sandbox } = useSelector((state) => state.org);
  const { isLoggedIn } = useSelector((state) => state.session);
  const { viewAsStaff } = useSelector((state) => state.user);

  const { data: user, isSuccess: isGetUserSuccess } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customizedParts = useSelector(getCustomizedParts);

  const options = useMemo(() => {
    if (!user || user?.orgs.length < 2) return [];
    return _.chain(user?.orgs)
      .filter((o) => !o.createdFrom)
      .sortBy('name')
      .map((o) => [
        o,
        ..._.chain(user?.orgs)
          .filter(
            (co) =>
              (typeof co.createdFrom === 'string'
                ? co.createdFrom
                : co.createdFrom?.id) === o.id
          )
          .value(),
      ])
      .flatten()
      .value();
  }, [user?.orgs]);

  const handleOptionChange = useCallback(
    (__, option) => {
      if (option) {
        dispatch(setStoredOrg((option as Org).id));
        dispatch(
          setActiveOrgId({
            orgId: (option as Org).id,
            org: option as Org,
            skipResetState,
          })
        );
        dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
        if (!skipResetState) {
          navigate(DASHBOARD_ROUTES.DASHBOARD);
        }
      }
    },
    [dispatch, navigate, customizedParts, skipResetState]
  );

  const activeOrg = useMemo(
    () => _.find(user?.orgs, { id: activeOrgId }),
    [user?.orgs, activeOrgId]
  );

  if (!isLoggedIn) {
    return <></>;
  }
  if (!isGetUserSuccess) {
    return <Loader />;
  }
  if (options.length === 0) return <></>;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      sx={{ ml: 1 }}
    >
      <Autocomplete
        options={options}
        getOptionLabel={(option) => (option as Org).name}
        onChange={handleOptionChange}
        autoComplete
        disabled={blended && !ignoreBlended}
        disableClearable
        sx={{ minWidth: '300px' }}
        value={activeOrg}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {!!(option as Org).createdFrom && (
              <IconCornerDownRight style={{ marginRight: '6px' }} />
            )}
            {(option as Org).sandbox && (
              <IconBeach style={{ marginRight: '6px' }} />
            )}
            {(option as Org).name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            label="Active Org"
            fullWidth
          />
        )}
      />
      {!ignoreBlended && (
        <Tooltip
          title={
            blended ? 'Disable blended view mode' : 'Enable blended view mode'
          }
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background: blended
                ? theme.palette.success.main
                : theme.palette.brand.moss,
              color: blended
                ? theme.palette.common.white
                : theme.palette.brand.steel,
              '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.common.white,
              },
            }}
            onClick={() => {
              if (blended) {
                dispatch(setActiveOrgId({ skipResetState, blended: false }));
              } else {
                dispatch(setActiveOrgId({ skipResetState, blended: true }));
                dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
              }
              dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
              if (!skipResetState) {
                navigate(DASHBOARD_ROUTES.DASHBOARD);
              }
            }}
            color="inherit"
          >
            <IconArrowsShuffle2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </Tooltip>
      )}
      {blended && !ignoreBlended && (
        <Tooltip
          title={sandbox ? 'Disable sandbox mode' : 'Enable sandbox mode'}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background: sandbox
                ? theme.palette.success.main
                : theme.palette.brand.moss,
              color: sandbox
                ? theme.palette.common.white
                : theme.palette.brand.steel,
              '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.common.white,
              },
            }}
            onClick={() => {
              dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
              if (sandbox) {
                dispatch(
                  setActiveOrgId({
                    skipResetState,
                    blended: true,
                    sandbox: false,
                  })
                );
              } else {
                dispatch(
                  setActiveOrgId({
                    skipResetState,
                    blended: true,
                    sandbox: true,
                  })
                );
              }
            }}
            color="inherit"
          >
            <IconBeach stroke={1.5} size="1.3rem" />
          </Avatar>
        </Tooltip>
      )}
    </Stack>
  );
};

export default memo(ActiveOrgSwitcher);
