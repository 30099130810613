import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { GenericObject } from 'types';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';

type HookFormAutoCompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  options?: T[] | never;
  getOptionLabel?: (option: T) => string | never;
  handleChange?: (option: T) => void | never;
  initialValueMatcher?: (value: string) => string | unknown | null | never;
  disabled?: boolean | never;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
};

export type BaseHookFormAutocompleteProps<T extends GenericObject | string> = {
  options: T[];
  getOptionLabel: (option: T) => string;
  handleChange?: (option: T) => void;
  initialValueMatcher?: (value: string) => string | unknown | null;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
};

type HookFormAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormAutoCompletePropsDisableRequired<T>
      | BaseHookFormAutocompleteProps<T>
    );

export const HookFormAutocomplete = <T extends GenericObject | string>({
  name = '',
  control,
  options = [],
  label,
  errors,
  getOptionLabel,
  disabled,
  handleChange,
  initialValueMatcher,
  mb = 2,
  fullWidth,
  multiple,
}: HookFormAutocompleteProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value, onBlur, ref } }) => (
      <Autocomplete
        fullWidth={fullWidth}
        multiple={multiple}
        options={options}
        onChange={(__, data) =>
          handleChange ? handleChange(data as T) : onChange(data)
        }
        value={
          initialValueMatcher
            ? initialValueMatcher(value)
            : typeof value === 'string'
            ? options.find((o) => o === value)
            : value || (multiple ? [] : null)
        }
        onBlur={onBlur}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <Box sx={{ mb }}>
            <TextField {...params} label={label} fullWidth inputRef={ref} />
            {errors?.[name]?.message && (
              <FormHelperText error id={`${name}Error`}>
                {errors?.[name]?.message}
              </FormHelperText>
            )}
          </Box>
        )}
        disabled={disabled}
      />
    )}
  />
);
