import { createSlice } from '@reduxjs/toolkit';
import { RootState, store } from 'store';
import { Dispatch } from 'redux';
import { DefaultRootStateProps } from 'types';
import { URLS } from 'store/constant';
import {
  CreateSubOrgRequest,
  Lookup,
  Org,
  OrgPatch,
  OrgUserCreate,
  OrgUserCreateResponse,
  OrgUserFull,
  OrgUserUpdate,
  OrgUserUpdateResponse,
} from 'types/org';
import { apiSlice } from 'store/slices/api';
import { analyticsTrack } from 'utils/functions';
import { logout, softLogout } from 'store/actions';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrg: builder.query<Org, Org['id']>({
      query: (orgId) => URLS.ORG(orgId),
      async onQueryStarted(orgId, { dispatch, queryFulfilled }) {
        const { data: org } = await queryFulfilled;
        const activeOrgId = store.getState().org.activeOrgId;
        if (activeOrgId === orgId) {
          dispatch(
            setActiveOrgId({
              skipResetState: true,
              org,
            })
          );
        }
      },
      providesTags: (result, error, orgId) => [{ type: 'Org', id: orgId }],
    }),
    updateOrg: builder.mutation<Org, { orgId: Org['id']; payload: OrgPatch }>({
      query: ({ orgId, payload }) => ({
        url: URLS.ORG(orgId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { orgId }) => [
        { type: 'Org', id: orgId },
      ],
    }),
    userLookup: builder.query<Lookup, string>({
      query: (email) => URLS.USER_LOOKUP(email),
      providesTags: (result, error, email) => [
        { type: 'UserLookup', id: email },
      ],
    }),
    getOrgUsers: builder.query<OrgUserFull[], Org['id']>({
      query: () => URLS.ORG_USERS,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: OrgUserFull) => ({ type: 'User', id } as const)
              ),
              { type: 'User', id: 'LIST' },
            ]
          : [{ type: 'User', id: 'LIST' }],
    }),
    createOrgUser: builder.mutation<
      OrgUserCreateResponse,
      { payload: OrgUserCreate }
    >({
      query: ({ payload }) => ({
        url: URLS.ORG_USERS,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted({ payload }, { getState }) {
        analyticsTrack('Invite Sent', {
          invitee_email: payload.email,
          invitee_first_name: payload.firstName,
          invitee_last_name: payload.lastName,
          // @ts-ignore
          context: { groupId: getState().org.activeOrgId },
        });
      },
      invalidatesTags: () => [{ type: 'User', id: 'LIST' }],
    }),
    getOrgUser: builder.query<OrgUserFull, { userId: OrgUserFull['id'] }>({
      query: ({ userId }) => URLS.ORG_USER(userId),
      providesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
      ],
    }),
    updateOrgUser: builder.mutation<
      OrgUserUpdateResponse,
      {
        userId: OrgUserFull['id'];
        payload: OrgUserUpdate;
      }
    >({
      query: ({ userId, payload }) => ({
        url: URLS.ORG_USER(userId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        // Need to bust all users, because "update" is how an existing user outside the org
        // is added, but their ID is fetched seperately from the getOrgUser query, so their tag isn't listend against type: 'User'.
        // I guess we could instead set user lookup queries against this User tag, but idk
        { type: 'User', id: 'LIST' },
      ],
    }),
    deleteOrgUser: builder.mutation<
      string,
      {
        userId: OrgUserFull['id'];
      }
    >({
      query: ({ userId }) => ({
        url: URLS.ORG_USER(userId),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'User', id: 'LIST' },
      ],
    }),
    reinviteOrgUser: builder.mutation<
      OrgUserCreateResponse,
      { userId: OrgUserFull['id'] }
    >({
      query: ({ userId }) => ({
        url: URLS.ORG_USER_INVITE(userId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: 'LIST' },
        { type: 'User', id: userId },
      ],
    }),
    // Need custom queryFn until we can get form data boundaries working
    // with RTK query's default fetch function
    uploadOrgLogo: builder.mutation<
      string,
      { orgId: Org['id']; payload: FormData }
    >({
      queryFn: async ({ orgId, payload }, { getState }) => {
        const result = await fetch(URLS.ORG_IMAGE(orgId), {
          method: 'POST',
          body: payload,
          headers: {
            Authorization: `Bearer ${
              (getState() as RootState).session.authToken
            }`,
            'X-ORG-ID': orgId,
          },
        });
        const data = await result.json();
        return { data };
      },
      // query: ({ orgId, payload }) => ({
      //   url: URLS.ORG_IMAGE(orgId),
      //   method: 'POST',
      //   body: payload,
      // }),
      invalidatesTags: (result, error, { orgId }) => [
        { type: 'Org', id: orgId },
      ],
    }),
    getOrgs: builder.query<Org[], void>({
      query: () => ({
        url: URLS.ORGS,
      }),
      providesTags: () => [{ type: 'Orgs', id: 'LIST' }],
    }),
    createSubOrg: builder.mutation<Org, { payload: CreateSubOrgRequest }>({
      query: ({ payload }) => ({
        url: URLS.ORGS,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'Orgs', id: 'LIST' }],
    }),
  }),
});
export const {
  useGetOrgQuery,
  useLazyUserLookupQuery,
  useUpdateOrgMutation,
  useGetOrgUsersQuery,
  useCreateOrgUserMutation,
  useGetOrgUserQuery,
  useUpdateOrgUserMutation,
  useDeleteOrgUserMutation,
  useReinviteOrgUserMutation,
  useUploadOrgLogoMutation,
  useGetOrgsQuery,
  useCreateSubOrgMutation,
} = extendedApiSlice;

const initialState: DefaultRootStateProps['org'] = {
  activeOrgId: null,
  activeOrg: null,
  blended: false,
  sandbox: false,
};
const slice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setActiveOrgId(state, action) {
      state.activeOrgId =
        action.payload.orgId !== undefined
          ? action.payload.orgId
          : state.activeOrgId;
      state.activeOrg =
        action.payload.org !== undefined ? action.payload.org : state.activeOrg;
      state.blended =
        action.payload.blended !== undefined
          ? action.payload.blended
          : state.blended;
      state.sandbox =
        action.payload.sandbox !== undefined
          ? action.payload.sandbox
          : state.sandbox;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        state.activeOrgId = null;
        state.activeOrg = null;
        state.blended = false;
        state.sandbox = false;
      })
      .addCase(softLogout, (state) => {
        state.activeOrgId = null;
        state.activeOrg = null;
        state.blended = false;
        state.sandbox = false;
      });
  },
});

export const setActiveOrgId =
  (data: {
    orgId?: string | null;
    skipResetState?: boolean;
    blended?: boolean;
    sandbox?: boolean;
    org?: Org | null;
  }) =>
  (dispatch: Dispatch): void => {
    dispatch(slice.actions.setActiveOrgId(data));
    if (!data.skipResetState) {
      dispatch(apiSlice.util.resetApiState());
    }
  };

export default slice.reducer;
