import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { ChangeEvent } from 'react';

interface BaseToggleProps extends CheckboxProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  checkboxSx?: SxProps<Theme>;
  additionalOnChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

type ToggleProps = BaseToggleProps & HookFormComponentProps;

export const HookFormCheckbox = ({
  name = '',
  control,
  label,
  disabled,
  defaultValue,
  sx,
  checkboxSx,
  additionalOnChange,
}: ToggleProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        value={value}
        key={name}
        sx={sx}
        control={
          <Checkbox
            sx={checkboxSx}
            checked={!!value}
            onChange={(e) => {
              onChange(e);
              additionalOnChange && additionalOnChange(e);
            }}
            name={name}
            disabled={disabled}
          />
        }
        label={label}
      />
    )}
  />
);
