import { FC, useMemo, useState } from 'react';
import {
  differenceInBusinessDays,
  differenceInCalendarDays,
  addBusinessDays,
  addDays,
  isWeekend,
} from 'date-fns';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const timeDurations = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
];

export type HookFormDateDurationInputProps = {
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  durationLabel?: string;
  disabled?: boolean;
  businessDays?: boolean;
  isSetAsDate?: boolean;
} & TextFieldProps;

export const HookFormDateDurationInput: FC<HookFormDateDurationInputProps> = ({
  control,
  name,
  setValue,
  label,
  durationLabel,
  disabled = false,
  businessDays = false,
  isSetAsDate = false,
}) => {
  const daysInWeek = useMemo(() => (businessDays ? 5 : 7), [businessDays]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [duration, setDuration] = useState(0);
  const [durationType, setDurationType] = useState('days');

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateDifference = businessDays
        ? differenceInBusinessDays(date, new Date())
        : differenceInCalendarDays(date, new Date());
      setSelectedDate(date);
      setDuration(dateDifference);
      setValue(name, isSetAsDate ? date : dateDifference);
      if (dateDifference % daysInWeek !== 0) {
        setDurationType('days');
      }
    } else {
      setSelectedDate(null);
      setDuration(0);
      setValue(name, 0);
    }
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = Math.floor(Number(e.target.value));
    const computedDuration =
      durationType === 'days' ? newDuration : newDuration * daysInWeek;
    const newSelectedDate = businessDays
      ? addBusinessDays(new Date(), computedDuration)
      : addDays(new Date(), computedDuration);
    setSelectedDate(newSelectedDate);
    setDuration(computedDuration);
    setValue(name, isSetAsDate ? newSelectedDate : computedDuration);
  };

  const handleDurationTypeChange = (e: SelectChangeEvent<string>) => {
    setValue(name, 0);
    setSelectedDate(null);
    setDuration(0);
    setDurationType(e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: '1 1 auto', mr: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={label}
                disabled={disabled}
                name={name}
                minDate={new Date()}
                shouldDisableDate={businessDays ? isWeekend : undefined}
                onChange={handleDateChange}
                value={selectedDate}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
            <TextField
              sx={{ flex: '4 1 auto', mr: 1 }}
              onChange={handleDurationChange}
              label={durationLabel}
              value={
                Math.ceil(
                  (isSetAsDate ? duration : value) /
                    (durationType === 'weeks' ? daysInWeek : 1)
                ) || ''
              }
              inputProps={{ min: 0 }}
              disabled={disabled}
              type="number"
            />
            <Select
              sx={{ flex: '1 0 auto' }}
              onChange={handleDurationTypeChange}
              value={durationType}
              disabled={disabled}
            >
              {timeDurations.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
    />
  );
};
