import { useSelector } from 'store';
import { useGetUserQuery } from 'store/slices/user';
import { isCofactrUser } from 'utils/userPermissions';

export const useIsCofactr = () => {
  const { viewAsStaff } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(undefined);
  const isCofactr = isCofactrUser({ user, viewAsStaff });
  return {
    isCofactr,
    user,
    viewAsStaff,
  };
};
