import { User } from 'types/user';
import { FC, useEffect, useState } from 'react';
import { useLazyGlobalSearchQuery } from 'store/slices/api';
import { GlobalSearchResponse } from 'types';
import { Button, Stack, Box, IconButton } from '@mui/material';
import {
  IconSquareLetterK,
  IconCommand,
  IconSparkles,
} from '@tabler/icons-react';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { useTheme } from '@mui/material/styles';
import { ALL_VIEWS, ALL_APPS } from 'constants/appConstants';
import { ICommandFromClientType } from 'commandbar/build/internal/src/middleware/types';

export type CommandBarProps = {
  userId: User['id'] | null;
};

// Routes must start with leading slash or commandbar will
// append the route to the current route
const RecordActions: { [key: string]: ICommandFromClientType } = {
  Bom: {
    text: 'Go to BOM',
    name: 'open_bom',
    heading: 'BOM Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.BOMS.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  StockLot: {
    text: 'Go to StockLot',
    name: 'open_stocklot',
    heading: 'Stock Lot Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  StockLocation: {
    text: 'Go to Stock Locations',
    name: 'open_stocklocations',
    heading: 'Stock Location Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.MANAGED_INVENTORY.views?.STOCK_LOCATIONS?.path}`,
      operation: 'router',
    },
  },
  Shipment: {
    text: 'Go to Shipment',
    name: 'open_shipment',
    heading: 'Shipment Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.KITTING.views?.SHIPMENTS?.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  OrgPart: {
    text: 'Go to Part',
    name: 'open_part',
    heading: 'Part Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.PART_SEARCH.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  OrgSupplier: {
    text: 'Go to Supplier',
    name: 'open_suppliers',
    heading: 'Supplier Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`,
      operation: 'router',
    },
  },
  PurchaseRule: {
    text: 'Go to Purchase Rule',
    name: 'open_purchase_rule',
    heading: 'Purchase Rule Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.SUPPLIERS.views?.PURCHASE_RULES?.path}`,
      operation: 'router',
    },
  },

  ProductionRun: {
    text: 'Go to Production Run',
    name: 'open_production_run',
    heading: 'Production Run Actions',
    template: {
      type: 'link',
      value: `${ALL_APPS.PRODUCTION.path}/{{record.id}}`,
      operation: 'router',
    },
  },
  PurchaseOrder: {
    text: 'Go to Purchase Order',
    name: 'open_purchase_order',
    heading: 'Purchase Order Actions',
    template: {
      type: 'link',
      value: `${ALL_VIEWS.PURCHASE_ORDERS.path}/{{record.id}}`,
      operation: 'router',
    },
  },
};

const CommandBar: FC<CommandBarProps> = ({ userId }) => {
  const { borderRadius } = useConfig();
  const [search] = useLazyGlobalSearchQuery();
  const [booted, setBooted] = useState(false);
  const { drawerOpen } = useSelector((state) => state.menu);
  const theme = useTheme();

  const onInputChange = async (query: string) => {
    const fetch = async () => {
      const data = await search(query).unwrap();
      return data;
    };
    type output = {
      [key: string]: (GlobalSearchResponse & { label: string })[];
    };
    return new Promise<any>((resolve: any) =>
      fetch().then((results) => {
        const out: output = {};
        results.forEach((res) => {
          const newRes = { ...res, label: res.display };
          if (!(newRes.model in out)) {
            out[newRes.model] = [];
          }
          out[newRes.model].push(newRes);
        });
        Object.keys(RecordActions).forEach((key) => {
          if (!out[key]) {
            out[key] = [];
          }
        });
        return resolve(out);
      })
    );
  };

  useEffect(() => {
    if (userId && window.CommandBar) {
      window.CommandBar.boot(userId);
      setBooted(true);
    }
  }, [userId, window.CommandBar]);
  useEffect(() => {
    if (window.CommandBar) {
      Object.keys(RecordActions).forEach((key) => {
        window.CommandBar.addRecords(key, []);
        window.CommandBar.addRecordAction(key, RecordActions[key]);
      });
      window.CommandBar.addMultiSearch(
        onInputChange,
        Object.keys(RecordActions)
      );
    }
  }, []);

  function handleClick() {
    window.CommandBar.open();
  }

  return booted ? (
    drawerOpen ? (
      <Button
        variant="outlined"
        fullWidth
        sx={{
          borderRadius: `${borderRadius}px`,
          mb: 1,
          borderColor: '#bdbdbd',
          color: theme.palette.primary.main,
        }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <IconSparkles size={20} />
            <Box sx={{ ml: 1 }}>Find Anything</Box>
          </Box>
          <Stack direction="row" alignItems="center">
            <IconCommand size={20} />
            <IconSquareLetterK size={20} />
          </Stack>
        </Stack>
      </Button>
    ) : (
      <IconButton
        sx={{
          borderRadius: `${borderRadius}px`,
          mb: 1,
          width: '42px',
        }}
        onClick={handleClick}
      >
        <IconSparkles size={22} />
      </IconButton>
    )
  ) : (
    <></>
  );
};

export { CommandBar };
