import MainCard from 'ui-component/cards/MainCard';
import { AppCard } from 'ui-component/AppCard';
import { useCurrentApp } from 'hooks/useCurrentApp';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { IconArrowRight } from '@tabler/icons-react';
import { Badge, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ALL_APP_IDS,
  ALL_VIEW_IDS,
  noAccessMessages,
} from 'constants/appConstants';
import { appPermissionStates } from 'types/apps';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { gridSpacing } from 'constants/themeConstants';
import { memo, useEffect } from 'react';

const AppLocation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentApp, currentView } = useCurrentApp();
  const { appsWithPermissions } = useAppAccessContext();
  const location = useLocation();

  useEffect(() => {
    if (currentView) {
      document.title = `Cofactr - ${currentView.name}`;
    } else if (currentApp?.name) {
      document.title = `Cofactr - ${currentApp.name}`;
    } else {
      document.title = 'Cofactr';
    }
    if (window.analytics) {
      analytics.page(location.pathname);
    }
  }, [location.pathname, currentApp?.id, currentView?.id]);

  const currentAppShortcuts = _.compact(
    (currentApp?.shortcuts || []).map((shortcut) => {
      const splitShortcut = shortcut.split('.');
      return {
        shortcut: _.get(appsWithPermissions, splitShortcut, null),
        app: splitShortcut[0] as keyof typeof ALL_APP_IDS,
        view:
          splitShortcut.length > 1
            ? (splitShortcut[-1] as keyof typeof ALL_VIEW_IDS)
            : undefined,
      };
    })
  );

  return (
    <MainCard
      sx={{ mb: gridSpacing }}
      contentSX={{
        p: 2,
        '&:last-child': {
          p: 2,
        },
      }}
    >
      <WithSkeleton isLoading={!currentApp}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          {currentApp && <AppCard app={currentApp} variant="title" />}
          <Grid item xs="auto">
            <Typography
              variant="h2"
              color={theme.palette.brand.steel}
              align="left"
            >
              {currentApp?.name
                ? `${currentApp?.name}`
                : 'Error: App Not Found'}
            </Typography>
          </Grid>
          {currentApp?.views && (
            <>
              <Divider orientation="vertical" flexItem sx={{ ml: 3, mt: 3 }} />
              <Grid item xs>
                <ExtendedTabs
                  bottomSpacing={0}
                  value={
                    currentView?.id || Object.values(currentApp?.views)?.[0]?.id
                  }
                  onClick={(tabName: string) =>
                    navigate(_.get(currentApp, ['views', tabName, 'path'], ''))
                  }
                  tabOptions={(_.values(currentApp?.views) || []).map(
                    (view) => ({
                      label: (
                        <Tooltip
                          title={`${
                            view.permissions?.state !==
                            appPermissionStates.enabled
                              ? view.permissions?.comingSoon
                                ? noAccessMessages.comingSoon
                                : noAccessMessages[
                                    view.permissions?.state ||
                                      appPermissionStates.unavailable
                                  ]
                              : view.name
                          }`}
                          placement="top"
                        >
                          <Badge
                            badgeContent={view.globalBeta ? 'BETA' : null}
                            color="primary"
                            sx={{
                              '.MuiBadge-anchorOriginTopRight': {
                                right: '10px',
                              },
                            }}
                          >
                            {view.name}
                          </Badge>
                        </Tooltip>
                      ),
                      name: view.id,
                      icon: <view.icon />,
                      disabled:
                        view.permissions?.state !== appPermissionStates.enabled,
                    })
                  )}
                />
              </Grid>
              {currentApp?.shortcuts && (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ ml: 3, mt: 3 }}
                  />
                  <Grid item container xs={12} rowSpacing={2}>
                    {currentAppShortcuts.map((shortcut) => (
                      <Grid item key={shortcut.shortcut.path} xs="auto">
                        <SegmentButton
                          sx={{ mr: 2 }}
                          variant="outlined"
                          startIcon={<shortcut.shortcut.icon />}
                          endIcon={<IconArrowRight />}
                          onClick={() => navigate(shortcut.shortcut.path)}
                          disabled={
                            shortcut.shortcut.permissions?.state !==
                            appPermissionStates.enabled
                          }
                          permissionScope={{
                            app: shortcut.app,
                            ...[shortcut.view ? { view: shortcut.view } : {}],
                          }}
                        >
                          {shortcut.shortcut.name}
                        </SegmentButton>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </WithSkeleton>
    </MainCard>
  );
};

export default memo(AppLocation);
