import { useSelector } from 'store';
import { useGetOrgQuery } from 'store/slices/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Org } from 'types/org';

export default function useSubOrgsViews() {
  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const isSubOrg = !!org?.createdFrom;
  const createdFrom = org?.createdFrom as Org;
  const showSubOrgUsageAndPlan = !org?.billedWithParent;

  return {
    isSubOrg,
    createdFrom,
    showSubOrgUsageAndPlan,
  };
}
