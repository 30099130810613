import { FC } from 'react';
import HotglueConfig from '@hotglue/widget';

const HOTGLUE_PUBLIC_API_KEY = process.env.HOTGLUE_PUBLIC_API_KEY || '';
const HOTGLUE_ENV_ID = process.env.HOTGLUE_ENV_ID || '';

const HotglueProvider: FC = ({ children }) => (
  <HotglueConfig
    config={{
      apiKey: HOTGLUE_PUBLIC_API_KEY,
      envId: HOTGLUE_ENV_ID,
    }}
  >
    {children}
  </HotglueConfig>
);

export default HotglueProvider;
