import { Outlet } from 'react-router-dom';
import BottomAppBar from 'layout/MobileInventoryLayout/BottomAppBar';
import TopAppBar from 'layout/MobileInventoryLayout/TopAppBar';

// ==============================|| MOBILE INVENTORY LAYOUT ||============================== //

const MobileInventoryLayout = () => (
  <>
    <TopAppBar />
    <Outlet />
    <BottomAppBar />
  </>
);

export default MobileInventoryLayout;
