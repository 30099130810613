// project imports
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import { AUTH_ROUTES } from 'routes/constants';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AuthResetPassword = Loadable(
  lazy(() => import('views/pages/authentication/ResetPassword'))
);

const AuthenticationRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: AUTH_ROUTES.RESET,
      element: <AuthResetPassword />,
    },
  ],
};

export default AuthenticationRoutes;
