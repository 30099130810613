import {
  Box,
  TextField,
  CircularProgress,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { GenericObject } from 'types';
import { Team, TeamType } from 'types/properties';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import {
  useGetTeamTypesQuery,
  useLazyGetTeamTypeQuery,
} from 'store/slices/properties';
import ContextualCreateButton from 'ui-component/ContextualCreateButton';
import _ from 'lodash';
import { ALL_APPS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import CreateTeamTypeDialog from 'views/properties/Teams/components/CreateTeamTypeDialog';

type HookFormTeamTypeAutocompletePropsDisableRequired<
  T extends GenericObject | string
> = {
  disableRequiredProps: true;
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  isInlineEdit?: boolean;
};

export type BaseHookFormTeamTypeAutocompleteProps<
  T extends GenericObject | string
> = {
  disabled?: boolean;
  mb?: number;
  fullWidth?: boolean;
  multiple?: boolean;
  setValue?: UseFormSetValue<FieldValues>;
  defaultValue?: T;
  sx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  isInlineEdit?: boolean;
};

type HookFormTeamAutocompleteProps<T extends GenericObject | string> =
  HookFormComponentProps &
    (
      | HookFormTeamTypeAutocompletePropsDisableRequired<T>
      | BaseHookFormTeamTypeAutocompleteProps<T>
    );

export const HookFormTeamTypeAutocomplete = <T extends GenericObject | string>({
  errors,
  control,
  name = '',
  label,
  defaultValue,
  disabled,
  sx,
  boxSx,
  fullWidth = true,
  setValue,
  isInlineEdit,
}: HookFormTeamAutocompleteProps<T>) => {
  const [newTeamTypeDialogOpen, setNewTeamTypeDialogOpen] = useState(false);
  const [selected, setSelected] = useState<TeamType | null>(null);
  const [contextuallyCreatedOption, setContextuallyCreatedOption] =
    useState<TeamType | null>(null);
  const {
    data: teamTypes,
    isFetching: isFetchingTeamTypes,
    isSuccess: isSuccessTeamTypes,
  } = useGetTeamTypesQuery(undefined);
  const [getTeamType, { data: teamType, isFetching: isFetchingTeamType }] =
    useLazyGetTeamTypeQuery();

  const handleGetTeamType = async (teamId: Team['id']) => {
    const fetchedTeamType = await getTeamType(teamId).unwrap();
    setSelected(fetchedTeamType);
  };

  const options: TeamType[] = useMemo(() => {
    const combinedOptions = [
      ...(contextuallyCreatedOption ? [contextuallyCreatedOption] : []),
      ...(teamTypes || []),
      ...([teamType] || []),
    ].filter((option) => option !== null && option !== undefined);

    return Array.from(new Set(combinedOptions.map((option) => option?.id)))
      .map((id) => combinedOptions.find((option) => option?.id === id))
      .filter((option): option is TeamType => option !== undefined);
  }, [teamTypes, contextuallyCreatedOption, teamType]);

  useEffect(() => {
    if (defaultValue) {
      const defaultTeam = _.find(teamTypes, (t) => t.id === defaultValue);
      if (!defaultTeam && typeof defaultValue === 'string') {
        handleGetTeamType(defaultValue);
      }
      setSelected(defaultTeam || null);
    }
  }, [defaultValue, isSuccessTeamTypes]);

  const AutoCompleteInput = (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur } }) => (
        <Autocomplete
          loading={isFetchingTeamTypes || isFetchingTeamType}
          fullWidth={fullWidth}
          sx={{ ...sx }}
          options={options}
          onChange={(__, data) => {
            if (data?.id !== selected?.id) {
              setSelected(data);
            }
            onChange(data?.id);
          }}
          value={selected}
          onBlur={onBlur}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <Box>
              <TextField
                {...params}
                label={label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetchingTeamTypes || isFetchingTeamType ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              {errors?.[name]?.message && (
                <FormHelperText error id={`${name}Error`}>
                  {errors?.[name]?.message}
                </FormHelperText>
              )}
            </Box>
          )}
          disabled={disabled}
        />
      )}
    />
  );

  return (
    <Box sx={{ ...boxSx }}>
      {disabled ? (
        AutoCompleteInput
      ) : (
        <>
          <ContextualCreateButton
            onClick={() => setNewTeamTypeDialogOpen(true)}
            permissionScope={{
              app: ALL_APPS.PROPERTIES.id,
              accessLevel: appPermissionAccessLevels.edit,
            }}
            sx={
              isInlineEdit
                ? {
                    borderRadius: '0px',
                    borderRight: 'none',
                    height: '42px',
                    p: '5px',
                    minWidth: '0',
                    position: 'relative',
                    left: '-1px',
                    '.MuiButton-startIcon': { p: 0, m: 0 },
                  }
                : undefined
            }
          >
            {AutoCompleteInput}
          </ContextualCreateButton>
          <CreateTeamTypeDialog
            dialogOpen={newTeamTypeDialogOpen}
            onClose={() => setNewTeamTypeDialogOpen(false)}
            onCreateSuccess={(value) => {
              const newTeamType = value as TeamType;
              setContextuallyCreatedOption(newTeamType);
              setSelected(newTeamType);
              setValue &&
                setValue(name, newTeamType.id, {
                  shouldDirty: true,
                });
            }}
          />
        </>
      )}
    </Box>
  );
};
