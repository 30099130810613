import { FC } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { HookFormComponentProps } from 'ui-component/HookFormComponents/types';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

type HookFormTelephoneInputProps = {} & MuiTelInputProps &
  HookFormComponentProps;

export const HookFormTelephoneInput: FC<HookFormTelephoneInputProps> = ({
  name = '',
  control,
  errors,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onBlur, onChange } }) => (
      <Box sx={{ mb: 2 }}>
        <MuiTelInput
          {...props}
          fullWidth
          id={name}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={(phoneVal: string) => {
            onChange(phoneVal ?? '');
          }}
          error={Boolean(errors?.[name])}
          preferredCountries={['US']}
          defaultCountry="US"
        />
        {errors?.[name]?.message && (
          <FormHelperText error id={`${name}Error`}>
            {errors?.[name]?.message}
          </FormHelperText>
        )}
      </Box>
    )}
  />
);
