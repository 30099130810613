import { URLS } from 'store/constant';
import { NoPartInventory, Part } from 'types/part';
import {
  BomCRUDSchema,
  BomLineCreate,
  BomLine,
  BomLineUpdate,
  BomLineCreateParseLines,
  BomQuerySchema,
  BomPOSTSchema,
  BomPATCHSchema,
  BomSheetPayload,
  BomSheetData,
  BomValidation,
  BomExportSheetType,
  BomExportFileType,
  BomLineIgnoreValidationIssuesForBomlinesPayload,
} from 'types/bom';
import {
  Availabilities,
  BomAvailabilitiesRequest,
  CreateBomAvailabilitiesSnapshotRequest,
  Snapshot,
} from 'types/purchasing';
import { apiSlice } from 'store/slices/api';
import { AsyncJobResponse, ListResponse, QueryParams } from 'types/api';
import { parseQueryParams } from 'store/slices/utils';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RootState } from 'store/index';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBom: builder.query<BomCRUDSchema, BomCRUDSchema['id']>({
      query: (bomId) => URLS.BOMS(null, bomId),
      providesTags: (result) =>
        result
          ? [
              { type: 'Boms', id: result.id },
              { type: 'Boms', id: 'LIST' },
            ]
          : [{ type: 'Boms', id: 'LIST' }],
    }),
    createBom: builder.mutation<BomCRUDSchema, { payload: BomPOSTSchema }>({
      query: ({ payload }) => ({
        url: URLS.BOMS(null, null),
        method: 'POST',
        body: payload,
      }),
      // Will force the Boms list cache to be invalidated
      // and thus need to be refetched.
      invalidatesTags: [
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
      ],
    }),
    updateBom: builder.mutation<
      BomCRUDSchema,
      {
        id: BomCRUDSchema['id'];
        payload: BomPATCHSchema;
      }
    >({
      query: ({ id, payload }) => ({
        url: URLS.BOMS(null, id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Boms', id },
        { type: 'Boms', id: 'PARTIAL-LIST' },
      ],
    }),
    deleteBom: builder.mutation<string, BomCRUDSchema['id']>({
      query: (bomId) => ({
        url: URLS.BOMS(null, bomId),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, bomId) => [
        { type: 'Boms', id: bomId },
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
      ],
    }),
    duplicateBom: builder.mutation<
      BomCRUDSchema,
      { bomId: BomCRUDSchema['id']; payload: BomPOSTSchema }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOM_DUPLICATE(bomId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Boms'],
    }),
    createBomLine: builder.mutation<
      BomLine,
      {
        bomId: BomCRUDSchema['id'];
        payload: BomLineCreate | BomLineCreate[];
      }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOMLINES_BOM(bomId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    createBomLineManual: builder.mutation<
      BomLine,
      {
        bomId: BomCRUDSchema['id'];
        payload: BomLineCreateParseLines | BomLineCreateParseLines[];
      }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOMLINES_PARSE_LINES(bomId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    createBomLineManualAsync: builder.mutation<
      AsyncJobResponse<null>,
      {
        bomId: BomCRUDSchema['id'];
        payload: BomLineCreateParseLines | BomLineCreateParseLines[];
      }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOMLINES_PARSE_LINES_ASYNC(bomId),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    addBomSheet: builder.mutation<
      BomSheetData,
      { bomId: BomCRUDSchema['id']; sheetData: BomSheetPayload }
    >({
      query: ({ bomId, sheetData }) => ({
        url: URLS.BOM_SHEET(bomId),
        method: 'POST',
        body: sheetData,
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'BomSheet', id: bomId },
        { type: 'BomLines', id: bomId },
      ],
    }),
    //   RTK Query doesn't handle FormData well, so we need to use a custom queryFn
    //   See uploadOrgLogo query for other spot we use FormData
    addBomWorkbook: builder.mutation<
      string,
      { bomId: BomCRUDSchema['id']; payload: FormData }
    >({
      queryFn: async ({ bomId, payload }, { getState }) => {
        const activeOrgId = (getState() as RootState).org.activeOrgId;
        const result = await fetch(URLS.BOM_WORKBOOK(bomId), {
          method: 'PUT',
          body: payload,
          headers: activeOrgId
            ? {
                Authorization: `Bearer ${
                  (getState() as RootState).session.authToken
                }`,
                'x-org-id': activeOrgId,
              }
            : {
                Authorization: `Bearer ${
                  (getState() as RootState).session.authToken
                }`,
              },
        });
        if (!result.ok) {
          return {
            error: {
              status: result.statusText,
            } as FetchBaseQueryError,
          };
        }
        const data = await result.json();
        return { data };
      },
    }),
    getBomLinesByPart: builder.query<
      BomLine[],
      {
        cpid: Part['id'];
      }
    >({
      query: ({ cpid }) => URLS.BOMLINES_PART(cpid),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: BomLine) => ({ type: 'BomLinesByPart', id } as const)
              ),
              { type: 'BomLinesByPart', id: 'LIST' },
            ]
          : [{ type: 'BomLinesByPart', id: 'LIST' }],
    }),
    getBomLines: builder.query<
      BomLine[],
      {
        bomId: BomCRUDSchema['id'];
      }
    >({
      query: ({ bomId }) => URLS.BOMLINES_BOM(bomId),
      providesTags: (result, error, { bomId }) =>
        result
          ? [
              ...result.map(
                ({ id }: BomLine) => ({ type: 'BomLines', id } as const)
              ),
              { type: 'BomLines', id: bomId },
            ]
          : [{ type: 'BomLines', id: bomId }],
    }),
    updateBomLine: builder.mutation<
      BomLine,
      {
        bomId: BomCRUDSchema['id'];
        bomLineId: BomLine['id'];
        payload: BomLineUpdate;
      }
    >({
      query: ({ bomId, bomLineId, payload }) => ({
        url: URLS.BOMLINES_BOM_LINE({ bomId, bomLineId }),
        method: 'PATCH',
        body: payload,
      }),
      async onQueryStarted({ bomLineId }, { dispatch, queryFulfilled }) {
        const { data: updatedBomLine } = await queryFulfilled;
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getBomLines',
            {
              bomId:
                typeof updatedBomLine.bom === 'string'
                  ? updatedBomLine.bom
                  : updatedBomLine.bom.id,
            },
            (draft) => {
              const index = draft.findIndex((x) => x.id === bomLineId);
              if (index > -1) {
                draft[index] = updatedBomLine;
              }
            }
          )
        );
        // duplicate of above with different query params to match
        // query params used with tempBomlinesPartRestructure feature flag
        dispatch(
          extendedApiSlice.util.updateQueryData(
            'getBomLines',
            {
              bomId:
                typeof updatedBomLine.bom === 'string'
                  ? updatedBomLine.bom
                  : updatedBomLine.bom.id,
            },
            (draft) => {
              const index = draft.findIndex((x) => x.id === bomLineId);
              if (index > -1) {
                draft[index] = updatedBomLine;
              }
            }
          )
        );
      },
      invalidatesTags: () => [{ type: 'ProductionRunParts', id: 'LIST' }],
    }),
    deleteBomLine: builder.mutation<
      BomLine['id'],
      { bomId: BomCRUDSchema['id']; bomLineId: BomLine['id'] }
    >({
      query: ({ bomId, bomLineId }) => ({
        url: URLS.BOMLINES_BOM_LINE({ bomId, bomLineId }),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { bomLineId, bomId }) => [
        // Because some boms include their bomlines
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: bomLineId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    getBomAvailabilities: builder.mutation<
      Availabilities,
      {
        bomId: BomCRUDSchema['id'];
        payload: BomAvailabilitiesRequest;
      }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOM_AVAILABILITIES(bomId),
        method: 'POST',
        body: payload,
      }),
    }),
    createBomAvailabilitiesSnapshot: builder.mutation<
      Snapshot,
      {
        bomId: BomCRUDSchema['id'];
        payload: CreateBomAvailabilitiesSnapshotRequest;
      }
    >({
      query: ({ bomId, payload }) => ({
        url: URLS.BOM_AVAILABILITIES_SNAPSHOT(bomId),
        method: 'POST',
        body: payload,
      }),
    }),
    approveBomForProductionRun: builder.mutation<
      BomCRUDSchema,
      { bomId: BomCRUDSchema['id'] }
    >({
      query: ({ bomId }) => ({
        url: URLS.BOM_APPROVE(bomId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
        { type: 'ProductionRunLines', id: 'LIST' },
      ],
    }),
    unapproveBomForProductionRun: builder.mutation<
      BomCRUDSchema,
      { bomId: BomCRUDSchema['id'] }
    >({
      query: ({ bomId }) => ({
        url: URLS.BOM_UNAPPROVE(bomId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, { bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
        { type: 'ProductionRunLines', id: 'LIST' },
      ],
    }),
    linkBomFromExternalSource: builder.mutation<
      BomCRUDSchema,
      BomCRUDSchema['id']
    >({
      query: (bomId) => ({
        url: URLS.BOM_LINK_EXTERNAL(bomId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, bomId) => [
        { type: 'Boms', id: bomId },
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
      ],
    }),
    unlinkBomFromExternalSource: builder.mutation<
      BomCRUDSchema,
      BomCRUDSchema['id']
    >({
      query: (bomId) => ({
        url: URLS.BOM_UNLINK_EXTERNAL(bomId),
        method: 'POST',
      }),
      invalidatesTags: (result, error, bomId) => [
        { type: 'Boms', id: bomId },
        { type: 'Boms', id: 'LIST' },
        { type: 'Boms', id: 'PARTIAL-LIST' },
      ],
    }),
    getBomInventory: builder.query<NoPartInventory[], BomCRUDSchema['id']>({
      query: (bomId) => URLS.BOM_INVENTORY(bomId),
      providesTags: () => [{ type: 'NoPartInventory', id: 'LIST' }],
    }),
    getBomValidation: builder.query<BomValidation, BomCRUDSchema['id']>({
      query: (bomId) => URLS.BOM_VALIDATE(bomId),
      providesTags: (__, ___, bomId) => [
        { type: 'BomValidations', id: 'LIST' },
        { type: 'BomValidations', id: bomId },
      ],
    }),
    revalidateBom: builder.mutation<BomValidation, BomCRUDSchema['id']>({
      query: (bomId) => ({
        url: URLS.BOM_VALIDATE(bomId),
        method: 'POST',
      }),
      invalidatesTags: (__, ___, bomId) => [
        { type: 'BomValidations', id: 'LIST' },
        { type: 'BomValidations', id: bomId },
      ],
    }),
    exportBomSheet: builder.query<
      BomSheetData,
      { id: BomCRUDSchema['id']; sheetType: BomExportSheetType }
    >({
      query: ({ id, sheetType }) =>
        URLS.BOM_EXPORT_SHEET({ bomId: id, sheetType }),
    }),
    //   TODO: Verify return type once API docs are fixed
    exportBomFile: builder.query<
      Blob,
      {
        id: BomCRUDSchema['id'];
        sheetType: BomExportSheetType;
        fileType: BomExportFileType;
      }
    >({
      query: ({ id, sheetType, fileType }) => ({
        url: URLS.BOM_EXPORT_FILE({ bomId: id, sheetType, fileType }),
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    batchIgnoreBomLines: builder.mutation<
      BomLine[],
      { id: BomCRUDSchema['id']; bomlineIds: BomLine['id'][] }
    >({
      query: ({ id, bomlineIds }) => ({
        url: URLS.BOM_IGNORE_LINES(id),
        method: 'POST',
        body: { bomlineIds },
      }),
      invalidatesTags: (__, ___, { id: bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    batchDnpBomLines: builder.mutation<
      BomLine[],
      { id: BomCRUDSchema['id']; bomlineIds: BomLine['id'][] }
    >({
      query: ({ id, bomlineIds }) => ({
        url: URLS.BOM_DNP_LINES(id),
        method: 'POST',
        body: { bomlineIds },
      }),
      invalidatesTags: (__, ___, { id: bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    batchDeleteBomLines: builder.mutation<
      BomLine[],
      { id: BomCRUDSchema['id']; bomlineIds: BomLine['id'][] }
    >({
      query: ({ id, bomlineIds }) => ({
        url: URLS.BOM_DELETE_LINES(id),
        method: 'POST',
        body: { bomlineIds },
      }),
      invalidatesTags: (__, ___, { id: bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    batchIgnoreValidationIssuesForBomlines: builder.mutation<
      BomLine[],
      {
        id: BomCRUDSchema['id'];
        warnings: BomLineIgnoreValidationIssuesForBomlinesPayload[];
      }
    >({
      query: ({ id, warnings }) => ({
        url: URLS.BOM_IGNORE_ISSUES(id),
        method: 'POST',
        body: { issues: warnings },
      }),
      invalidatesTags: (__, ___, { id: bomId }) => [
        { type: 'Boms', id: bomId },
        { type: 'BomLines', id: 'LIST' },
        { type: 'BomLines', id: bomId },
        { type: 'NoPartInventory', id: 'LIST' },
        { type: 'ProductionRunParts', id: 'LIST' },
      ],
    }),
    batchResetBomWarnings: builder.mutation<BomValidation, BomCRUDSchema['id']>(
      {
        query: (bomId) => ({
          url: URLS.BOM_RESET_WARNINGS(bomId),
          method: 'POST',
        }),
        invalidatesTags: (__, ___, bomId) => [
          { type: 'Boms', id: bomId },
          { type: 'BomValidations', id: 'LIST' },
          { type: 'BomValidations', id: bomId },
        ],
      }
    ),
  }),
});

export const extendedApiSliceServerSide = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBomsServerSide: builder.query<
      ListResponse<BomQuerySchema>,
      QueryParams<BomQuerySchema>
    >({
      query: (params) =>
        URLS.BOMS_SERVER(parseQueryParams<BomQuerySchema>(params)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: BomQuerySchema) => ({ type: 'Boms', id } as const)
              ),
              { type: 'Boms', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Boms', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useCreateBomMutation,
  useUpdateBomMutation,
  useDeleteBomMutation,
  useDuplicateBomMutation,
  useGetBomQuery,
  useCreateBomLineMutation,
  useCreateBomLineManualMutation,
  useCreateBomLineManualAsyncMutation,
  useAddBomSheetMutation,
  useAddBomWorkbookMutation,
  useGetBomLinesByPartQuery,
  useGetBomLinesQuery,
  useUpdateBomLineMutation,
  useDeleteBomLineMutation,
  useGetBomAvailabilitiesMutation,
  useCreateBomAvailabilitiesSnapshotMutation,
  useApproveBomForProductionRunMutation,
  useUnapproveBomForProductionRunMutation,
  useUnlinkBomFromExternalSourceMutation,
  useLinkBomFromExternalSourceMutation,
  useGetBomInventoryQuery,
  useLazyGetBomValidationQuery,
  useRevalidateBomMutation,
  useLazyExportBomFileQuery,
  useBatchIgnoreBomLinesMutation,
  useBatchIgnoreValidationIssuesForBomlinesMutation,
  useBatchDnpBomLinesMutation,
  useBatchDeleteBomLinesMutation,
} = extendedApiSlice;

export const { useGetBomsServerSideQuery, usePrefetch } =
  extendedApiSliceServerSide;
