import {
  parseISO,
  subDays,
  endOfDay,
  isBefore,
  isEqual,
  addDays,
  addBusinessDays,
  subBusinessDays,
  addMonths,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { pluralizeNoun } from 'utils/functions';

const SHORT_DATE_FORMAT = 'MM/dd/yy';
const DATE_TIME_FORMAT = 'MM/d/yyyy, h:mm a';
const SHORT_DATE_FORMAT_YYYY = 'M-d-yyyy';
const UNIVERSAL_DATE_FORMAT = 'yyyy-MM-dd';
const UNIVERSAL_DATE_TIME_FORMAT = 'M/dd/yyyy h:mma';
const MM_DD_FORMAT = 'MM/dd';

type DateParam = string | Date;

// supportedValuesOf is not found on Intl even though it is actually there
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/supportedValuesOf
// @ts-ignore
export const timeZones: string[] = Intl.supportedValuesOf
  ? // @ts-ignore
    Intl.supportedValuesOf('timeZone')
  : []; // Provide a fallback array of time zones

export const convertDate = (date: DateParam) =>
  typeof date === 'string' ? parseISO(date) : date;

export const convertToLocalTimeZone = (date: DateParam) =>
  utcToZonedTime(
    convertDate(date),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

export const formatToShortDate = (date: DateParam) =>
  date ? format(convertDate(date), SHORT_DATE_FORMAT) : undefined;

export const formatToMMDD = (date: DateParam) =>
  date ? format(convertDate(date), MM_DD_FORMAT) : undefined;

export const formatToUniversalDate = (date: DateParam) =>
  format(convertDate(date), UNIVERSAL_DATE_FORMAT);

export const formatToUniversalDateTime = (date: DateParam) =>
  format(convertToLocalTimeZone(date), UNIVERSAL_DATE_TIME_FORMAT, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

export const formatToDateTime = (date: DateParam) =>
  format(convertToLocalTimeZone(date), DATE_TIME_FORMAT);

export const getNowDate = () => {
  const now = new Date();
  const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return nowDate;
};

export const isBeforeNowDate = (date: DateParam) => {
  const now = endOfDay(getNowDate());
  if (!date) {
    return false;
  }
  const compareDate = endOfDay(convertDate(date));
  return isBefore(compareDate, now);
};

export const isDaysBeforeNowDate = (
  date: DateParam,
  days: number,
  businessDays?: boolean
) => {
  const nowMinusDays = businessDays
    ? subBusinessDays(endOfDay(getNowDate()), days)
    : subDays(endOfDay(getNowDate()), days);
  if (!date) {
    return false;
  }
  const compareDate = endOfDay(convertDate(date));
  return isBefore(compareDate, nowMinusDays);
};

export const isTodayOrLater = (date: DateParam) => {
  const now = endOfDay(getNowDate());
  const compareDate = endOfDay(convertDate(date));
  return isEqual(compareDate, now) || isBefore(now, compareDate);
};

export const isDaysAfterNowDate = (
  date: DateParam,
  days: number,
  businessDays?: boolean
) => {
  const nowPlusDays = businessDays
    ? addBusinessDays(endOfDay(getNowDate()), days)
    : addDays(endOfDay(getNowDate()), days);
  if (!date) {
    return false;
  }
  const compareDate = endOfDay(convertDate(date));
  return isBefore(nowPlusDays, compareDate);
};

export const isMonthsAfterNowDate = (date: DateParam, months: number) => {
  const nowPlusMonths = addMonths(endOfDay(getNowDate()), months);
  if (!date) {
    return false;
  }
  const compareDate = endOfDay(convertDate(date));
  return isBefore(nowPlusMonths, compareDate);
};

export const isAfterToday = (date: DateParam) => {
  const now = endOfDay(getNowDate());
  const compareDate = endOfDay(convertDate(date));
  return isBefore(now, compareDate);
};

export const nowShortDate = () => format(new Date(), SHORT_DATE_FORMAT_YYYY);

export const leadTimeUnit = {
  hour: 'hour',
  day: 'day',
  week: 'week',
};

export const daysToDaysOrWeeks = (
  days: number,
  businessDays: boolean = true
) => {
  if (days <= 20) {
    return { value: days, unit: leadTimeUnit.day };
  }

  const weeks = Math.ceil(days / (businessDays ? 5 : 7));
  return { value: weeks, unit: leadTimeUnit.week };
};

export const hoursToHoursOrDays = (hours: number) => {
  if (hours <= 72) {
    return { value: hours, unit: leadTimeUnit.hour };
  }

  const days = Math.ceil(hours / 24);
  return { value: days, unit: leadTimeUnit.day };
};

export const formatDaysToDaysOrWeeks = (
  days: number | undefined,
  dayString?: string,
  weekString?: string,
  showZero?: boolean,
  businessDays: boolean = true
) => {
  if (showZero && days === 0)
    return `0 ${pluralizeNoun(dayString || 'day', 0)}`;
  if (!days) return '';
  return `${daysToDaysOrWeeks(days, businessDays).value} ${
    daysToDaysOrWeeks(days, businessDays).unit === leadTimeUnit.week
      ? pluralizeNoun(
          weekString || 'wk',
          daysToDaysOrWeeks(days, businessDays).value
        )
      : pluralizeNoun(
          dayString || 'day',
          daysToDaysOrWeeks(days, businessDays).value
        )
  }`;
};
