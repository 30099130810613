import SegmentButton from 'ui-component/Segment/SegmentButton';
import { SyntheticEvent } from 'react';

import UIfx from 'uifx';
import error from 'assets/sounds/error.ogg';
import success from 'assets/sounds/success.ogg';

// material-ui
import {
  Alert,
  Fade as MuiFade,
  Grow as MuiGrow,
  Slide,
  SlideProps,
} from '@mui/material';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import { IconX, IconArrowRight } from '@tabler/icons-react';

import { useDispatch, useSelector } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeSnackbar } from 'store/slices/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <MuiGrow {...props} />;
}

enum AnimationTypes {
  SlideLeft = 'SlideLeft',
  SlideUp = 'SlideUp',
  SlideRight = 'SlideRight',
  SlideDown = 'SlideDown',
  Grow = 'Grow',
  Fade = 'Fade',
}

// animation options
const animation = {
  [AnimationTypes.SlideLeft]: TransitionSlideLeft,
  [AnimationTypes.SlideUp]: TransitionSlideUp,
  [AnimationTypes.SlideRight]: TransitionSlideRight,
  [AnimationTypes.SlideDown]: TransitionSlideDown,
  [AnimationTypes.Grow]: GrowTransition,
  [AnimationTypes.Fade]: MuiFade,
};

// sound effects
const successSound = new UIfx(success, {
  volume: 1,
  throttleMs: 100,
});
const errorSound = new UIfx(error, {
  volume: 1,
  throttleMs: 100,
});

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const mobileVersion = pathname.includes('/mobileinventory');
  const snackbar = useSelector((state) => state.snackbar);
  const navigate = useNavigate();
  const {
    actionButton,
    anchorOrigin,
    alert,
    close,
    message,
    open,
    transition,
    variant,
    autoHideDuration,
    customAction,
    navigateTo,
  } = snackbar;

  const handleClose = (__: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  if (mobileVersion && snackbar.open && alert.color === 'error') {
    errorSound.play();
  }

  if (mobileVersion && snackbar.open && alert.color === 'success') {
    successSound.play();
  }

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={
            mobileVersion
              ? {
                  vertical: 'bottom',
                  horizontal: 'center',
                }
              : anchorOrigin
          }
          sx={mobileVersion ? { bottom: { xs: 120, sm: 0 } } : {}}
          open={open}
          autoHideDuration={mobileVersion ? 2000 : autoHideDuration}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition as AnimationTypes]}
          action={
            <>
              <SegmentButton
                color="secondary"
                size="small"
                onClick={handleClose}
              >
                UNDO
              </SegmentButton>
              <SegmentIconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                sx={{ mt: 0.25 }}
              >
                <IconX fontSize="small" />
              </SegmentIconButton>
            </>
          }
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition as AnimationTypes]}
          anchorOrigin={
            mobileVersion
              ? {
                  vertical: 'bottom',
                  horizontal: 'center',
                }
              : anchorOrigin
          }
          sx={mobileVersion ? { bottom: { xs: 120, sm: 0 } } : {}}
          open={open}
          autoHideDuration={mobileVersion ? 2000 : autoHideDuration}
          onClose={handleClose}
        >
          <Alert
            variant={alert.variant}
            color={alert.color}
            severity={alert.severity}
            action={
              <>
                {customAction && (
                  <SegmentButton
                    size="small"
                    onClick={customAction.onClick}
                    sx={{
                      color: 'background.paper',
                    }}
                  >
                    {customAction.name}
                  </SegmentButton>
                )}
                {actionButton !== false && (
                  <SegmentButton
                    size="small"
                    onClick={handleClose}
                    sx={{ color: 'background.paper' }}
                  >
                    UNDO
                  </SegmentButton>
                )}
                {navigateTo && (
                  <SegmentIconButton
                    sx={{ color: 'background.paper' }}
                    size="small"
                    aria-label="close"
                    onClick={() => navigate(navigateTo)}
                  >
                    <IconArrowRight fontSize="small" />
                  </SegmentIconButton>
                )}
                {close !== false && (
                  <SegmentIconButton
                    sx={{ color: 'background.paper' }}
                    size="small"
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <IconX fontSize="small" />
                  </SegmentIconButton>
                )}
              </>
            }
            sx={
              mobileVersion
                ? {
                    ...(alert.variant === 'outlined' && {
                      bgcolor: 'background.paper',
                    }),
                    width: '100%',
                  }
                : {
                    ...(alert.variant === 'outlined' && {
                      bgcolor: 'background.paper',
                    }),
                  }
            }
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
