import React, { Ref, useState, MouseEvent } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';

// project imports
import { Icon, IconDotsVertical } from '@tabler/icons-react';
import _ from 'lodash';

// constant
const defaultHeaderSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface ClickableMainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  headerSX?: CardHeaderProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  highlight?: boolean;
  loading?: boolean;
  optionsMenu?: boolean;
  optionsMenuItems?: {
    onClick: () => void;
    disabled?: boolean;
    icon: Icon;
    iconColor:
      | 'warning'
      | 'primary'
      | 'error'
      | 'success'
      | 'secondary'
      | undefined;
    text: string;
  }[];
  onClick: (e: MouseEvent) => void;
  disabled?: boolean;
  variant?: CardProps['variant'];
}

const ClickableMainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      headerSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      onClick,
      highlight = false,
      loading = false,
      disabled,
      optionsMenu,
      optionsMenuItems,
      ...others
    }: ClickableMainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<
      Element | ((element: Element) => Element) | null | undefined
    >(null);

    const handleClick = (event: React.SyntheticEvent) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: highlight
            ? theme.palette.success.main
            : theme.palette.grey[300],
          ':hover': !disabled
            ? {
                cursor: 'pointer',
                boxShadow: boxShadow
                  ? shadow ||
                    (theme.palette.mode === 'dark'
                      ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                      : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                  : 'inherit',
              }
            : {},
          ...sx,
        }}
      >
        <CardActionArea
          component="div"
          onClick={disabled ? undefined : (e) => onClick(e)}
          sx={disabled ? { ':hover': { cursor: 'auto' } } : {}}
          disabled={disabled}
        >
          {loading && <LinearProgress color="primary" />}
          {/* card header and action */}
          {!darkTitle && title && (
            <CardHeader
              sx={{ ...headerSX, ...defaultHeaderSX }}
              title={
                optionsMenu ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {title}
                    <Tooltip title="More options">
                      <IconButton
                        onMouseDown={(event) => event.stopPropagation()}
                        onTouchStart={(event) => event.stopPropagation()}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          handleClick(event);
                        }}
                        size="small"
                      >
                        <IconDotsVertical />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  title
                )
              }
              action={secondary}
            />
          )}

          {/* content & header divider */}
          {title && <Divider />}

          {/* card content */}
          {content && (
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          )}
          {!content && children}
        </CardActionArea>
        {optionsMenu && (
          <Menu
            id="menu-earning-card"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {optionsMenuItems?.map((o) => {
              const TablerIcon = o.icon;
              return (
                <MenuItem
                  onClick={o.onClick}
                  disabled={o.disabled}
                  key={o.text}
                >
                  <ListItemIcon>
                    <TablerIcon
                      color={
                        o.iconColor
                          ? _.get(theme, ['palette', o.iconColor, 'main'])
                          : undefined
                      }
                    />
                  </ListItemIcon>
                  <ListItemText>{o.text}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </Card>
    );
  }
);

export default ClickableMainCard;
