// project imports
import { Theme } from '@mui/material/styles';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
} from '@tabler/icons-react';

export default function componentStyleOverrides(
  theme: Theme,
  borderRadius: number,
  outlinedFilled: boolean
) {
  const mode = theme.palette.mode;
  const bgColor = theme.palette.grey[50];
  const menuSelectedBack = theme.palette.brand.magnesium;
  const menuSelected = theme.palette.brand.steel;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'outlined' as 'outlined' | 'standard' | 'filled' | undefined,
        iconMapping: {
          error: <IconAlertOctagon />,
          success: <IconCircleCheck />,
          info: <IconInfoCircle />,
          warning: <IconAlertTriangle />,
        },
      },
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlinedSuccess: {
          color: theme.palette.primary.main,
          border: '1px solid',
        },
        outlinedInfo: {
          color: theme.palette.secondary.main,
          border: '1px solid',
        },
        outlinedWarning: {
          color: theme.palette.warning.main,
          border: '1px solid',
        },
        outlinedError: {
          color: theme.palette.error.main,
          border: '1px solid',
        },
        standardSuccess: {
          color: theme.palette.primary.dark,
          backgroundColor: theme.palette.success.light,
          '& .MuiAlert-icon': {
            color: theme.palette.primary.dark,
          },
        },
        standardInfo: {
          color: theme.palette.secondary.dark,
          backgroundColor: theme.palette.secondary.light,
          '& .MuiAlert-icon': {
            color: theme.palette.secondary.dark,
          },
        },
        standardWarning: {
          color: theme.palette.warning.dark,
          backgroundColor: theme.palette.warning.light,
          '& .MuiAlert-icon': {
            color: theme.palette.orange.dark,
          },
        },
        standardError: {
          color: theme.palette.error.dark,
          backgroundColor: theme.palette.orange.light,
          '& .MuiAlert-icon': {
            color: theme.palette.error.dark,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.brand.juniper,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.palette.brand.juniper,
            backgroundColor: menuSelectedBack,
            '&:hover': {
              backgroundColor: menuSelectedBack,
            },
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.brand.juniper,
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: theme.palette.text.secondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: outlinedFilled ? bgColor : 'transparent',
          borderRadius: `${borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 28
                : theme.palette.grey[400],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.light,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: outlinedFilled ? bgColor : 'transparent',
          padding: '15.5px 14px',
          borderRadius: `${borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color:
              mode === 'dark'
                ? theme.palette.text.primary + 50
                : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        },
        valueLabel: {
          color:
            mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color:
                mode === 'dark'
                  ? theme.palette.text.primary + 80
                  : theme.palette.secondary[200],
            },
          },
        },
        popper: {
          borderRadius: `${borderRadius}px`,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === 'dark' ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.dark,
          background: 'white',
        },
        rounded: {
          border: '1px solid #F5F5F5',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: '16px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            mode === 'dark'
              ? theme.palette.dark[900]
              : theme.palette.primary.light,
          '& .MuiTabs-flexContainer': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.primary[200],
          },
          '& .MuiTab-root': {
            color:
              mode === 'dark'
                ? theme.palette.text.secondary
                : theme.palette.grey[900],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark,
          },
          '& .Mui-selected': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor:
            mode === 'dark'
              ? theme.palette.text.primary + 20
              : theme.palette.grey[200],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[700],
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor:
            mode === 'dark'
              ? theme.palette.text.primary + 15
              : theme.palette.grey[200],
          '&.MuiTableCell-head': {
            fontSize: '0.875rem',
            color: theme.palette.grey[600],
            fontWeight: 500,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        // columnHeaders:{
        //     borderColor: theme.palette.grey[300],
        // },
        footerContainer: {
          borderTop: '1px solid rgba(255, 0, 0, 1)',
          borderColor: theme.palette.grey[300],
        },
        row: {
          '&.dnpOrIgnore': {
            color: theme.palette.grey[400],
          },
          '&.dnpOrIgnore a': {
            color: theme.palette.grey[500],
          },
          '&.parentRow': {
            borderTop: '1px solid rgba(0, 255, 0, 1)',
            borderColor: theme.palette.grey[300],
          },
          '&.childRow': {
            borderTop: '1px solid rgba(0, 0, 0, 0)',
          },
          '&.overAllocated .MuiDataGrid-cell[data-field="allocated"]': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
          },
          '&.overAllocated .MuiDataGrid-cell[data-field="ownedQuant"]': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
          },
          '&.childRow:hover': {
            backgroundColor: 'white',
          },
          borderTop: '1px solid rgba(255, 0, 0, 1)',
          borderColor: theme.palette.grey[300],
          overflow: 'hidden',
        },
        cell: {
          borderBottom: '1px solid rgba(0, 0, 0, 0)',
        },
        iconButtonContainer: {
          color: 'rgba(0, 0, 0, 0.00)',
        },
        menu: {
          boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
          borderRadius: '12px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          paddingBottom: '0',
        },
      },
    },
  };
}
